// @mui material components
import Grid from "@mui/material/Grid";

import { useState, useEffect } from "react";
import { Navigate, useNavigate, useLocation, Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useAuth } from "../../AuthProvider";
import axios from "axios";
import { STATES } from "../../config/states";
import { ZONES } from "../../config/zones";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAutocomplete from "components/MDAutocomplete";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import MDSnackbar from "components/MDSnackbar";
import Sidebar, { ProfileSidebar } from "examples/Sidenav/Sidebar";
import CAInformation from "layouts/centreapplications/components/CentreInformation";
import { AssignEQACard, DownloadCADocsCard } from "layouts/centreapplications/components/EqaCards";
import {
  Paper,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Avatar,
  Box,
  Typography,
  IconButton,
  Divider,
  Icon,
  Tooltip,
  Switch,
  List,
  ListItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EngineeringIcon from "@mui/icons-material/Engineering";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

const USER_REGEX = /^[A-z]{3,20}$/;
const EMAIL_REGEX = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/;
const PHONE_REGEX = /^[0-9]{11}$/;

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

import burceMars from "assets/images/bruce-mars.jpg";

export default function Settings() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const apiUrl = "https://api.skillcamp.niobnat.org";
  const [_user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [invisible, setInvisible] = useState(false);
  const navigate = useNavigate();

  const [uln, setUln] = useState("");
  const [avatar, setAvatar] = useState("");
  const [firstName, setFirstName] = useState("");
  const [validFirstName, setValidFirstName] = useState(false);
  const [middleName, setMiddleName] = useState("");
  const [validMiddleName, setValidMiddleName] = useState(false);
  const [lastName, setLastName] = useState("");
  const [validLastName, setValidLastName] = useState(false);
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [gender, setGender] = useState("");
  const [phone, setPhone] = useState("");
  const [validPhone, setValidPhone] = useState(false);
  const [roles, setRoles] = useState([]);
  const [stateOfRez, setStateOfRez] = useState("");
  const [zone, setZone] = useState("");
  const [centres, setCentres] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [rolesData, setRolesData] = useState([]);
  const [qualificationsData, setQualificationsData] = useState([]);
  const [centresData, setCentresData] = useState([]);

  const { auth } = useAuth();
  const userRole = auth?.roles?.toLowerCase();

  if (userRole === "admin") {
    return <Navigate to="/error404" />;
  }

  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    type: "success", // "success" or "error"
    message: "",
  });

  // Show notification
  const showNotification = (type, message) => {
    setNotification({ open: true, type, message });
  };

  // Close notification
  const closeNotification = () => {
    setNotification({ ...notification, open: false });
  };

  useEffect(() => {
    const authData = localStorage.getItem("auth");

    if (!authData) {
      console.error("No auth data found in localStorage.");
      return;
    }

    try {
      const parsedAuth = JSON.parse(authData);
      const token = parsedAuth?.token;

      if (!token) {
        console.error("Token not found in auth object.");
        return;
      }

      const user = jwtDecode(token);

      if (user?.id) {
        setUserId(user.id);
      } else {
        console.error("Id not found in token payload.");
      }
    } catch (error) {
      console.error("Error parsing auth data from localStorage:", error);
    }
  }, []);

  // Fetch user data only if `userId` is available
  useEffect(() => {
    if (!userId) return;

    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/users/${userId}`);

        if (response.data) {
          setUser({
            ...response.data,
            qualifications: response.data.qualifications || [],
            roles: response.data.roles || [],
          });
          setUln(response.data.uln);
          setAvatar(`${apiUrl}${response.data.avatar}` || burceMars);
          setFirstName(response.data.firstName);
          setMiddleName(response.data.middleName);
          setLastName(response.data.lastName);
          setGender(response.data.gender);
          setPhone(response.data.phone);
          setEmail(response.data.email);
          setStateOfRez(response.data.stateOfRez);
          setZone(response.data.zone);
          setQualifications(response.data.qualifications.map((q) => q.qualificationType));
          setRoles(response.data.roles.map((r) => r.roleType));
          setCentres(response.data.centres.map((c) => c.centreName));
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [userId]);

  // Fetch qualifications data
  useEffect(() => {
    const fetchData = async () => {
      const qualificationsResponse = await axios.get(
        `${apiUrl}/api/qualifications/getQualifications`
      );

      setQualificationsData(qualificationsResponse.data);
    };

    fetchData();
  }, []);

  // Input validation
  useEffect(() => {
    setValidFirstName(USER_REGEX.test(firstName));
    setValidMiddleName(USER_REGEX.test(middleName));
    setValidLastName(USER_REGEX.test(lastName));
    setValidEmail(EMAIL_REGEX.test(email));
    setValidPhone(PHONE_REGEX.test(phone));
  }, [firstName, middleName, lastName, email, phone]);

  // Form submission handler
  const onSaveUserClicked = async (e) => {
    e.preventDefault();

    // Map qualifications to their corresponding IDs
    const selectedQualificationIds = qualifications
      .map(
        (qualification) =>
          qualificationsData.find((data) => data.qualificationType === qualification)?.id
      )
      .filter((id) => id);

    const formData = {
      uln,
      firstName,
      middleName,
      lastName,
      gender,
      email,
      phone,
      stateOfRez,
      zone,
      qualifications: selectedQualificationIds,
    };

    try {
      const response = await axios.put(`${apiUrl}/api/users/${userId}`, formData);

      if (response) {
        showNotification("success", "User record updated successfully!");

        // Redirect after a delay
        setTimeout(() => navigate("/"), 7000);
      }
    } catch (e) {
      showNotification("error", `${e.response.data}, Try again`);
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();

    // Basic validation
    if (newPassword !== confirmPassword) {
      showNotification("error", "New passwords do not match!");
      return;
    }

    if (newPassword.length < 8) {
      showNotification("error", "Password must be at least 6 characters long.");
      return;
    }

    // Submit logic here...
    const formData = {
      currentPassword,
      newPassword,
    };

    try {
      const response = await axios.put(`${apiUrl}/api/users/password/${userId}`, formData);

      if (response) {
        showNotification("success", "Password changed successfully!");

        // Redirect after a delay
        setTimeout(() => navigate("/"), 7000);
      }
    } catch (e) {
      showNotification("error", `${e.response.data}, Try again`);
    }
  };

  const handleUpload = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setAvatar(reader.result);

      const formData = new FormData(); // Gather all form data
      formData.append("avatar", file);

      try {
        const response = await axios.put(`${apiUrl}/api/users/avatar/${userId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        showNotification("success", "Profile photo updated");
      } catch (error) {
        console.log("error: ", error);
      }
      reader.readAsDataURL(file);
    } else {
      showNotification("error", "No file selected");
    }
  };

  // Options for autocomplete components
  const options0 = rolesData.map((role) => ({ id: role.id, roleType: role.roleType }));
  const options2 = centresData.map((centre) => ({ id: centre.id, centreName: centre.centreName }));
  const options3 = qualificationsData.map((qualification) => ({
    id: qualification.id,
    qualificationType: qualification.qualificationType,
  }));
  const options1 = Object.entries(STATES).map(([key, value]) => ({
    key,
    state: value,
  }));

  const options4 = Object.entries(ZONES).map(([key, value]) => ({
    key,
    zone: value,
  }));

  // Form validation state
  const canSave = [
    validFirstName,
    validLastName,
    validEmail,
    validPhone,
    roles.length,
    centres.length,
    qualifications.length,
  ].every(Boolean);

  // Ensure values are always valid objects or null
  const mapValuesToOptions = (values, options, labelKey) =>
    values.map(
      (value) => options.find((option) => option[labelKey] === value) || { [labelKey]: value }
    );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        color={notification.type}
        icon={notification.type === "success" ? "check" : "warning"}
        title="NIOB Skill CaMP"
        content={notification.message}
        open={notification.open}
        onClose={closeNotification}
        close={closeNotification}
        bgWhite
      />
      <MDBox mt={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <Sidebar />
            </Grid>

            <Grid item xs={12} md={9}>
              <Card id="avatar" sx={{ p: 3, borderRadius: "12px", boxShadow: 3, mb: 3 }}>
                <Grid container spacing={2} alignItems="center">
                  {/* Profile Image */}
                  <Grid item>
                    <Avatar src={avatar} alt="Profile Image" sx={{ width: 64, height: 64 }} />
                  </Grid>

                  {/* Name & Title */}
                  <Grid item xs>
                    <Typography variant="h5" fontWeight="bold">
                      {firstName.toUpperCase() +
                        " " +
                        middleName.toUpperCase() +
                        " " +
                        lastName.toUpperCase()}
                    </Typography>
                    <Typography variant="button" color="textSecondary">
                      {roles.map((role) => {
                        if (role.roleType === "EQAM") {
                          return "External Quality Assurance Manager";
                        } else if (role.roleType === "IQAM") {
                          return "Internal Quality Assurance Manager";
                        }
                      })}
                    </Typography>
                  </Grid>

                  {/* Update Avatar handle/toggle */}
                  <Grid item>
                    {/*<Typography variant="caption">
                      Switch to {invisible ? "visible" : "invisible"}
                    </Typography>
                    <Switch
                      checked={invisible}
                      onChange={() => setInvisible(!invisible)}
                      color="primary"
                    />*/}
                    {/* Hidden File Input */}
                    <input
                      accept="image/*"
                      type="file"
                      id="avatar-upload"
                      style={{ display: "none" }}
                      onChange={handleUpload}
                    />

                    {/* Upload Button */}
                    <label htmlFor="avatar-upload">
                      <IconButton color="dark" component="span">
                        <PhotoCameraIcon />
                      </IconButton>
                    </label>
                  </Grid>
                </Grid>
              </Card>

              <Card id="basic-info" sx={{ p: 3, borderRadius: "12px", boxShadow: 3, mb: 3 }}>
                <Grid container spacing={2} alignItems="center">
                  <MDBox p={3}>
                    <form onSubmit={onSaveUserClicked}>
                      <MDBox
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                      >
                        <MDInput
                          fullWidth
                          variant="standard"
                          type="text"
                          label="Unique Learner's Number"
                          value={uln}
                          disabled
                          sx={{ gridColumn: "span 4" }}
                        />

                        <MDInput
                          fullWidth
                          variant="standard"
                          type="text"
                          label="First Name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          sx={{ gridColumn: "span 2" }}
                        />

                        <MDInput
                          fullWidth
                          variant="standard"
                          type="text"
                          label="Middle Name"
                          value={middleName}
                          onChange={(e) => setMiddleName(e.target.value)}
                          sx={{ gridColumn: "span 1" }}
                        />

                        <MDInput
                          fullWidth
                          variant="standard"
                          type="text"
                          label="Last Name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          sx={{ gridColumn: "span 1" }}
                        />

                        <MDAutocomplete
                          id="gender-selector"
                          sx={{ gridColumn: "span 1" }}
                          options={[
                            { label: "Male", value: "Male" },
                            { label: "Female", value: "Female" },
                          ]}
                          getOptionLabel={(option) => option.label || ""}
                          renderInput={(params) => (
                            <MDInput
                              {...params}
                              fullWidth
                              label="Select Gender"
                              variant="standard"
                              value={gender} // Populate the current value
                              aria-autocomplete="list"
                              spellCheck={false}
                              autoComplete="off"
                              autoCapitalize="none"
                            />
                          )}
                          value={gender ? { label: gender, value: gender } : null} // Set current value as default
                          onChange={(event, value) => {
                            setGender(value?.value || ""); // Update gender state
                          }}
                          isOptionEqualToValue={(option, value) => option.value === value?.value}
                        />

                        <MDInput
                          fullWidth
                          variant="standard"
                          type="email"
                          label="Email"
                          disabled
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          name="email"
                          sx={{ gridColumn: "span 2" }}
                        />

                        <MDInput
                          fullWidth
                          variant="standard"
                          type="tel"
                          label="Phone Number"
                          onChange={(e) => setPhone(e.target.value)}
                          value={phone}
                          name="phone"
                          sx={{ gridColumn: "span 1" }}
                        />

                        <MDAutocomplete
                          id="zone-selector"
                          sx={{ gridColumn: "span 2" }}
                          options={options4}
                          value={options4.find((option) => option.zone === zone) || null} // Set a single value or null if no match
                          onChange={(event, value) => setZone(value?.zone || "")} // Set only one zone value
                          getOptionLabel={(option) => option.zone} // Access the zone property for display
                          isOptionEqualToValue={(option, value) => option.zone === value?.zone}
                          renderInput={(params) => (
                            <MDInput {...params} label="Select Zone" variant="standard" />
                          )}
                        />

                        <MDAutocomplete
                          id="state-selector"
                          sx={{ gridColumn: "span 2" }}
                          options={options1}
                          value={options1.find((option) => option.state === stateOfRez) || null} // Set a single value or null if no match
                          onChange={(event, value) => setStateOfRez(value?.state || "")} // Set only one state value
                          getOptionLabel={(option) => option.state} // Access the state property for display
                          isOptionEqualToValue={(option, value) => option.state === value?.state}
                          renderInput={(params) => (
                            <MDInput {...params} label="Select State" variant="standard" />
                          )}
                        />

                        <MDAutocomplete
                          id="qualification-selector"
                          options={options3}
                          multiple
                          value={mapValuesToOptions(qualifications, options3, "qualificationType")}
                          onChange={(event, value) =>
                            setQualifications(value.map((item) => item.qualificationType))
                          }
                          getOptionLabel={(option) => option.qualificationType || ""}
                          isOptionEqualToValue={(option, value) =>
                            option.qualificationType === value?.qualificationType
                          }
                          renderInput={(params) => (
                            <MDInput {...params} label="Select Qualifications" variant="standard" />
                          )}
                          sx={{ gridColumn: "span 4" }}
                        />
                      </MDBox>
                      <MDBox mt={3}>
                        <MDButton
                          type="submit"
                          disabled={!canSave}
                          variant="gradient"
                          color="success"
                        >
                          Update My Record
                        </MDButton>
                      </MDBox>
                    </form>
                  </MDBox>
                </Grid>
              </Card>

              <Card id="password" sx={{ p: 3, borderRadius: "12px", boxShadow: 3, mb: 3 }}>
                <MDBox p={1}>
                  <form onSubmit={handleChangePassword}>
                    <MDBox
                      display="grid"
                      gap="30px"
                      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    >
                      {/* Current Password */}
                      <MDInput
                        fullWidth
                        variant="standard"
                        type="password"
                        label="Current Password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        sx={{ gridColumn: "span 4" }}
                      />

                      {/* New Password */}
                      <MDInput
                        fullWidth
                        variant="standard"
                        type="password"
                        label="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        sx={{ gridColumn: "span 4" }}
                      />

                      {/* Confirm New Password */}
                      <MDInput
                        fullWidth
                        variant="standard"
                        type="password"
                        label="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        sx={{ gridColumn: "span 4" }}
                      />
                    </MDBox>

                    {/* Password Requirements */}
                    <MDBox mt={5}>
                      <MDTypography variant="h6">Password Requirements</MDTypography>
                      <MDTypography variant="body2" color="textSecondary" mb={1}>
                        Please follow this guide for a strong password:
                      </MDTypography>
                      <List dense sx={{ fontSize: "12px" }}>
                        <ListItem>-&nbsp; One special character (e.g: @#_!)</ListItem>
                        <ListItem>-&nbsp; Minimum 8 characters</ListItem>
                        <ListItem>-&nbsp; One number (2 recommended)</ListItem>
                        <ListItem>-&nbsp; Change it often</ListItem>
                      </List>
                    </MDBox>

                    {/* Submit Button */}
                    <MDBox mt={3}>
                      <MDButton type="submit" variant="gradient" color="success">
                        Update My Password
                      </MDButton>
                    </MDBox>
                  </form>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
