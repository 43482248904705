import { Link } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

const Error404 = () => {
  return (
    <MDBox textAlign="center" mt={10}>
      <MDTypography variant="h3" color="error">
        404 - Page Not Found
      </MDTypography>
      <MDTypography variant="body1" mt={2}>
        Sorry, the page you are looking for does not exist.
      </MDTypography>
      <MDBox mt={3}>
        <MDButton component={Link} to="/" color="info">
          Go Home
        </MDButton>
      </MDBox>
    </MDBox>
  );
};

export default Error404;
