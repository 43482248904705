/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// stefan.dataanalyst - wfthxGz4zd
// deji4me - FDa5NUfB8x

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAutocomplete from "components/MDAutocomplete";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar, { DashboardNavbar2 } from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { STATES } from "../../config/states";
import { ZONES } from "../../config/zones";

//import MDLoadingButton from "@mui/lab/LoadingButton";
import { useTheme, styled } from "@mui/material";
import MDInput from "components/MDInput";
import MDAlert from "@mui/material/Alert";
import MDHomeIcon from "@mui/icons-material/Home";
import MDVisibilityIcon from "@mui/icons-material/Visibility";
import MDCloudUploadIcon from "@mui/icons-material/CloudUpload";
import MDTextField from "@mui/material/TextField";
import MDStack from "@mui/material/Stack";
import MDButton from "components/MDButton";
import MDFormControl from "@mui/material/FormControl";
import MDInputLabel from "@mui/material/InputLabel";
import MDSelect from "@mui/material/Select";
import MDMenuItem from "@mui/material/MenuItem";
import MDGrid from "@mui/material/Grid";
import MDCard from "@mui/material/Card";
import MDCardHeader from "@mui/material/CardHeader";
import MDCardActions from "@mui/material/CardActions";
import MDCardContent from "@mui/material/CardContent";

const ROLE_REGEX = /^[A-Za-z& ]{2,40}$/;

// Data
import rolesTableData from "layouts/roles/data/rolesTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import { useAuth } from "../../AuthProvider"; // Corrected Import
import { Navigate, useLocation } from "react-router-dom";

// Display All Roles
export default function Roles() {
  const { columns, rows } = rolesTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();

  const { auth } = useAuth();
  const userRole = auth?.roles?.toLowerCase();

  if (userRole !== "admin") {
    return <Navigate to="/error404" />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar2 />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
              >
                <MDTypography variant="h6" color="white">
                  All Roles
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  canSearch={true}
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={{ variant: "gradient", color: "success" }}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

// New Role Form
export function NewRoleForm() {
  const navigate = useNavigate();
  const [roleType, setRoleType] = useState("");
  const [validRoleType, setValidRoleType] = useState(false);
  const [description, setDescription] = useState("");
  const [validDescription, setValidDescription] = useState(false);
  const apiUrl = "https://api.skillcamp.niobnat.org";

  const { auth } = useAuth();
  const userRole = auth?.roles?.toLowerCase();

  if (userRole !== "admin") {
    return <Navigate to="/error404" />;
  }

  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    type: "success", // "success" or "error"
    message: "",
  });

  // Show notification
  const showNotification = (type, message) => {
    setNotification({ open: true, type, message });
  };

  // Close notification
  const closeNotification = () => {
    setNotification({ ...notification, open: false });
  };

  // Input validation
  useEffect(() => {
    setValidRoleType(ROLE_REGEX.test(roleType));
    setValidDescription(ROLE_REGEX.test(description));
  }, [roleType, description]);

  const onRoleTypeChanged = (e) => setRoleType(e.target.value);
  const onDescriptionChanged = (e) => setDescription(e.target.value);

  const onSaveUserClicked = async (e) => {
    e.preventDefault();

    const formData = {
      roleType,
      description,
    };

    try {
      const roleAdded = await axios.post(`${apiUrl}/api/roles/addRole`, formData);

      if (roleAdded) {
        console.log("Response: ", roleAdded);
        setRoleType("");
        setDescription("");
        showNotification("success", "Role added successfully!");

        // Redirect after a delay
        setTimeout(() => navigate("/roles"), 7000);
      }
    } catch (e) {
      console.error("Error: ", e.response.data);
      showNotification("error", `${e.response.data}, Try again.`);
    }
  };

  const canSave = [validRoleType, validDescription].every(Boolean);

  return (
    <DashboardLayout>
      <DashboardNavbar2 />
      <MDSnackbar
        color={notification.type}
        icon={notification.type === "success" ? "check" : "warning"}
        title="NIOB Skill CaMP"
        content={notification.message}
        open={notification.open}
        onClose={closeNotification}
        close={closeNotification}
        bgWhite
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
              >
                <MDTypography variant="h6" color="white">
                  Add New Role
                </MDTypography>
              </MDBox>
              <MDBox p={3}>
                <form onSubmit={onSaveUserClicked}>
                  <MDBox
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": { gridColumn: undefined },
                    }}
                  >
                    <MDInput
                      fullWidth
                      variant="standard"
                      type="text"
                      label="Role Type"
                      onChange={onRoleTypeChanged}
                      value={roleType}
                      name="roleType"
                      sx={{ gridColumn: "span 4" }}
                    />

                    <MDInput
                      fullWidth
                      variant="standard"
                      type="text"
                      label="Add a Description of the Role Type"
                      onChange={onDescriptionChanged}
                      value={description}
                      name="description"
                      sx={{ gridColumn: "span 4" }}
                    />
                  </MDBox>
                  <MDBox>
                    <MDButton
                      disabled={!canSave}
                      loading={false}
                      type="submit"
                      variant="contained"
                      color="success"
                      sx={{ mt: 3 }}
                    >
                      Add New Role
                    </MDButton>
                  </MDBox>
                </form>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

// Edit Role Form
export function EditRoleForm() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [roleType, setRoleType] = useState("");
  const [validRoleType, setValidRoleType] = useState(false);
  const [description, setDescription] = useState("");
  const [validDescription, setValidDescription] = useState(false);

  const apiUrl = "https://api.skillcamp.niobnat.org";

  const { auth } = useAuth();
  const userRole = auth?.roles?.toLowerCase();

  if (userRole !== "admin") {
    return <Navigate to="/error404" />;
  }

  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    type: "success", // "success" or "error"
    message: "",
  });

  // Show notification
  const showNotification = (type, message) => {
    setNotification({ open: true, type, message });
  };

  // Close notification
  const closeNotification = () => {
    setNotification({ ...notification, open: false });
  };

  // Fetch role data
  useEffect(() => {
    const fetchRoleData = async () => {
      const { data } = await axios.get(`${apiUrl}/api/roles/${id}`);
      setRoleType(data.roleType);
      //setDescription(data.description);
    };
    fetchRoleData();
  }, [id]);

  // Input validation
  useEffect(() => {
    setValidRoleType(ROLE_REGEX.test(roleType));
    setValidDescription(ROLE_REGEX.test(description));
  }, [roleType, description]);

  const onRoleTypeChanged = (e) => setRoleType(e.target.value);
  const onDescriptionChanged = (e) => setDescription(e.target.value);

  const onSaveUserClicked = async (e) => {
    e.preventDefault();

    const formData = {
      roleType,
      description,
    };

    try {
      const roleUpdated = await axios.put(`${apiUrl}/api/roles/${id}`, formData);

      if (roleUpdated) {
        console.log("Response: ", roleUpdated);
        showNotification("success", "Role updated successfully!");

        // Redirect after a delay
        setTimeout(() => navigate("/roles"), 7000);
      }
    } catch (e) {
      console.error("Error: ", e.response.data);
      showNotification("error", `${e.response.data}, Try again.`);
    }
  };

  const canSave = [validRoleType].every(Boolean);

  return (
    <DashboardLayout>
      <DashboardNavbar2 />
      <MDSnackbar
        color={notification.type}
        icon={notification.type === "success" ? "check" : "warning"}
        title="NIOB Skill CaMP"
        content={notification.message}
        open={notification.open}
        onClose={closeNotification}
        close={closeNotification}
        bgWhite
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
              >
                <MDTypography variant="h6" color="white">
                  Update Role Record
                </MDTypography>
              </MDBox>
              <MDBox p={3}>
                <form onSubmit={onSaveUserClicked}>
                  <MDBox
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": { gridColumn: undefined },
                    }}
                  >
                    <MDInput
                      fullWidth
                      variant="standard"
                      type="text"
                      label="Role Type"
                      onChange={onRoleTypeChanged}
                      value={roleType}
                      name="roleType"
                      sx={{ gridColumn: "span 4" }}
                    />

                    <MDInput
                      fullWidth
                      variant="standard"
                      type="text"
                      label="Description of the Role Type"
                      onChange={onDescriptionChanged}
                      value={description}
                      name="description"
                      sx={{ gridColumn: "span 4" }}
                    />
                  </MDBox>
                  <MDBox>
                    <MDButton
                      disabled={!canSave}
                      type="submit"
                      variant="contained"
                      color="success"
                      sx={{ mt: 3 }}
                    >
                      Update Role Record
                    </MDButton>
                  </MDBox>
                </form>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
