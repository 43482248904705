export const STATES = {
  ABIA: "ABIA",
  ADAMAWA: "ADAMAWA",
  AKWAIBOM: "AKWA IBOM",
  ANAMBRA: "ANAMBRA",
  BAUCHI: "BAUCHI",
  BAYELSA: "BAYELSA",
  BENUE: "BENUE",
  BORNO: "BORNO",
  CROSSRIVER: "CROSS RIVER",
  DELTA: "DELTA",
  EBONYI: "EBONYI",
  EDO: "EDO",
  EKITI: "EKITI",
  ENUGU: "ENUGU",
  ABUJA: "ABUJA",
  GOMBE: "GOMBE",
  IMO: "IMO",
  JIGAWA: "JIGAWA",
  KADUNA: "KADUNA",
  KANO: "KANO",
  KATSINA: "KATSINA",
  KEBBI: "KEBBI",
  KOGI: "KOGI",
  KWARA: "KWARA",
  LAGOS: "LAGOS",
  NASARAWA: "NASARAWA",
  NIGER: "NIGER",
  OGUN: "OGUN",
  ONDO: "ONDO",
  OSUN: "OSUN",
  OYO: "OYO",
  PLATEAU: "PLATEAU",
  RIVERS: "RIVERS",
  SOKOTO: "SOKOTO",
  TARABA: "TARABA",
  YOBE: "YOBE",
  ZAMFARA: "ZAMFARA",
};
