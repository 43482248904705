/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import {
  Grid,
  Card,
  Tab,
  Tabs,
  List,
  ListItem,
  Select,
  Stack,
  IconButton,
  InputAdornment,
  InputLabel,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox,
  Stepper,
  Step,
  StepLabel,
  TextField,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAutocomplete from "components/MDAutocomplete";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar, { DashboardNavbar2 } from "examples/Navbars/DashboardNavbar";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Sidebar, { CASidebar } from "examples/Sidenav/Sidebar";
import CAInformation from "layouts/centreapplications/components/CentreInformation";
import {
  AssignEQACard,
  DownloadCADocsCard,
  ApproveCACard,
} from "layouts/centreapplications/components/EqaCards";

import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { STATES } from "../../config/states";
import { ZONES } from "../../config/zones";
import { CENTRETYPES } from "../../config/centretypes";
import { useAuth } from "../../AuthProvider"; // Corrected Import
import { Navigate, useLocation } from "react-router-dom";

// Data
import centreapplicationsTableData from "layouts/centreapplications/data/centreapplicationsTableData";
import myCentreapplicationsTableData from "layouts/centreapplications/data/myCentreapplicationsTableData";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

const CENTRE_REGEX = /^[a-zA-Z \.,()-]{8,50}$/;
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const steps = [
  "Instructions",
  "CENTRE DETAILS",
  "YOUR ORGANISATION",
  "HUMAN & FINANCIAL RESOURCES",
  "PHYSICAL INFRASTRUCTURE",
  "CODE OF PRACTICE",
  "ORGANISATIONAL STAFFING",
  "PHYSICAL FACILITIES",
  "TRAINING STAFF",
  "SUPPORTING DOCUMENTS",
];

const eqasteps = [
  "Instructions",
  "EQA FEEDBACK 1",
  "EQA FEEDBACK 2",
  "EQA FEEDBACK 3",
  "EQA FINAL FEEDBACK",
  "EQA SUPPORTING DOCUMENTS",
];

// Display All CentreApplications
export default function CentreApplications() {
  const { columns, rows } = centreapplicationsTableData();

  const { auth } = useAuth();
  const userRole = auth?.roles?.toLowerCase();

  if (userRole !== "admin") {
    return <Navigate to="/error404" />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar2 />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
              >
                <MDTypography variant="h6" color="white">
                  All Centre Applications
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  canSearch={true}
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={{ variant: "gradient", color: "success" }}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

// New CentreApplication Form with Stepper
export function NewCentreApplicationForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [qualificationsData, setQualificationsData] = useState([]);
  const maxYear = new Date().getFullYear().toString();
  const today = new Date().toString();

  const apiUrl = "https://api.skillcamp.niobnat.org";

  const [activeStep, setActiveStep] = useState(0);

  //CENTRE DETAILS INITIALIZE
  const [centreName, setCentreName] = useState("");
  const [validCentreName, setValidCentreName] = useState(false);
  const [yearOfInc, setYearOfInc] = useState();
  const [cacNumber, setCACNumber] = useState("");
  const [validCACNumber, setValidCACNumber] = useState(false);
  const [permanentCentreAddress, setPermanentCentreAddress] = useState("");
  const [trainingCentreAddress, setTrainingCentreAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [validPhone, setValidPhone] = useState(false);
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [website, setWebsite] = useState("");
  const [validWebsite, setValidWebsite] = useState(false);
  const [centreHead, setCentreHead] = useState("");
  const [validCentreHead, setValidCentreHead] = useState(false);
  const [centreHeadPhone, setCentreHeadPhone] = useState("");
  const [validCentreHeadPhone, setValidCentreHeadPhone] = useState(false);
  const [centreHeadEmail, setCentreHeadEmail] = useState("");
  const [validCentreHeadEmail, setValidCentreHeadEmail] = useState(false);
  const [qualifications, setQualifications] = useState([]);
  const [zone, setZone] = useState("");
  const [stateOfRez, setStateOfRez] = useState("");

  //CENTRE ORGANISATION INITIALIZE
  const [centreType, setCentreType] = useState("");
  const [satelliteCentreName1, setSatelliteCentreName1] = useState("");
  const [validSatelliteCentreName1, setValidSatelliteCentreName1] = useState(false);
  const [satelliteCentreAddress1, setSatelliteCentreAddress1] = useState("");
  const [satelliteCentreName2, setSatelliteCentreName2] = useState("");
  const [validSatelliteCentreName2, setValidSatelliteCentreName2] = useState(false);
  const [satelliteCentreAddress2, setSatelliteCentreAddress2] = useState("");
  const [otherApprovals, setOtherApprovals] = React.useState({
    itp: false,
    naddc: false,
    nabteb: false,
    cityGuild: false,
  });
  const [awardingBody1, setAwardingBody1] = useState("");
  const [awardingBody1Qualification, setAwardingBody1Qualification] = useState("");
  const [awardingBody1Level, setAwardingBody1Level] = useState(1);
  const [awardingBody1DateApproved, setAwardingBody1DateApproved] = useState();
  const [awardingBody1ApprovalStatus, setAwardingBody1ApprovalStatus] = useState("");
  const [awardingBody2, setAwardingBody2] = useState("");
  const [awardingBody2Qualification, setAwardingBody2Qualification] = useState("");
  const [awardingBody2Level, setAwardingBody2Level] = useState(1);
  const [awardingBody2DateApproved, setAwardingBody2DateApproved] = useState();
  const [awardingBody2ApprovalStatus, setAwardingBody2ApprovalStatus] = useState("");

  //HUMAN & FINANCIAL RESOURCES INITIALIZE
  const [nsqCertifiedAssessors, setNsqCertifiedAssessors] = useState("");
  const [assessorsCount, setAssessorsCount] = useState(0);
  const [nsqCertifiedVerifiers, setNsqCertifiedVerifiers] = useState("");
  const [verifiersCount, setVerifiersCount] = useState(0);
  const [nsqCertifiedCentreManager, setNsqCertifiedCentreManager] = useState("");
  const [liaisonOfficerPresent, setLiaisonOfficerPresent] = useState("");
  const [resourceOfficerPresent, setResourceOfficerPresent] = useState("");
  const [siteBankPresent, setSiteBankPresent] = useState("");

  //PHYSICAL INFRASTRUCTURE INITIALIZE
  const [trainingRoomAdequate, setTrainingRoomAdequate] = useState("");
  const [dataRoomAdequate, setDataRoomAdequate] = useState("");
  const [workshopAdequate, setWorkshopAdequate] = useState("");
  const [practicalSpaceAdequate, setPracticalSpaceAdequate] = useState("");
  const [techLabAdequate, setTechLabAdequate] = useState("");
  const [staffRoomAdequate, setStaffRoomAdequate] = useState("");
  const [toiletAdequate, setToiletAdequate] = useState("");
  const [firstAidFireAdequate, setFirstAidFireAdequate] = useState("");
  const [hostelsAdequate, setHostelsAdequate] = useState("");
  const [tradeToolsAdequate, setTradeToolsAdequate] = useState("");
  const [auditoriumAvailable, setAuditoriumAvailable] = useState("");
  const [libraryAdequate, setLibraryAdequate] = useState("");
  const [waterAndPowerAdequate, setWaterAndPowerAdequate] = useState("");
  const [mgtOfficesAdequate, setMgtOfficesAdequate] = useState("");

  //CODE OF PRACTICE INITIALIZE
  const [nosAvailable, setNosAvailable] = useState("");
  const [trainingManualAvailable, setTrainingManualAvailable] = useState("");
  const [nosAndManualAlignment, setNosAndManualAlignment] = useState("");
  const [otherTrainingMaterialsAvailable, setOtherTrainingMaterialsAvailable] = useState("");
  const [nsqGuidelineAvailable, setNsqGuidelineAvailable] = useState("");
  const [staffCompliantWithStandards, setStaffCompliantWithStandards] = useState("");
  const [objectivesClear, setObjectivesClear] = useState("");
  const [classParticipation, setClassParticipation] = useState("");

  //ORGANISATION STAFFING INITIALIZE
  const [centreManagerName, setCentreManagerName] = useState("");
  const [centreManagerQualification, setCentreManagerQualification] = useState("");
  const [assessor1Name, setAssessor1Name] = useState("");
  const [assessor1Qualification, setAssessor1Qualification] = useState("");
  const [assessor2Name, setAssessor2Name] = useState("");
  const [assessor2Qualification, setAssessor2Qualification] = useState("");
  const [internalVerifierName, setInternalVerifierName] = useState("");
  const [internalVerifierQualification, setInternalVerifierQualification] = useState("");
  const [liaisonOfficerName, setLiaisonOfficerName] = useState("");
  const [liaisonOfficerQualification, setLiaisonOfficerQualification] = useState("");
  const [resourceOfficerName, setResourceOfficerName] = useState("");
  const [resourceOfficerQualification, setResourceOfficerQualification] = useState("");
  const [otherStaffName, setOtherStaffName] = useState("");
  const [otherStaffQualification, setOtherStaffQualification] = useState("");
  const [otherStaffDesignation, setOtherStaffDesignation] = useState("");

  //PHYSICAL FACILITIES INITIALIZE
  const [trainingRoomQuantity, setTrainingRoomQuantity] = useState(0);
  const [trainingRoomFloorArea, setTrainingRoomFloorArea] = useState(0);
  const [workshopQuantity, setWorkshopQuantity] = useState(0);
  const [workshopFloorArea, setWorkshopFloorArea] = useState(0);
  const [practicalTrainingSpaceQuantity, setPracticalTrainingSpaceQuantity] = useState(0);
  const [practicalTrainingSpaceFloorArea, setPracticalTrainingSpaceFloorArea] = useState(0);
  const [technicalLabQuantity, setTechnicalLabQuantity] = useState(0);
  const [technicalLabFloorArea, setTechnicalLabFloorArea] = useState(0);
  const [recordsRoomQuantity, setRecordsRoomQuantity] = useState(0);
  const [recordsRoomFloorArea, setRecordsRoomFloorArea] = useState(0);
  const [auditoriumQuantity, setAuditoriumQuantity] = useState(0);
  const [auditoriumFloorArea, setAuditoriumFloorArea] = useState(0);
  const [libraryQuantity, setLibraryQuantity] = useState(0);
  const [libraryFloorArea, setLibraryFloorArea] = useState(0);
  const [utilityQuantity, setUtilityQuantity] = useState(0);
  const [utilityFloorArea, setUtilityFloorArea] = useState(0);
  const [mgtOfficesQuantity, setMgtOfficesQuantity] = useState(0);
  const [mgtOfficesFloorArea, setMgtOfficesFloorArea] = useState(0);
  const [staffRoomQuantity, setStaffRoomQuantity] = useState(0);
  const [staffRoomFloorArea, setStaffRoomFloorArea] = useState(0);
  const [toiletQuantity, setToiletQuantity] = useState(0);
  const [toiletFloorArea, setToiletFloorArea] = useState(0);
  const [firstAidFireFacilityQuantity, setFirstAidFireFacilityQuantity] = useState(0);
  const [firstAidFireFacilityFloorArea, setFirstAidFireFacilityFloorArea] = useState(0);
  const [hostelQuantity, setHostelQuantity] = useState(0);
  const [hostelFloorArea, setHostelFloorArea] = useState(0);
  const [tradeToolsQuantity, setTradeToolsQuantity] = useState(0);
  const [tradeToolsFloorArea, setTradeToolsFloorArea] = useState(0);
  const [otherFacilityName, setOtherFacilityName] = useState("");
  const [otherFacilityQuantity, setOtherFacilityQuantity] = useState(0);
  const [otherFacilityFloorArea, setOtherFacilityFloorArea] = useState(0);

  //TRAINING STAFF INITIALIZE
  const [trainingStaff1Name, setTrainingStaff1Name] = useState("");
  const [trainingStaff1Gender, setTrainingStaff1Gender] = useState("");
  const [trainingStaff1Qualification, setTrainingStaff1Qualification] = useState("");
  const [trainingStaff1Experience, setTrainingStaff1Experience] = useState(0);
  const [trainingStaff1Specialization, setTrainingStaff1Specialization] = useState("");
  const [trainingStaff2Name, setTrainingStaff2Name] = useState("");
  const [trainingStaff2Gender, setTrainingStaff2Gender] = useState("");
  const [trainingStaff2Qualification, setTrainingStaff2Qualification] = useState("");
  const [trainingStaff2Experience, setTrainingStaff2Experience] = useState(0);
  const [trainingStaff2Specialization, setTrainingStaff2Specialization] = useState("");
  const [trainingStaff3Name, setTrainingStaff3Name] = useState("");
  const [trainingStaff3Gender, setTrainingStaff3Gender] = useState("");
  const [trainingStaff3Qualification, setTrainingStaff3Qualification] = useState("");
  const [trainingStaff3Experience, setTrainingStaff3Experience] = useState(0);
  const [trainingStaff3Specialization, setTrainingStaff3Specialization] = useState("");

  //SUPPORTING DOCS & DECLARATION INITIALIZE
  const [applicantsName, setApplicantsName] = useState("");
  const [validApplicantsName, setValidApplicantsName] = useState(false);
  const [applicantsPosition, setApplicantsPosition] = useState("");
  const [validApplicantsPosition, setValidApplicantsPosition] = useState(false);

  //INITIALIZE UPLOAD VARIABLES
  const [centreCredentials, setCentreCredentials] = useState(null);
  //const [centreCredentialsURL, setCentreCredentialsURL] = useState("");
  const [previewCredentials, setPreviewCredentials] = useState();

  // Fetch qualifications data
  useEffect(() => {
    const fetchData = async () => {
      const qualificationsResponse = await axios.get(
        `${apiUrl}/api/qualifications/getQualifications`
      );

      setQualificationsData(qualificationsResponse.data);
    };

    fetchData();
  }, []);

  // Input validation
  useEffect(() => {
    setValidCentreName(CENTRE_REGEX.test(centreName));
    setValidEmail(EMAIL_REGEX.test(email));
  }, [centreName, email]);

  //CENTRE DETAILS HANDLE CHANGE
  const onCentreNameChanged = (e) => setCentreName(e.target.value);
  const onCACChanged = (e) => setCACNumber(e.target.value);
  const onPermanentCentreAddressChanged = (e) => setPermanentCentreAddress(e.target.value);
  const onTrainingCentreAddressChanged = (e) => setTrainingCentreAddress(e.target.value);
  const onPhoneChanged = (e) => setPhone(e.target.value);
  const onEmailChanged = (e) => setEmail(e.target.value);
  const onWebsiteChanged = (e) => setWebsite(e.target.value);
  const onCentreHeadChanged = (e) => setCentreHead(e.target.value);
  const onCentreHeadPhoneChanged = (e) => setCentreHeadPhone(e.target.value);
  const onCentreHeadEmailChanged = (e) => setCentreHeadEmail(e.target.value);

  //CENTRE ORGANIZATION HANDLE CHANGE
  const onCentreTypeChanged = (e) => setCentreType(e.target.value);
  const onSatelliteCentreName1Changed = (e) => setSatelliteCentreName1(e.target.value);
  const onSatelliteCentreAddress1Changed = (e) => setSatelliteCentreAddress1(e.target.value);
  const onSatelliteCentreName2Changed = (e) => setSatelliteCentreName2(e.target.value);
  const onSatelliteCentreAddress2Changed = (e) => setSatelliteCentreAddress2(e.target.value);
  const onOtherApprovalsChange = (event) => {
    setOtherApprovals({ ...otherApprovals, [event.target.name]: event.target.checked });
  };
  const { itp, naddc, nabteb, cityGuild } = otherApprovals;
  const onAwardingBody1Changed = (e) => setAwardingBody1(e.target.value);
  const onAwardingBody1LevelChanged = (e) => setAwardingBody1Level(e.target.value);
  const onAwardingBody2Changed = (e) => setAwardingBody2(e.target.value);
  const onAwardingBody2LevelChanged = (e) => setAwardingBody2Level(e.target.value);

  //HUMAN & FINANCIAL RESOURCES HANDLE CHANGE
  const onAssessorsCountChanged = (e) => setAssessorsCount(e.target.value);
  const onVerifiersCountChanged = (e) => setVerifiersCount(e.target.value);

  //ORGANIZATIONAL STAFFING HANDLE CHANGE
  const onCentreManagerNameChanged = (e) => setCentreManagerName(e.target.value);
  const onCentreManagerQualificationChanged = (e) => setCentreManagerQualification(e.target.value);
  const onAssessor1NameChanged = (e) => setAssessor1Name(e.target.value);
  const onAssessor1QualificationChanged = (e) => setAssessor1Qualification(e.target.value);
  const onAssessor2NameChanged = (e) => setAssessor2Name(e.target.value);
  const onAssessor2QualificationChanged = (e) => setAssessor2Qualification(e.target.value);
  const onInternalVerifierNameChanged = (e) => setInternalVerifierName(e.target.value);
  const onInternalVerifierQualificationChanged = (e) =>
    setInternalVerifierQualification(e.target.value);
  const onLiaisonOfficerNameChanged = (e) => setLiaisonOfficerName(e.target.value);
  const onLiaisonOfficerQualificationChanged = (e) =>
    setLiaisonOfficerQualification(e.target.value);
  const onResourceOfficerNameChanged = (e) => setResourceOfficerName(e.target.value);
  const onResourceOfficerQualificationChanged = (e) =>
    setResourceOfficerQualification(e.target.value);
  const onOtherStaffNameChanged = (e) => setOtherStaffName(e.target.value);
  const onOtherStaffQualificationChanged = (e) => setOtherStaffQualification(e.target.value);
  const onOtherStaffDesignationChanged = (e) => setOtherStaffDesignation(e.target.value);

  //PHYSICAL FACILITIES HANDLE CHANGE
  const onTrainingRoomQuantityChanged = (e) => setTrainingRoomQuantity(e.target.value);
  const onTrainingRoomFloorAreaChanged = (e) => setTrainingRoomFloorArea(e.target.value);
  const onWorkshopQuantityChanged = (e) => setWorkshopQuantity(e.target.value);
  const onWorkshopFloorAreaChanged = (e) => setWorkshopFloorArea(e.target.value);
  const onPracticalTrainingSpaceQuantityChanged = (e) =>
    setPracticalTrainingSpaceQuantity(e.target.value);
  const onPracticalTrainingSpaceFloorAreaChanged = (e) =>
    setPracticalTrainingSpaceFloorArea(e.target.value);
  const onTechnicalLabQuantityChanged = (e) => setTechnicalLabQuantity(e.target.value);
  const onTechnicalLabFloorAreaChanged = (e) => setTechnicalLabFloorArea(e.target.value);
  const onRecordsRoomQuantityChanged = (e) => setRecordsRoomQuantity(e.target.value);
  const onRecordsRoomFloorAreaChanged = (e) => setRecordsRoomFloorArea(e.target.value);
  const onAuditoriumQuantityChanged = (e) => setAuditoriumQuantity(e.target.value);
  const onAuditoriumFloorAreaChanged = (e) => setAuditoriumFloorArea(e.target.value);
  const onLibraryQuantityChanged = (e) => setLibraryQuantity(e.target.value);
  const onLibraryFloorAreaChanged = (e) => setLibraryFloorArea(e.target.value);
  const onUtilityQuantityChanged = (e) => setUtilityQuantity(e.target.value);
  const onUtilityFloorAreaChanged = (e) => setUtilityFloorArea(e.target.value);
  const onMgtOfficesQuantityChanged = (e) => setMgtOfficesQuantity(e.target.value);
  const onMgtOfficesFloorAreaChanged = (e) => setMgtOfficesFloorArea(e.target.value);
  const onStaffRoomQuantityChanged = (e) => setStaffRoomQuantity(e.target.value);
  const onStaffRoomFloorAreaChanged = (e) => setStaffRoomFloorArea(e.target.value);
  const onToiletQuantityChanged = (e) => setToiletQuantity(e.target.value);
  const onToiletFloorAreaChanged = (e) => setToiletFloorArea(e.target.value);
  const onFirstAidFireFacilityQuantityChanged = (e) =>
    setFirstAidFireFacilityQuantity(e.target.value);
  const onFirstAidFireFacilityFloorAreaChanged = (e) =>
    setFirstAidFireFacilityFloorArea(e.target.value);
  const onHostelQuantityChanged = (e) => setHostelQuantity(e.target.value);
  const onHostelFloorAreaChanged = (e) => setHostelFloorArea(e.target.value);
  const onTradeToolsQuantityChanged = (e) => setTradeToolsQuantity(e.target.value);
  const onTradeToolsFloorAreaChanged = (e) => setTradeToolsFloorArea(e.target.value);
  const onOtherFacilityNameChanged = (e) => setOtherFacilityName(e.target.value);
  const onOtherFacilityQuantityChanged = (e) => setOtherFacilityQuantity(e.target.value);
  const onOtherFacilityFloorAreaChanged = (e) => setOtherFacilityFloorArea(e.target.value);

  //TRAINING STAFF HANDLE CHANGE
  const onTrainingStaff1NameChanged = (e) => setTrainingStaff1Name(e.target.value);
  const onTrainingStaff1QualificationChanged = (e) =>
    setTrainingStaff1Qualification(e.target.value);
  const onTrainingStaff1ExperienceChanged = (e) => setTrainingStaff1Experience(e.target.value);
  const onTrainingStaff2NameChanged = (e) => setTrainingStaff2Name(e.target.value);
  const onTrainingStaff2QualificationChanged = (e) =>
    setTrainingStaff2Qualification(e.target.value);
  const onTrainingStaff2ExperienceChanged = (e) => setTrainingStaff2Experience(e.target.value);
  const onTrainingStaff3NameChanged = (e) => setTrainingStaff3Name(e.target.value);
  const onTrainingStaff3QualificationChanged = (e) =>
    setTrainingStaff3Qualification(e.target.value);
  const onTrainingStaff3ExperienceChanged = (e) => setTrainingStaff3Experience(e.target.value);

  //SUPPORTING DOCS & DECLARATION HANDLE CHANGE
  const onApplicantsNameChanged = (e) => setApplicantsName(e.target.value);
  const onApplicantsPositionChanged = (e) => setApplicantsPosition(e.target.value);
  const onSelectCredentials = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCentreCredentials(e.target.files[0]);
    }
  };

  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    type: "success", // "success" or "error"
    message: "",
  });

  // Show notification
  const showNotification = (type, message) => {
    setNotification({ open: true, type, message });
  };

  // Close notification
  const closeNotification = () => {
    setNotification({ ...notification, open: false });
  };

  // Form submission handler
  const onSaveCentreApplicationClicked = async (e) => {
    e.preventDefault();

    if (!centreCredentials) {
      console.error("No file selected.");
      return;
    }

    try {
      // Step 1: Upload the zip file to the server
      const zipFormData = new FormData();
      zipFormData.append("centreCredentials", centreCredentials);

      const uploadResponse = await axios.post(
        `${apiUrl}/api/centreapplications/upload-credentials`,
        zipFormData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      const centreCredentialsUrl = uploadResponse.data.filePath;

      // Step 3: Append url and store centre application
      // Map qualifications to their corresponding IDs
      const selectedQualificationIds = qualifications
        .map(
          (qualification) =>
            qualificationsData.find((data) => data.qualificationType === qualification)?.id
        )
        .filter((id) => id);

      const formData = {
        centreName,
        cacNumber,
        yearOfInc: yearOfInc.$y || "",
        permanentCentreAddress,
        trainingCentreAddress,
        phone,
        email,
        website,
        centreHead,
        centreHeadEmail,
        centreHeadPhone,
        qualifications: selectedQualificationIds,
        zone,
        stateOfRez,
        centreType,
        satelliteCentreName1,
        satelliteCentreAddress1,
        satelliteCentreName2,
        satelliteCentreAddress2,
        otherApprovals,
        awardingBody1,
        awardingBody1Qualification,
        awardingBody1Level,
        awardingBody1DateApproved,
        awardingBody1ApprovalStatus,
        awardingBody2,
        awardingBody2Qualification,
        awardingBody2Level,
        awardingBody2DateApproved,
        awardingBody2ApprovalStatus,
        nsqCertifiedAssessors,
        assessorsCount,
        nsqCertifiedVerifiers,
        verifiersCount,
        nsqCertifiedCentreManager,
        liaisonOfficerPresent,
        resourceOfficerPresent,
        siteBankPresent,
        trainingRoomAdequate,
        dataRoomAdequate,
        workshopAdequate,
        practicalSpaceAdequate,
        techLabAdequate,
        staffRoomAdequate,
        toiletAdequate,
        firstAidFireAdequate,
        hostelsAdequate,
        tradeToolsAdequate,
        auditoriumAvailable,
        libraryAdequate,
        waterAndPowerAdequate,
        mgtOfficesAdequate,
        nosAvailable,
        trainingManualAvailable,
        nosAndManualAlignment,
        otherTrainingMaterialsAvailable,
        nsqGuidelineAvailable,
        staffCompliantWithStandards,
        objectivesClear,
        classParticipation,
        centreManagerName,
        centreManagerQualification,
        assessor1Name,
        assessor1Qualification,
        assessor2Name,
        assessor2Qualification,
        internalVerifierName,
        internalVerifierQualification,
        liaisonOfficerName,
        liaisonOfficerQualification,
        resourceOfficerName,
        resourceOfficerQualification,
        otherStaffName,
        otherStaffQualification,
        otherStaffDesignation,
        trainingRoomQuantity,
        trainingRoomFloorArea,
        workshopQuantity,
        workshopFloorArea,
        practicalTrainingSpaceQuantity,
        practicalTrainingSpaceFloorArea,
        technicalLabQuantity,
        technicalLabFloorArea,
        recordsRoomQuantity,
        recordsRoomFloorArea,
        auditoriumQuantity,
        auditoriumFloorArea,
        libraryQuantity,
        libraryFloorArea,
        utilityQuantity,
        utilityFloorArea,
        mgtOfficesQuantity,
        mgtOfficesFloorArea,
        staffRoomQuantity,
        staffRoomFloorArea,
        toiletQuantity,
        toiletFloorArea,
        firstAidFireFacilityQuantity,
        firstAidFireFacilityFloorArea,
        hostelQuantity,
        hostelFloorArea,
        tradeToolsQuantity,
        tradeToolsFloorArea,
        otherFacilityName,
        otherFacilityQuantity,
        otherFacilityFloorArea,
        trainingStaff1Name,
        trainingStaff1Gender,
        trainingStaff1Qualification,
        trainingStaff1Experience,
        trainingStaff1Specialization,
        trainingStaff2Name,
        trainingStaff2Gender,
        trainingStaff2Qualification,
        trainingStaff2Experience,
        trainingStaff2Specialization,
        trainingStaff3Name,
        trainingStaff3Gender,
        trainingStaff3Qualification,
        trainingStaff3Experience,
        trainingStaff3Specialization,
        applicantsName,
        applicantsPosition,
        centreCredentialsUrl: centreCredentialsUrl,
      };

      const response = await axios.post(
        `${apiUrl}/api/centreapplications/addCentreApplication`,
        formData
      );

      if (response) {
        showNotification("success", "Centre Application submitted successfully!");

        // Redirect after a delay
        setTimeout(() => navigate("/"), 7000);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      showNotification("error", error.response.data.message);

      // Redirect after a delay
      setTimeout(() => navigate("/"), 7000);
    }
  };

  // Options for autocomplete components
  const options3 = qualificationsData.map((qualification) => ({
    id: qualification.id,
    qualificationType: qualification.qualificationType,
  }));

  const options1 = Object.entries(STATES).map(([key, value]) => ({
    key,
    state: value,
  }));

  const options4 = Object.entries(ZONES).map(([key, value]) => ({
    key,
    zone: value,
  }));

  const options0 = Object.entries(CENTRETYPES).map(([key, value]) => ({
    key,
    centreType: value,
  }));

  // Ensure values are always valid objects or null
  const mapValuesToOptions = (values, options, key) => {
    if (!Array.isArray(values) || !Array.isArray(options)) return [];
    return values
      .map((value) => options.find((option) => option[key] === value))
      .filter((option) => option); // Ensure no undefined values
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <MDBox sx={{ pl: 5, pr: 5 }}>
            <MDTypography sx={{ color: "#295C21", fontSize: 17, mb: 3 }}>
              APPLICATION / APPROVAL FORM INSTRUCTIONS
            </MDTypography>

            <MDBox sx={{ maxWidth: "80%" }}>
              <MDTypography sx={{ color: "#0b1c08", fontSize: 16, wordWrap: "break-word", mb: 3 }}>
                Please use this form to request approval as Training/Assessment Centre for NSQs
                Complete all sections in BLOCK LETTERS and give your details as you want them to
                appear in NIOB records.
              </MDTypography>
              <MDTypography sx={{ color: "#49a139", fontSize: 16, wordWrap: "break-word" }}>
                Read the following conditions which apply to this approval request, before you
                complete this form:
              </MDTypography>
              <List sx={{ fontSize: 14, wordWrap: "break-word" }}>
                <ListItem>
                  1. You will be required to pay an inspection fee which will be invoiced to your
                  Account.
                </ListItem>
                <ListItem>
                  2. Payment for an inspection visit fee is required per venue/satellite Centre.
                </ListItem>
                <ListItem>
                  3. Your Centre and Qualification(s) approval will lapse if there is no activity
                  within one year of approval being granted.
                </ListItem>
                <ListItem>
                  4. Failure to provide accurate information may result in withdrawal of any
                  Approval granted earlier.
                </ListItem>
                <ListItem sx={{ pb: 0 }}>
                  5. You&rsquo;ll need the following documents to complete your application:
                </ListItem>
                <List sx={{ ml: "20px" }}>
                  <ListItem sx={{ pt: 0, pb: 0 }}> - Evidence of Legal Registration</ListItem>
                  <ListItem sx={{ pt: 0, pb: 0 }}> - Centre Profile</ListItem>
                  <ListItem sx={{ pt: 0, pb: 0 }}> - Proof of Payment (NGN 150,000.00)</ListItem>
                  <ListItem sx={{ pt: 0, pb: 0 }}>
                    - Completed Centre Manager Declaration Form
                    <a
                      href="/2024-CENTRE APPLICATION Declaration FORM.pdf" // Adjusted path
                      style={{ color: "#49a139", textDecoration: "underline" }}
                      target="_blank"
                      download="2024-CENTRE_APPLICATION_Declaration_FORM.pdf" // Added download attribute
                    >
                      <u>(download the form here)</u>
                    </a>
                  </ListItem>
                  <ListItem sx={{ pt: 0, pb: 0 }}> - MOU (Site Banks / Facility Usage)</ListItem>
                  <ListItem sx={{ pt: 0, pb: 0 }}> - Training Staff CVs </ListItem>
                  <ListItem sx={{ pt: 0, pb: 0 }}> - List of Equipment </ListItem>
                </List>
              </List>
            </MDBox>
          </MDBox>
        );

      case 1:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography sx={{ color: "#295C21", fontSize: 12, gridColumn: "span 6" }}>
              CENTRE DETAILS
            </MDTypography>
            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  Centre Name <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCentreNameChanged}
              value={centreName}
              name="centreName"
              sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                variant="standard"
                label={
                  <span>
                    Year of Incorporation <span style={{ color: "red" }}> *</span>
                  </span>
                }
                openTo="year"
                onChange={(newValue) => setYearOfInc(newValue)}
                value={yearOfInc ? yearOfInc : dayjs("")}
                views={["year"]}
                maxDate={dayjs(maxYear)}
                minDate={dayjs("1960")}
                name="yearOfInc"
                sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
              />
            </LocalizationProvider>

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  CAC Number <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCACChanged}
              value={cacNumber}
              name="cacNumber"
              sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
              InputProps={{
                startAdornment: <InputAdornment position="start">RC</InputAdornment>,
              }}
            />

            <MDInput
              variant="standard"
              multiline
              rows={3}
              type="text"
              label={
                <span>
                  Permanent Centre Address <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onPermanentCentreAddressChanged}
              value={permanentCentreAddress}
              name="permanentCentreAddress"
              sx={{ gridColumn: { xs: "span 6", sm: "span 3" } }}
            />

            <MDInput
              variant="standard"
              multiline
              rows={3}
              type="text"
              label="Training Centre Address"
              onChange={onTrainingCentreAddressChanged}
              value={trainingCentreAddress}
              name="trainingCentreAddress"
              sx={{ gridColumn: { xs: "span 6", sm: "span 3" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  Phone Number <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onPhoneChanged}
              value={phone}
              name="phone"
              sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
              InputProps={{
                startAdornment: <InputAdornment position="start">+234</InputAdornment>,
              }}
            />

            <MDInput
              variant="standard"
              type="email"
              label={
                <span>
                  Email <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onEmailChanged}
              value={email}
              name="email"
              sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="Website"
              onChange={onWebsiteChanged}
              value={website}
              name="website"
              sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  Centre Head Fullname <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCentreHeadChanged}
              value={centreHead}
              name="centreHead"
              sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  Centre Head Phone Number <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCentreHeadPhoneChanged}
              value={centreHeadPhone}
              name="centreHeadPhone"
              sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
              InputProps={{
                startAdornment: <InputAdornment position="start">+234</InputAdornment>,
              }}
            />

            <MDInput
              variant="standard"
              type="email"
              label="Centre Head Email"
              onChange={onCentreHeadEmailChanged}
              value={centreHeadEmail}
              name="centreHeadEmail"
              sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
            />

            <MDAutocomplete
              id="zone-selector"
              sx={{ gridColumn: { xs: "span 6", sm: "span 3" } }}
              options={options4}
              value={options4.find((option) => option.zone === zone) || null} // Set a single value or null if no match
              onChange={(event, value) => setZone(value?.zone || "")} // Set only one zone value
              getOptionLabel={(option) => option.zone} // Access the zone property for display
              isOptionEqualToValue={(option, value) => option.zone === value?.zone}
              renderInput={(params) => (
                <MDInput {...params} label="Select Zone" variant="standard" />
              )}
            />

            <MDAutocomplete
              id="state-selector"
              sx={{ gridColumn: { xs: "span 6", sm: "span 3" } }}
              options={options1}
              value={options1.find((option) => option.state === stateOfRez) || null} // Set null if no selection
              onChange={(event, value) => setStateOfRez(value?.state || "")} // Update the stateOfRez based on selection
              getOptionLabel={(option) => option.state} // Display the state property for options
              isOptionEqualToValue={(option, value) => option.state === value?.state} // Match state
              renderInput={(params) => (
                <MDInput {...params} label="Select State" variant="standard" />
              )}
            />

            <MDAutocomplete
              id="qualification-selector"
              sx={{ gridColumn: "span 6", mb: "30px" }}
              options={options3}
              multiple
              value={mapValuesToOptions(qualifications || [], options3 || [], "qualificationType")}
              onChange={(event, value) =>
                setQualifications(value.map((item) => item.qualificationType))
              }
              getOptionLabel={(option) => option.qualificationType || ""}
              isOptionEqualToValue={(option, value) =>
                option.qualificationType === value?.qualificationType
              }
              renderInput={(params) => (
                <MDInput {...params} label="Select Qualifications" variant="standard" />
              )}
            />
          </MDBox>
        );

      case 2:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography sx={{ color: "#295C21", fontSize: 12, gridColumn: "span 6" }}>
              YOUR ORGANISATION
            </MDTypography>
            <MDAutocomplete
              id="centretype-selector"
              sx={{ gridColumn: "span 6" }}
              options={options0}
              value={options0.find((option) => option.centreType === centreType) || null} // Set a single value or null if no match
              onChange={(event, value) => setCentreType(value?.centreType || "")} // Set only one centreType value
              getOptionLabel={(option) => option.centreType} // Access the centreType property for display
              isOptionEqualToValue={(option, value) => option.centreType === value?.centreType}
              renderInput={(params) => (
                <MDInput {...params} label="Select Centre Type" variant="standard" />
              )}
            />

            <MDTypography sx={{ gridColumn: "span 6", fontSize: "15px", mb: "-10px" }}>
              - Satellite Centres where Training is provided other than your Main Campus/Centre
            </MDTypography>
            <MDInput
              variant="standard"
              type="text"
              label="Satellite Centre Name 1"
              onChange={onSatelliteCentreName1Changed}
              value={satelliteCentreName1}
              name="satelliteCentreName1"
              sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="Satellite Centre Address 1"
              onChange={onSatelliteCentreAddress1Changed}
              value={satelliteCentreAddress1}
              name="satelliteCentreAddress1"
              sx={{ gridColumn: { xs: "span 6", sm: "span 4" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="Satellite Centre Name 2"
              onChange={onSatelliteCentreName2Changed}
              value={satelliteCentreName2}
              name="satelliteCentreName2"
              sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="Satellite Centre Address 2"
              onChange={onSatelliteCentreAddress2Changed}
              value={satelliteCentreAddress2}
              name="satelliteCentreAddress2"
              sx={{ gridColumn: { xs: "span 6", sm: "span 4" }, mb: "30px" }}
            />

            <FormControl fullWidth sx={{ gridColumn: "span 6" }}>
              <FormLabel component="legend" sx={{ color: "#49a139", fontSize: "15px" }}>
                Indicate if you are currently an approved Centre of another Awarding Body
              </FormLabel>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={itp}
                      onChange={onOtherApprovalsChange}
                      name="itp"
                      sx={{ color: "#49a139" }}
                    />
                  }
                  label="ITP"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={naddc}
                      onChange={onOtherApprovalsChange}
                      name="naddc"
                      sx={{ color: "#49a139" }}
                    />
                  }
                  label="NADDC"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={nabteb}
                      onChange={onOtherApprovalsChange}
                      name="nabteb"
                      sx={{ color: "#49a139" }}
                    />
                  }
                  label="NABTEB"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cityGuild}
                      onChange={onOtherApprovalsChange}
                      name="cityGuild"
                      sx={{ color: "#49a139" }}
                    />
                  }
                  label="City & Guilds"
                />
              </FormGroup>
            </FormControl>

            <MDTypography sx={{ gridColumn: "span 6", fontSize: "15px" }}>
              If your Centre is currently approved as a Centre for NSQ by another Awarding Body,
              please give details below:
            </MDTypography>

            <MDInput
              variant="standard"
              type="text"
              label="Awarding Body 1"
              onChange={onAwardingBody1Changed}
              value={awardingBody1}
              name="awardingBody1"
              sx={{ gridColumn: { xs: "span 6", sm: "span 1" } }}
            />

            <MDAutocomplete
              id="awardingBody1-qualification-selector"
              sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
              options={options3}
              value={
                options3.find(
                  (option) => option.qualificationType === awardingBody1Qualification
                ) || null
              } // Set null if no selection
              onChange={(event, value) =>
                setAwardingBody1Qualification(value?.qualificationType || "")
              } // Update the stateOfRez based on selection
              getOptionLabel={(option) => option.qualificationType} // Display the state property for options
              isOptionEqualToValue={(option, value) =>
                option.qualificationType === value?.qualificationType
              } // Match state
              renderInput={(params) => (
                <MDInput {...params} label="Qualification 1" variant="standard" />
              )}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Level"
              onChange={onAwardingBody1LevelChanged}
              value={awardingBody1Level}
              name="awardingBody1Level"
              sx={{ gridColumn: { xs: "span 6", sm: "span 1" } }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                variant="standard"
                label={<span>Date Approved</span>}
                value={
                  awardingBody1DateApproved ? dayjs(awardingBody1DateApproved, "DD-MM-YYYY") : null
                }
                onChange={(newValue) =>
                  setAwardingBody1DateApproved(newValue ? newValue.format("DD-MM-YYYY") : "")
                }
                views={["year", "month", "day"]}
                maxDate={dayjs()} // Today's date
                minDate={dayjs("1960-01-01")} // Min date set to 1960
                name="awardingBody1DateApproved"
                sx={{ gridColumn: { xs: "span 6", sm: "span 1" } }}
                renderInput={(params) => (
                  <MDInput {...params} value={awardingBody1DateApproved || ""} /> // Format displayed value
                )}
              />
            </LocalizationProvider>

            <MDAutocomplete
              id="awardingBody1ApprovalStatus-selector"
              sx={{ gridColumn: { xs: "span 6", sm: "span 1" } }}
              options={[
                { label: "Done", value: "Done" },
                { label: "Pending", value: "Pending" },
              ]} // Array of options for the autocomplete
              getOptionLabel={(option) => option?.label || ""} // Display label of the option
              renderInput={(params) => (
                <MDInput
                  {...params}
                  fullWidth
                  label="Approval Status"
                  variant="standard"
                  aria-autocomplete="list"
                  spellCheck={false}
                  autoComplete="off"
                  autoCapitalize="none"
                />
              )}
              onChange={(event, value) => {
                setAwardingBody1ApprovalStatus(value?.value || ""); // Update state based on selection
              }}
              isOptionEqualToValue={
                (option, value) => option?.value === value?.value // Safely compare option and value
              }
            />

            <MDInput
              variant="standard"
              type="text"
              label="Awarding Body 2"
              onChange={onAwardingBody2Changed}
              value={awardingBody2}
              name="awardingBody2"
              sx={{ gridColumn: { xs: "span 6", sm: "span 1" } }}
            />

            <MDAutocomplete
              id="awardingBody2-qualification-selector"
              sx={{ gridColumn: { xs: "span 6", sm: "span 2" } }}
              options={options3}
              value={
                options3.find(
                  (option) => option.qualificationType === awardingBody2Qualification
                ) || null
              } // Set null if no selection
              onChange={(event, value) =>
                setAwardingBody2Qualification(value?.qualificationType || "")
              } // Update the stateOfRez based on selection
              getOptionLabel={(option) => option.qualificationType} // Display the state property for options
              isOptionEqualToValue={(option, value) =>
                option.qualificationType === value?.qualificationType
              } // Match state
              renderInput={(params) => (
                <MDInput {...params} label="Qualification 2" variant="standard" />
              )}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Level"
              onChange={onAwardingBody2LevelChanged}
              value={awardingBody2Level}
              name="awardingBody2Level"
              sx={{ gridColumn: { xs: "span 6", sm: "span 1" } }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                variant="standard"
                label={<span>Date Approved</span>}
                value={
                  awardingBody2DateApproved ? dayjs(awardingBody2DateApproved, "DD-MM-YYYY") : null
                }
                onChange={(newValue) =>
                  setAwardingBody2DateApproved(newValue ? newValue.format("DD-MM-YYYY") : "")
                }
                views={["year", "month", "day"]}
                maxDate={dayjs()} // Today's date
                minDate={dayjs("1960-01-01")} // Min date set to 1960
                name="awardingBody2DateApproved"
                sx={{ gridColumn: { xs: "span 6", sm: "span 1" } }}
                renderInput={(params) => (
                  <MDInput {...params} value={awardingBody2DateApproved || ""} /> // Format displayed value
                )}
              />
            </LocalizationProvider>

            <MDAutocomplete
              id="awardingBody2ApprovalStatus-selector"
              sx={{ gridColumn: { xs: "span 6", sm: "span 1" }, mb: "30px" }}
              options={[
                { label: "Done", value: "Done" },
                { label: "Pending", value: "Pending" },
              ]} // Array of options for the autocomplete
              getOptionLabel={(option) => option.label || ""} // Display label of the option
              renderInput={(params) => (
                <MDInput
                  {...params}
                  fullWidth
                  label="Approval Status"
                  variant="standard"
                  aria-autocomplete="list"
                  spellCheck={false}
                  autoComplete="off"
                  autoCapitalize="none"
                />
              )}
              onChange={(event, value) => {
                setAwardingBody2ApprovalStatus(value?.value || "");
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />
          </MDBox>
        );

      case 3:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography sx={{ color: "#295C21", fontSize: 12, gridColumn: "span 6" }}>
              HUMAN & FINANCIAL RESOURCES
            </MDTypography>
            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                i.{"\u00A0"} Are there NSQ certified Assessors at your Centre?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="nsqCertifiedAssessor-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  nsqCertifiedAssessors
                    ? { label: nsqCertifiedAssessors, value: nsqCertifiedAssessors }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setNsqCertifiedAssessors(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                ii.{"\u00A0"} How many Assessors are available at Centre?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDInput
                variant="standard"
                type="number"
                label="Level"
                onChange={onAssessorsCountChanged}
                value={assessorsCount}
                InputProps={{ inputProps: { min: 0 } }}
                name="assessorsCount"
                sx={{ width: "20%" }}
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                iii.{"\u00A0"} Are there NSQ certified Verifiers at your Centre?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="nsqCertifiedVerifier-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  nsqCertifiedVerifiers
                    ? { label: nsqCertifiedVerifiers, value: nsqCertifiedVerifiers }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setNsqCertifiedVerifiers(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                iv.{"\u00A0"} How many Verifiers are available at Centre?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDInput
                variant="standard"
                type="number"
                label="Level"
                onChange={onVerifiersCountChanged}
                value={verifiersCount}
                InputProps={{ inputProps: { min: 0 } }}
                name="verifiersCount"
                sx={{ width: "20%" }}
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                v.{"\u00A0"} Is the Centre Manager NSQ certified?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="nsqCertifiedCentreManager-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  nsqCertifiedCentreManager
                    ? { label: nsqCertifiedCentreManager, value: nsqCertifiedCentreManager }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setNsqCertifiedCentreManager(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                vi.{"\u00A0"} Is there a Liaison Officer with the Industry at your Centre?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="liaisonOfficerPresent-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  liaisonOfficerPresent
                    ? { label: liaisonOfficerPresent, value: liaisonOfficerPresent }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setLiaisonOfficerPresent(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                vii.{"\u00A0"} Is there a Resource/Data Officer at your Centre?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="resourceOfficerPresent-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  resourceOfficerPresent
                    ? { label: resourceOfficerPresent, value: resourceOfficerPresent }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setResourceOfficerPresent(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                viii.{"\u00A0"} Are there evidences/records of site-banks for apprenticeship
                training at your Centre?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="siteBankPresent-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={siteBankPresent ? { label: siteBankPresent, value: siteBankPresent } : null}
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setSiteBankPresent(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>
          </MDBox>
        );

      case 4:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography sx={{ color: "#295C21", fontSize: 12, gridColumn: "span 6" }}>
              PHYSICAL INFRASTRUCTURE
            </MDTypography>
            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                ix.{"\u00A0"} Are the Classrooms/Training Rooms adequate in terms of space,
                lighting, ventilation & traffic?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="trainingRoomAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  trainingRoomAdequate
                    ? { label: trainingRoomAdequate, value: trainingRoomAdequate }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setTrainingRoomAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                x.{"\u00A0"} Is there a Data/Records room available and adequate?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="dataRoomAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  dataRoomAdequate ? { label: dataRoomAdequate, value: dataRoomAdequate } : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setDataRoomAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xi.{"\u00A0"} Are Workshop spaces adequate and fit for purpose?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="workshopAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  workshopAdequate ? { label: workshopAdequate, value: workshopAdequate } : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setWorkshopAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xii.{"\u00A0"} Are Practical training spaces available and suitable for each trade?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="practicalSpaceAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  practicalSpaceAdequate
                    ? { label: practicalSpaceAdequate, value: practicalSpaceAdequate }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setPracticalSpaceAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xiii.{"\u00A0"} Are Technical laboratories AVAILABLE or HIRED?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="techLabAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "AVAILABLE", value: "AVAILABLE" },
                  { label: "HIRED", value: "HIRED" },
                ]} // Array of options for the autocomplete
                value={techLabAdequate ? { label: techLabAdequate, value: techLabAdequate } : null}
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setTechLabAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xiv.{"\u00A0"} Are Staff rooms adequate and fit for purpose?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="staffRoomAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  staffRoomAdequate ? { label: staffRoomAdequate, value: staffRoomAdequate } : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setStaffRoomAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xv.{"\u00A0"} Are Toilets available and functional?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="toiletAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={toiletAdequate ? { label: toiletAdequate, value: toiletAdequate } : null}
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setToiletAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xvi.{"\u00A0"} Are First Aid/Firefighting facilities available and functional?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="firstAidFireAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  firstAidFireAdequate
                    ? { label: firstAidFireAdequate, value: firstAidFireAdequate }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setFirstAidFireAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xvii.{"\u00A0"} Are Hostels available and fit for purpose?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="hostelsAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={hostelsAdequate ? { label: hostelsAdequate, value: hostelsAdequate } : null}
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setHostelsAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xviii.{"\u00A0"} Are there Tools and Equipment for relevant trades at your Centre?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="tradeToolsAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  tradeToolsAdequate
                    ? { label: tradeToolsAdequate, value: tradeToolsAdequate }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setTradeToolsAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xix.{"\u00A0"} Is Auditorium AVAILABLE or HIRED?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="auditoriumAvailable-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "AVAILABLE", value: "AVAILABLE" },
                  { label: "HIRED", value: "HIRED" },
                ]} // Array of options for the autocomplete
                value={
                  auditoriumAvailable
                    ? { label: auditoriumAvailable, value: auditoriumAvailable }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setAuditoriumAvailable(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xx.{"\u00A0"} Are Library and Study rooms adequate?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="libraryAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={libraryAdequate ? { label: libraryAdequate, value: libraryAdequate } : null}
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setLibraryAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xxi.{"\u00A0"} Is Water and Power supply adequate?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="waterAndPowerAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  waterAndPowerAdequate
                    ? { label: waterAndPowerAdequate, value: waterAndPowerAdequate }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setWaterAndPowerAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xxii.{"\u00A0"} Are Management Offices adequate and fit for purpose?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="mgtOfficesAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  mgtOfficesAdequate
                    ? { label: mgtOfficesAdequate, value: mgtOfficesAdequate }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setMgtOfficesAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>
          </MDBox>
        );

      case 5:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography sx={{ color: "#295C21", fontSize: 12, gridColumn: "span 6" }}>
              CODE OF PRACTICE
            </MDTypography>
            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xxiii.{"\u00A0"} Is NOS available at your Centre?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="nosAvailable-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={nosAvailable ? { label: nosAvailable, value: nosAvailable } : null}
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setNosAvailable(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xxiv.{"\u00A0"} Are there Training Manuals at your Centre?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="trainingManualAvailable-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  trainingManualAvailable
                    ? { label: trainingManualAvailable, value: trainingManualAvailable }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setTrainingManualAvailable(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xxv.{"\u00A0"} Are the Manuals aligned to the NOS?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="nosAndManualAlignment-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  nosAndManualAlignment
                    ? { label: nosAndManualAlignment, value: nosAndManualAlignment }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setNosAndManualAlignment(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xxvi.{"\u00A0"} Are there other Training materials that are relevant to the training
                needs?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="otherTrainingMaterialsAvailable-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  otherTrainingMaterialsAvailable
                    ? {
                        label: otherTrainingMaterialsAvailable,
                        value: otherTrainingMaterialsAvailable,
                      }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setOtherTrainingMaterialsAvailable(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xxvii.{"\u00A0"} Is there an NSQ Code of Practice and Implementation Guideline at
                your Centre?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="nsqGuidelineAvailable-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  nsqGuidelineAvailable
                    ? { label: nsqGuidelineAvailable, value: nsqGuidelineAvailable }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setNsqGuidelineAvailable(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xxviii.{"\u00A0"} Are the Trainers and Staff conversant/comply with these documents?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="staffCompliantWithStandards-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  staffCompliantWithStandards
                    ? { label: staffCompliantWithStandards, value: staffCompliantWithStandards }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setStaffCompliantWithStandards(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xxix.{"\u00A0"} Are the Objectives of the training clearly defined?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="objectivesClear-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={objectivesClear ? { label: objectivesClear, value: objectivesClear } : null}
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setObjectivesClear(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xxx.{"\u00A0"} Is Class participation through questioning encouraged?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="classParticipation-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  classParticipation
                    ? { label: classParticipation, value: classParticipation }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select if in place"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setClassParticipation(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>
          </MDBox>
        );

      case 6:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography sx={{ color: "#295C21", fontSize: 12, gridColumn: "span 6" }}>
              ORGANISATIONAL STAFFING
            </MDTypography>
            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  Centre Manager&apos;s FullName <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCentreManagerNameChanged}
              value={centreManagerName}
              name="centreManagerName"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  Centre Manager&apos;s Qualification <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCentreManagerQualificationChanged}
              value={centreManagerQualification}
              name="centreManagerQualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  Assessor 1&apos;s FullName <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onAssessor1NameChanged}
              value={assessor1Name}
              name="assessor1Name"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  Assessor 1&apos;s Qualification <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onAssessor1QualificationChanged}
              value={assessor1Qualification}
              name="assessor1Qualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Assessor 2&apos;s FullName</span>}
              onChange={onAssessor2NameChanged}
              value={assessor2Name}
              name="assessor2Name"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Assessor 2&apos;s Qualification</span>}
              onChange={onAssessor2QualificationChanged}
              value={assessor2Qualification}
              name="assessor2Qualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  Internal Verifier&apos;s FullName <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onInternalVerifierNameChanged}
              value={internalVerifierName}
              name="internalVerifierName"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  Internal Verifier&apos;s Qualification <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onInternalVerifierQualificationChanged}
              value={internalVerifierQualification}
              name="internalVerifierQualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Industry/Workplace Liaison Officer&apos;s FullName</span>}
              onChange={onLiaisonOfficerNameChanged}
              value={liaisonOfficerName}
              name="liaisonOfficerName"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Industry/Workplace Liaison Officer&apos;s Qualification</span>}
              onChange={onInternalVerifierQualificationChanged}
              value={liaisonOfficerQualification}
              name="liaisonOfficerQualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Resource/Data Officer&apos;s FullName</span>}
              onChange={onResourceOfficerNameChanged}
              value={resourceOfficerName}
              name="resourceOfficerName"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Resource/Data Officer&apos;s Qualification</span>}
              onChange={onResourceOfficerQualificationChanged}
              value={resourceOfficerQualification}
              name="resourceOfficerQualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Other Staff&apos;s FullName</span>}
              onChange={onOtherStaffNameChanged}
              value={otherStaffName}
              name="otherStaffName"
              sx={{ gridColumn: "span 2" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Other Staff&apos;s Qualification</span>}
              onChange={onOtherStaffQualificationChanged}
              value={otherStaffQualification}
              name="otherStaffQualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: "span 2" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Other Staff&apos;s Designation</span>}
              onChange={onOtherStaffDesignationChanged}
              value={otherStaffDesignation}
              name="otherStaffDesignation"
              helperText="e.g: Program Officer, HOD Admin, etc."
              sx={{ gridColumn: "span 2" }}
            />
          </MDBox>
        );

      case 7:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography sx={{ color: "#295C21", fontSize: 12, gridColumn: "span 6" }}>
              PHYSICAL FACILITIES
            </MDTypography>
            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Classrooms/Training Rooms - Quantity"
              onChange={onTrainingRoomQuantityChanged}
              value={trainingRoomQuantity}
              name="trainingRoomQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Classrooms/Training Rooms - Total Floor Area"
              onChange={onTrainingRoomFloorAreaChanged}
              value={trainingRoomFloorArea}
              name="trainingRoomFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Workshops - Quantity"
              onChange={onWorkshopQuantityChanged}
              value={workshopQuantity}
              name="workshopQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Workshops - Total Floor Area"
              onChange={onWorkshopFloorAreaChanged}
              value={workshopFloorArea}
              name="workshopFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Practical Training Space for each Trade - Quantity"
              onChange={onPracticalTrainingSpaceQuantityChanged}
              value={practicalTrainingSpaceQuantity}
              name="practicalTrainingSpaceQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Practical Training Space for each Trade - Total Floor Area"
              onChange={onPracticalTrainingSpaceFloorAreaChanged}
              value={practicalTrainingSpaceFloorArea}
              name="practicalTrainingSpaceFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Technical Laboratories - Quantity"
              onChange={onTechnicalLabQuantityChanged}
              value={technicalLabQuantity}
              name="technicalLabQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Technical Laboratories - Total Floor Area"
              onChange={onTechnicalLabFloorAreaChanged}
              value={technicalLabFloorArea}
              name="technicalLabFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Data/Records Room - Quantity"
              onChange={onRecordsRoomQuantityChanged}
              value={recordsRoomQuantity}
              name="recordsRoomQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Data/Records Room - Total Floor Area"
              onChange={onRecordsRoomFloorAreaChanged}
              value={recordsRoomFloorArea}
              name="recordsRoomFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Auditorium - Quantity"
              onChange={onAuditoriumQuantityChanged}
              value={auditoriumQuantity}
              name="auditoriumQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Auditorium - Total Floor Area"
              onChange={onAuditoriumFloorAreaChanged}
              value={auditoriumFloorArea}
              name="auditoriumFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Library & Study Rooms - Quantity"
              onChange={onLibraryQuantityChanged}
              value={libraryQuantity}
              name="libraryQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Library & Study Rooms - Total Floor Area"
              onChange={onLibraryFloorAreaChanged}
              value={libraryFloorArea}
              name="libraryFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Water & Power Supply (Public/Private) - Quantity"
              onChange={onUtilityQuantityChanged}
              value={utilityQuantity}
              name="utilityQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Water & Power Supply (Public/Private) - Total Floor Area"
              onChange={onUtilityFloorAreaChanged}
              value={utilityFloorArea}
              name="utilityFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Management Offices - Quantity"
              onChange={onMgtOfficesQuantityChanged}
              value={mgtOfficesQuantity}
              name="mgtOfficesQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Management Offices - Total Floor Area"
              onChange={onMgtOfficesFloorAreaChanged}
              value={mgtOfficesFloorArea}
              name="mgtOfficesFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Staff Rooms - Quantity"
              onChange={onStaffRoomQuantityChanged}
              value={staffRoomQuantity}
              name="staffRoomQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Staff Rooms - Total Floor Area"
              onChange={onStaffRoomFloorAreaChanged}
              value={staffRoomFloorArea}
              name="staffRoomFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Toilets - Quantity"
              onChange={onToiletQuantityChanged}
              value={toiletQuantity}
              name="toiletQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Toilets - Total Floor Area"
              onChange={onToiletFloorAreaChanged}
              value={toiletFloorArea}
              name="toiletFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="First Aid/Firefighting Facility - Quantity"
              onChange={onFirstAidFireFacilityQuantityChanged}
              value={firstAidFireFacilityQuantity}
              name="firstAidFireFacilityQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="First Aid/Firefighting Facility - Total Floor Area"
              onChange={onFirstAidFireFacilityFloorAreaChanged}
              value={firstAidFireFacilityFloorArea}
              name="firstAidFireFacilityFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Hostel - Quantity"
              onChange={onHostelQuantityChanged}
              value={hostelQuantity}
              name="hostelQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Hostel - Total Floor Area"
              onChange={onHostelFloorAreaChanged}
              value={hostelFloorArea}
              name="hostelFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              label="Tools & Equipment for relevant Trades - Quantity"
              onChange={onTradeToolsQuantityChanged}
              value={tradeToolsQuantity}
              name="tradeToolsQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Tools & Equipment for relevant Trades - Total Floor Area"
              onChange={onTradeToolsFloorAreaChanged}
              value={tradeToolsFloorArea}
              name="tradeToolsFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="Other Facility Name"
              onChange={onOtherFacilityNameChanged}
              value={otherFacilityName}
              name="otherFacilityName"
              sx={{ gridColumn: "span 2" }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Others - Quantity"
              onChange={onOtherFacilityQuantityChanged}
              value={otherFacilityQuantity}
              name="otherFacilityQuantity"
              InputProps={{ inputProps: { min: 0 } }}
              sx={{ gridColumn: "span 2" }}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Others - Total Floor Area"
              onChange={onOtherFacilityFloorAreaChanged}
              value={otherFacilityFloorArea}
              name="otherFacilityFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 2" }}
            />
          </MDBox>
        );

      case 8:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography sx={{ color: "#295C21", fontSize: 12, gridColumn: "span 6" }}>
              TRAINING STAFF
            </MDTypography>
            <MDInput
              variant="standard"
              type="text"
              label="Full Name of Staff 1"
              onChange={onTrainingStaff1NameChanged}
              value={trainingStaff1Name}
              name="trainingStaff1Name"
              sx={{ gridColumn: "span 2" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="Staff's Qualification"
              onChange={onTrainingStaff1QualificationChanged}
              value={trainingStaff1Qualification}
              name="trainingStaff1Qualification"
              helperText="e.g: BSc. Building, etc."
              sx={{ gridColumn: "span 1" }}
            />

            <MDAutocomplete
              id="trainingStaff1Gender-selector"
              sx={{ gridColumn: "span 1" }}
              options={[
                { label: "Male", value: "Male" },
                { label: "Female", value: "Female" },
              ]} // Array of options for the autocomplete
              getOptionLabel={(option) => option.label || ""} // Display label of the option
              renderInput={(params) => (
                <MDInput
                  {...params}
                  fullWidth
                  label="Gender"
                  variant="standard"
                  aria-autocomplete="list"
                  spellCheck={false}
                  autoComplete="off"
                  autoCapitalize="none"
                />
              )}
              onChange={(event, value) => {
                setTrainingStaff1Gender(value?.value || ""); // Call setGender with the value (e.g., "male" or "female")
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Practical Experience"
              onChange={onTrainingStaff1ExperienceChanged}
              value={trainingStaff1Experience}
              name="trainingStaff1Experience"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">yrs</InputAdornment>,
              }}
              sx={{ gridColumn: "span 1" }}
            />

            <MDAutocomplete
              id="trainingStaff1Specialization-selector"
              options={options3}
              value={
                options3.find(
                  (option) => option.qualificationType === trainingStaff1Specialization
                ) || null
              }
              onChange={(event, value) =>
                setTrainingStaff1Specialization(value?.qualificationType || "")
              }
              getOptionLabel={(option) => option.qualificationType || ""}
              isOptionEqualToValue={(option, value) =>
                option.qualificationType === value?.qualificationType
              }
              renderInput={(params) => (
                <MDInput {...params} label="Trade Specialization" variant="standard" />
              )}
              sx={{ gridColumn: "span 1" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="Full Name of Staff 2"
              onChange={onTrainingStaff2NameChanged}
              value={trainingStaff2Name}
              name="trainingStaff2Name"
              sx={{ gridColumn: "span 2" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="Staff's Qualification"
              onChange={onTrainingStaff2QualificationChanged}
              value={trainingStaff2Qualification}
              name="trainingStaff2Qualification"
              helperText="e.g: BSc. Building, etc."
              sx={{ gridColumn: "span 1" }}
            />

            <MDAutocomplete
              id="trainingStaff2Gender-selector"
              sx={{ gridColumn: "span 1" }}
              options={[
                { label: "Male", value: "Male" },
                { label: "Female", value: "Female" },
              ]} // Array of options for the autocomplete
              getOptionLabel={(option) => option.label || ""} // Display label of the option
              renderInput={(params) => (
                <MDInput
                  {...params}
                  fullWidth
                  label="Gender"
                  variant="standard"
                  aria-autocomplete="list"
                  spellCheck={false}
                  autoComplete="off"
                  autoCapitalize="none"
                />
              )}
              onChange={(event, value) => {
                setTrainingStaff2Gender(value?.value || ""); // Call setGender with the value (e.g., "male" or "female")
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Practical Experience"
              onChange={onTrainingStaff2ExperienceChanged}
              value={trainingStaff2Experience}
              name="trainingStaff2Experience"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">yrs</InputAdornment>,
              }}
              sx={{ gridColumn: "span 1" }}
            />

            <MDAutocomplete
              id="trainingStaff2Specialization-selector"
              options={options3}
              value={
                options3.find(
                  (option) => option.qualificationType === trainingStaff2Specialization
                ) || null
              }
              onChange={(event, value) =>
                setTrainingStaff2Specialization(value?.qualificationType || "")
              }
              getOptionLabel={(option) => option.qualificationType || ""}
              isOptionEqualToValue={(option, value) =>
                option.qualificationType === value?.qualificationType
              }
              renderInput={(params) => (
                <MDInput {...params} label="Trade Specialization" variant="standard" />
              )}
              sx={{ gridColumn: "span 1" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="Full Name of Staff 3"
              onChange={onTrainingStaff3NameChanged}
              value={trainingStaff3Name}
              name="trainingStaff3Name"
              sx={{ gridColumn: "span 2" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="Staff's Qualification"
              onChange={onTrainingStaff3QualificationChanged}
              value={trainingStaff3Qualification}
              name="trainingStaff3Qualification"
              helperText="e.g: BSc. Building, etc."
              sx={{ gridColumn: "span 1" }}
            />

            <MDAutocomplete
              id="trainingStaff3Gender-selector"
              sx={{ gridColumn: "span 1" }}
              options={[
                { label: "Male", value: "Male" },
                { label: "Female", value: "Female" },
              ]} // Array of options for the autocomplete
              getOptionLabel={(option) => option.label || ""} // Display label of the option
              renderInput={(params) => (
                <MDInput
                  {...params}
                  fullWidth
                  label="Gender"
                  variant="standard"
                  aria-autocomplete="list"
                  spellCheck={false}
                  autoComplete="off"
                  autoCapitalize="none"
                />
              )}
              onChange={(event, value) => {
                setTrainingStaff3Gender(value?.value || ""); // Call setGender with the value (e.g., "male" or "female")
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Practical Experience"
              onChange={onTrainingStaff3ExperienceChanged}
              value={trainingStaff3Experience}
              name="trainingStaff3Experience"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">yrs</InputAdornment>,
              }}
              sx={{ gridColumn: "span 1" }}
            />

            <MDAutocomplete
              id="trainingStaff3Specialization-selector"
              options={options3}
              value={
                options3.find(
                  (option) => option.qualificationType === trainingStaff3Specialization
                ) || null
              }
              onChange={(event, value) =>
                setTrainingStaff3Specialization(value?.qualificationType || "")
              }
              getOptionLabel={(option) => option.qualificationType || ""}
              isOptionEqualToValue={(option, value) =>
                option.qualificationType === value?.qualificationType
              }
              renderInput={(params) => (
                <MDInput {...params} label="Trade Specialization" variant="standard" />
              )}
              sx={{ gridColumn: "span 1" }}
            />
          </MDBox>
        );

      case 9:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography sx={{ color: "#295C21", fontSize: 12, gridColumn: "span 6" }}>
              SUPPORTING DOCUMENTS
            </MDTypography>
            <MDButton
              component="label"
              role={undefined}
              variant="gradient"
              color="dark"
              tabIndex={-1}
              sx={{ gridColumn: "span 4", mb: 5 }}
              startIcon={<CloudUploadIcon />}
            >
              Click to Upload Centre Credentials
              <VisuallyHiddenInput type="file" onChange={onSelectCredentials} />
            </MDButton>

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  Full Name of Applicant <span style={{ color: "red" }}>*</span>
                </span>
              }
              onChange={onApplicantsNameChanged}
              value={applicantsName}
              name="applicantsName"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={
                <span>
                  Position/Designation of Applicant <span style={{ color: "red" }}>*</span>
                </span>
              }
              onChange={onApplicantsPositionChanged}
              value={applicantsPosition}
              name="applicantsPosition"
              sx={{ gridColumn: "span 3" }}
            />
          </MDBox>
        );
    }
  };

  return (
    <CoverLayout image={bgImage}>
      <MDSnackbar
        color={notification.type}
        icon={notification.type === "success" ? "check" : "warning"}
        title="NIOB Skill CaMP"
        content={notification.message}
        open={notification.open}
        onClose={closeNotification}
        close={closeNotification}
        bgWhite
      />
      <MDBox sx={{ mb: 5 }}>
        <Card>
          <form>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              sx={{
                p: 3,
                ml: 5,
                mr: 5,
                mt: -3,
                background: "#4CAF50 !important",
                boxShadow: "#4CAF50 !important",
                "& .MuiStepLabel-label": {
                  color: "#ffffff !important", // Ensures text remains visible
                },
                "& .MuiStepIcon-root": {
                  color: "#ffffff !important", // Changes icon color
                  stroke: "#ffffff !important",
                  fill: "#ffffff !important",
                },
                "& .MuiStepConnector-root": {
                  color: "#ffffff !important", // Changes connector color
                  stroke: "#ffffff !important",
                  fill: "#ffffff !important",
                },
              }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <MDBox sx={{ mt: 3 }}>{renderStepContent(activeStep)}</MDBox>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
                mb: 2,
                mr: 3,
                ml: 3,
              }}
            >
              <MDButton disabled={activeStep === 0} onClick={handleBack}>
                Back
              </MDButton>
              {/* Next/Finish Button */}
              {activeStep === steps.length - 1 ? (
                <MDButton onClick={onSaveCentreApplicationClicked}>Send</MDButton>
              ) : (
                <MDButton onClick={handleNext}>Next</MDButton>
              )}
            </MDBox>
          </form>
        </Card>
      </MDBox>
      <Footer />
    </CoverLayout>
  );
}

// View Centre Application
export function ViewCentreApplication() {
  const { id } = useParams();
  const apiUrl = "https://api.skillcamp.niobnat.org";
  const [cApp, setCApp] = useState({});
  const [eqas, setEQAs] = useState({});

  // Fetch centreapplication data
  useEffect(() => {
    const fetchCentreApplicationData = async () => {
      try {
        const { data } = await axios.get(`${apiUrl}/api/centreapplications/${id}`);
        setCApp({
          ...data,
          qualificationsList: data.qualificationsList || [], // Ensure it’s always an array
        });
      } catch (error) {
        console.error("Error fetching centre application data:", error);
      }
    };
    fetchCentreApplicationData();
  }, [id]);

  // Fetch eqa data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await axios.get(`${apiUrl}/api/users/getUsers`);

        // Filter users where roleType is "EQAM"
        const eqamUsers = data.filter((user) =>
          user.roles.some((role) => role.roleType === "EQAM")
        );

        setEQAs(eqamUsers);
      } catch (error) {
        console.error("Error fetching eqa data:", error);
      }
    };
    fetchUserData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar2 />
      <MDBox mt={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <CASidebar />
            </Grid>
            <Grid item xs={12} md={9}>
              <CAInformation cApp={cApp} />

              {/* Check if cApp.eqaAssignedTo is null or empty */}
              {cApp.eqaAssignedTo ? (
                (() => {
                  const assignedEqa = cApp.eqaInCharge;
                  return assignedEqa ? (
                    <Paper elevation={1} sx={{ p: 3, borderRadius: 2, mt: 3 }} id="assign-eqa">
                      <MDTypography variant="h6" sx={{ mb: 2 }}>
                        EQA Assigned
                      </MDTypography>
                      <MDTypography variant="h4" fontWeight="light" color="success">
                        {assignedEqa.firstName.toUpperCase()} {assignedEqa.middleName.toUpperCase()}
                        &nbsp;{assignedEqa.lastName.toUpperCase()}
                      </MDTypography>
                    </Paper>
                  ) : (
                    <Paper elevation={1} sx={{ p: 3, borderRadius: 2, mt: 3 }} id="assign-eqa">
                      <MDTypography variant="h6">EQA Assigned</MDTypography>
                      <MDTypography variant="subtitle2" fontWeight="regular" color="danger">
                        NOT FOUND
                      </MDTypography>
                    </Paper>
                  );
                })()
              ) : (
                <AssignEQACard eqas={eqas} />
              )}

              <DownloadCADocsCard />

              <ApproveCACard />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

// Display All CentreApplications assigned to a particular EQA
export function MyCentreApplications() {
  const { columns, rows } = myCentreapplicationsTableData();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
              >
                <MDTypography variant="h6" color="white">
                  My Centres
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  canSearch={true}
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={{ variant: "gradient", color: "success" }}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

// Centre Quality Check Form with Stepper
export function CentreQualityCheck() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [qualificationsData, setQualificationsData] = useState([]);
  const maxYear = new Date().getFullYear().toString();
  const today = new Date().toString();

  const apiUrl = "https://api.skillcamp.niobnat.org";

  const { auth } = useAuth();
  const userRole = auth?.roles?.toLowerCase();

  if (userRole !== "eqam") {
    return <Navigate to="/error404" />;
  }

  const [activeStep, setActiveStep] = useState(0);

  //CENTRE DETAILS INITIALIZE
  const [centreName, setCentreName] = useState("");
  const [validCentreName, setValidCentreName] = useState(false);
  const [yearOfInc, setYearOfInc] = useState();
  const [cacNumber, setCACNumber] = useState("");
  const [validCACNumber, setValidCACNumber] = useState(false);
  const [permanentCentreAddress, setPermanentCentreAddress] = useState("");
  const [trainingCentreAddress, setTrainingCentreAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [validPhone, setValidPhone] = useState(false);
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [website, setWebsite] = useState("");
  const [validWebsite, setValidWebsite] = useState(false);
  const [centreHead, setCentreHead] = useState("");
  const [validCentreHead, setValidCentreHead] = useState(false);
  const [centreHeadPhone, setCentreHeadPhone] = useState("");
  const [validCentreHeadPhone, setValidCentreHeadPhone] = useState(false);
  const [centreHeadEmail, setCentreHeadEmail] = useState("");
  const [validCentreHeadEmail, setValidCentreHeadEmail] = useState(false);
  const [qualifications, setQualifications] = useState([]);
  const [zone, setZone] = useState("");
  const [stateOfRez, setStateOfRez] = useState("");

  //CENTRE ORGANISATION INITIALIZE
  const [centreType, setCentreType] = useState("");
  const [satelliteCentreName1, setSatelliteCentreName1] = useState("");
  const [validSatelliteCentreName1, setValidSatelliteCentreName1] = useState(false);
  const [satelliteCentreAddress1, setSatelliteCentreAddress1] = useState("");
  const [satelliteCentreName2, setSatelliteCentreName2] = useState("");
  const [validSatelliteCentreName2, setValidSatelliteCentreName2] = useState(false);
  const [satelliteCentreAddress2, setSatelliteCentreAddress2] = useState("");
  const [otherApprovals, setOtherApprovals] = React.useState({
    itp: false,
    naddc: false,
    nabteb: false,
    cityGuild: false,
  });
  const [awardingBody1, setAwardingBody1] = useState("");
  const [awardingBody1Qualification, setAwardingBody1Qualification] = useState("");
  const [awardingBody1Level, setAwardingBody1Level] = useState(1);
  const [awardingBody1DateApproved, setAwardingBody1DateApproved] = useState();
  const [awardingBody1ApprovalStatus, setAwardingBody1ApprovalStatus] = useState("");
  const [awardingBody2, setAwardingBody2] = useState("");
  const [awardingBody2Qualification, setAwardingBody2Qualification] = useState("");
  const [awardingBody2Level, setAwardingBody2Level] = useState(1);
  const [awardingBody2DateApproved, setAwardingBody2DateApproved] = useState();
  const [awardingBody2ApprovalStatus, setAwardingBody2ApprovalStatus] = useState("");

  //EQA FEEDBACK 1 INITIALIZE
  const [nsqCertifiedAssessors, setNsqCertifiedAssessors] = useState("");
  const [assessorsCount, setAssessorsCount] = useState(0);
  const [nsqCertifiedVerifiers, setNsqCertifiedVerifiers] = useState("");
  const [verifiersCount, setVerifiersCount] = useState(0);
  const [nsqCertifiedCentreManager, setNsqCertifiedCentreManager] = useState("");
  const [liaisonOfficerPresent, setLiaisonOfficerPresent] = useState("");
  const [resourceOfficerPresent, setResourceOfficerPresent] = useState("");
  const [siteBankPresent, setSiteBankPresent] = useState("");
  const [nsqCertifiedAssessorsEQAView, setNsqCertifiedAssessorsEQAView] = useState("");
  const [assessorsCountEQAView, setAssessorsCountEQAView] = useState("");
  const [nsqCertifiedVerifiersEQAView, setNsqCertifiedVerifiersEQAView] = useState("");
  const [verifiersCountEQAView, setVerifiersCountEQAView] = useState("");
  const [nsqCertifiedCentreManagerEQAView, setNsqCertifiedCentreManagerEQAView] = useState("");
  const [liaisonOfficerPresentEQAView, setLiaisonOfficerPresentEQAView] = useState("");
  const [resourceOfficerPresentEQAView, setResourceOfficerPresentEQAView] = useState("");
  const [siteBankPresentEQAView, setSiteBankPresentEQAView] = useState("");
  const [eqaFeedbackOnCentreHumanResource, setEqaFeedbackOnCentreHumanResource] = useState("");

  //EQA FEEDBACK 2 INITIALIZE
  const [trainingRoomAdequate, setTrainingRoomAdequate] = useState("");
  const [dataRoomAdequate, setDataRoomAdequate] = useState("");
  const [workshopAdequate, setWorkshopAdequate] = useState("");
  const [practicalSpaceAdequate, setPracticalSpaceAdequate] = useState("");
  const [techLabAdequate, setTechLabAdequate] = useState("");
  const [staffRoomAdequate, setStaffRoomAdequate] = useState("");
  const [toiletAdequate, setToiletAdequate] = useState("");
  const [firstAidFireAdequate, setFirstAidFireAdequate] = useState("");
  const [hostelsAdequate, setHostelsAdequate] = useState("");
  const [tradeToolsAdequate, setTradeToolsAdequate] = useState("");
  const [auditoriumAvailable, setAuditoriumAvailable] = useState("");
  const [libraryAdequate, setLibraryAdequate] = useState("");
  const [waterAndPowerAdequate, setWaterAndPowerAdequate] = useState("");
  const [mgtOfficesAdequate, setMgtOfficesAdequate] = useState("");
  const [trainingRoomAdequateEQAView, setTrainingRoomAdequateEQAView] = useState("");
  const [dataRoomAdequateEQAView, setDataRoomAdequateEQAView] = useState("");
  const [workshopAdequateEQAView, setWorkshopAdequateEQAView] = useState("");
  const [practicalSpaceAdequateEQAView, setPracticalSpaceAdequateEQAView] = useState("");
  const [techLabAdequateEQAView, setTechLabAdequateEQAView] = useState("");
  const [staffRoomAdequateEQAView, setStaffRoomAdequateEQAView] = useState("");
  const [toiletAdequateEQAView, setToiletAdequateEQAView] = useState("");
  const [firstAidFireAdequateEQAView, setFirstAidFireAdequateEQAView] = useState("");
  const [hostelsAdequateEQAView, setHostelsAdequateEQAView] = useState("");
  const [tradeToolsAdequateEQAView, setTradeToolsAdequateEQAView] = useState("");
  const [auditoriumAvailableEQAView, setAuditoriumAvailableEQAView] = useState("");
  const [libraryAdequateEQAView, setLibraryAdequateEQAView] = useState("");
  const [waterAndPowerAdequateEQAView, setWaterAndPowerAdequateEQAView] = useState("");
  const [mgtOfficesAdequateEQAView, setMgtOfficesAdequateEQAView] = useState("");
  const [eqaFeedbackOnCentreFacilities, setEqaFeedbackOnCentreFacilities] = useState("");

  //EQA FEEDBACK 3 INITIALIZE
  const [nosAvailable, setNosAvailable] = useState("");
  const [trainingManualAvailable, setTrainingManualAvailable] = useState("");
  const [nosAndManualAlignment, setNosAndManualAlignment] = useState("");
  const [otherTrainingMaterialsAvailable, setOtherTrainingMaterialsAvailable] = useState("");
  const [nsqGuidelineAvailable, setNsqGuidelineAvailable] = useState("");
  const [staffCompliantWithStandards, setStaffCompliantWithStandards] = useState("");
  const [objectivesClear, setObjectivesClear] = useState("");
  const [classParticipation, setClassParticipation] = useState("");
  const [nosAvailableEQAView, setNosAvailableEQAView] = useState("");
  const [trainingManualAvailableEQAView, setTrainingManualAvailableEQAView] = useState("");
  const [nosAndManualAlignedEQAView, setNosAndManualAlignedEQAView] = useState("");
  const [otherTrainingMaterialsAvailableEQAView, setOtherTrainingMaterialsAvailableEQAView] =
    useState("");
  const [nsqGuidelineAvailableEQAView, setNsqGuidelineAvailableEQAView] = useState("");
  const [staffCompliantWithStandardsEQAView, setStaffCompliantWithStandardsEQAView] = useState("");
  const [objectivesClearEQAView, setObjectivesClearEQAView] = useState("");
  const [classParticipationEQAView, setClassParticipationEQAView] = useState("");
  const [eqaFeedbackOnCentreNOSNSQ, setEqaFeedbackOnCentreNOSNSQ] = useState("");

  //EQA FINAL REPORT
  const [conduciveLearningEnvironment, setConduciveLearningEnvironment] = useState("");
  const [policyAndProcedure, setPolicyAndProcedure] = useState("");
  const [practicalWorkPlacementArrangement, setPracticalWorkPlacementArrangement] = useState("");
  const [sufficientQualityAssuranceTeam, setSufficientQualityAssuranceTeam] = useState("");
  const [learnersSupportSystem, setLearnersSupportSystem] = useState("");
  const [eqaFinalFeedbackOnCentre, setEqaFinalFeedbackOnCentre] = useState("");

  //EQA SUPPORTING DOCUMENT
  const [eqaReports, setEqaReports] = useState(null);
  const [eqaReportsUrl, setEqaReportsUrl] = useState("");
  const [previewEqaReports, setPreviewEqaReports] = useState();

  //ORGANISATION STAFFING INITIALIZE
  const [centreManagerName, setCentreManagerName] = useState("");
  const [centreManagerQualification, setCentreManagerQualification] = useState("");
  const [assessor1Name, setAssessor1Name] = useState("");
  const [assessor1Qualification, setAssessor1Qualification] = useState("");
  const [assessor2Name, setAssessor2Name] = useState("");
  const [assessor2Qualification, setAssessor2Qualification] = useState("");
  const [internalVerifierName, setInternalVerifierName] = useState("");
  const [internalVerifierQualification, setInternalVerifierQualification] = useState("");
  const [liaisonOfficerName, setLiaisonOfficerName] = useState("");
  const [liaisonOfficerQualification, setLiaisonOfficerQualification] = useState("");
  const [resourceOfficerName, setResourceOfficerName] = useState("");
  const [resourceOfficerQualification, setResourceOfficerQualification] = useState("");
  const [otherStaffName, setOtherStaffName] = useState("");
  const [otherStaffQualification, setOtherStaffQualification] = useState("");
  const [otherStaffDesignation, setOtherStaffDesignation] = useState("");

  //PHYSICAL FACILITIES INITIALIZE
  const [trainingRoomQuantity, setTrainingRoomQuantity] = useState(0);
  const [trainingRoomFloorArea, setTrainingRoomFloorArea] = useState(0);
  const [workshopQuantity, setWorkshopQuantity] = useState(0);
  const [workshopFloorArea, setWorkshopFloorArea] = useState(0);
  const [practicalTrainingSpaceQuantity, setPracticalTrainingSpaceQuantity] = useState(0);
  const [practicalTrainingSpaceFloorArea, setPracticalTrainingSpaceFloorArea] = useState(0);
  const [technicalLabQuantity, setTechnicalLabQuantity] = useState(0);
  const [technicalLabFloorArea, setTechnicalLabFloorArea] = useState(0);
  const [recordsRoomQuantity, setRecordsRoomQuantity] = useState(0);
  const [recordsRoomFloorArea, setRecordsRoomFloorArea] = useState(0);
  const [auditoriumQuantity, setAuditoriumQuantity] = useState(0);
  const [auditoriumFloorArea, setAuditoriumFloorArea] = useState(0);
  const [libraryQuantity, setLibraryQuantity] = useState(0);
  const [libraryFloorArea, setLibraryFloorArea] = useState(0);
  const [utilityQuantity, setUtilityQuantity] = useState(0);
  const [utilityFloorArea, setUtilityFloorArea] = useState(0);
  const [mgtOfficesQuantity, setMgtOfficesQuantity] = useState(0);
  const [mgtOfficesFloorArea, setMgtOfficesFloorArea] = useState(0);
  const [staffRoomQuantity, setStaffRoomQuantity] = useState(0);
  const [staffRoomFloorArea, setStaffRoomFloorArea] = useState(0);
  const [toiletQuantity, setToiletQuantity] = useState(0);
  const [toiletFloorArea, setToiletFloorArea] = useState(0);
  const [firstAidFireFacilityQuantity, setFirstAidFireFacilityQuantity] = useState(0);
  const [firstAidFireFacilityFloorArea, setFirstAidFireFacilityFloorArea] = useState(0);
  const [hostelQuantity, setHostelQuantity] = useState(0);
  const [hostelFloorArea, setHostelFloorArea] = useState(0);
  const [tradeToolsQuantity, setTradeToolsQuantity] = useState(0);
  const [tradeToolsFloorArea, setTradeToolsFloorArea] = useState(0);
  const [otherFacilityName, setOtherFacilityName] = useState("");
  const [otherFacilityQuantity, setOtherFacilityQuantity] = useState(0);
  const [otherFacilityFloorArea, setOtherFacilityFloorArea] = useState(0);

  //TRAINING STAFF INITIALIZE
  const [trainingStaff1Name, setTrainingStaff1Name] = useState("");
  const [trainingStaff1Gender, setTrainingStaff1Gender] = useState("");
  const [trainingStaff1Qualification, setTrainingStaff1Qualification] = useState("");
  const [trainingStaff1Experience, setTrainingStaff1Experience] = useState(0);
  const [trainingStaff1Specialization, setTrainingStaff1Specialization] = useState("");
  const [trainingStaff2Name, setTrainingStaff2Name] = useState("");
  const [trainingStaff2Gender, setTrainingStaff2Gender] = useState("");
  const [trainingStaff2Qualification, setTrainingStaff2Qualification] = useState("");
  const [trainingStaff2Experience, setTrainingStaff2Experience] = useState(0);
  const [trainingStaff2Specialization, setTrainingStaff2Specialization] = useState("");
  const [trainingStaff3Name, setTrainingStaff3Name] = useState("");
  const [trainingStaff3Gender, setTrainingStaff3Gender] = useState("");
  const [trainingStaff3Qualification, setTrainingStaff3Qualification] = useState("");
  const [trainingStaff3Experience, setTrainingStaff3Experience] = useState(0);
  const [trainingStaff3Specialization, setTrainingStaff3Specialization] = useState("");

  //SUPPORTING DOCS & DECLARATION INITIALIZE
  const [applicantsName, setApplicantsName] = useState("");
  const [validApplicantsName, setValidApplicantsName] = useState(false);
  const [applicantsPosition, setApplicantsPosition] = useState("");
  const [validApplicantsPosition, setValidApplicantsPosition] = useState(false);

  //INITIALIZE UPLOAD VARIABLES
  const [centreCredentials, setCentreCredentials] = useState(null);
  //const [centreCredentialsURL, setCentreCredentialsURL] = useState("");
  const [previewCredentials, setPreviewCredentials] = useState();

  // Fetch centreapplication data
  useEffect(() => {
    const fetchCentreApplicationData = async () => {
      const { data } = await axios.get(`${apiUrl}/api/centreapplications/${id}`);

      setCentreName(data.centreName);
      setCACNumber(data.cacNumber);
      setYearOfInc(data.yearOfInc);
      setPermanentCentreAddress(data.permanentCentreAddress);
      setTrainingCentreAddress(data.trainingCentreAddress);
      setPhone(data.phone);
      setEmail(data.email);
      setWebsite(data.website);
      setCentreHead(data.centreHead);
      setCentreHeadEmail(data.centreHeadEmail);
      setCentreHeadPhone(data.centreHeadPhone);
      setQualifications(data.qualificationsList.map((q) => q.qualificationType));
      setZone(data.zone);
      setStateOfRez(data.stateOfRez);
      setCentreType(data.centreType);
      setSatelliteCentreName1(data.satelliteCentreName1);
      setSatelliteCentreAddress1(data.satelliteCentreAddress1);
      setSatelliteCentreName2(data.satelliteCentreName2);
      setSatelliteCentreAddress2(data.satelliteCentreAddress2);
      setOtherApprovals(data?.otherApprovals[0]);
      setAwardingBody1(data.awardingBody1);
      setAwardingBody1Qualification(data.awardingBody1Qualification);
      setAwardingBody1Level(data.awardingBody1Level);
      setAwardingBody1DateApproved(data.awardingBody1DateApproved);
      setAwardingBody1ApprovalStatus(data.awardingBody1ApprovalStatus);
      setAwardingBody2(data.awardingBody2);
      setAwardingBody2Qualification(data.awardingBody2Qualification);
      setAwardingBody2Level(data.awardingBody2Level);
      setAwardingBody2DateApproved(data.awardingBody2DateApproved);
      setAwardingBody2ApprovalStatus(data.awardingBody2ApprovalStatus);
      setNsqCertifiedAssessors(data.nsqCertifiedAssessors);
      setAssessorsCount(data.assessorsCount);
      setNsqCertifiedVerifiers(data.nsqCertifiedVerifiers);
      setVerifiersCount(data.verifiersCount);
      setNsqCertifiedCentreManager(data.nsqCertifiedCentreManager);
      setLiaisonOfficerPresent(data.liaisonOfficerPresent);
      setResourceOfficerPresent(data.resourceOfficerPresent);
      setSiteBankPresent(data.siteBankPresent);
      setTrainingRoomAdequate(data.trainingRoomAdequate);
      setDataRoomAdequate(data.dataRoomAdequate);
      setWorkshopAdequate(data.workshopAdequate);
      setPracticalSpaceAdequate(data.practicalSpaceAdequate);
      setTechLabAdequate(data.techLabAdequate);
      setStaffRoomAdequate(data.staffRoomAdequate);
      setToiletAdequate(data.toiletAdequate);
      setFirstAidFireAdequate(data.firstAidFireAdequate);
      setHostelsAdequate(data.hostelsAdequate);
      setTradeToolsAdequate(data.tradeToolsAdequate);
      setAuditoriumAvailable(data.auditoriumAvailable);
      setLibraryAdequate(data.libraryAdequate);
      setWaterAndPowerAdequate(data.waterAndPowerAdequate);
      setMgtOfficesAdequate(data.mgtOfficesAdequate);
      setNosAvailable(data.nosAvailable);
      setTrainingManualAvailable(data.trainingManualAvailable);
      setNosAndManualAlignment(data.nosAndManualAlignment);
      setOtherTrainingMaterialsAvailable(data.otherTrainingMaterialsAvailable);
      setNsqGuidelineAvailable(data.nsqGuidelineAvailable);
      setStaffCompliantWithStandards(data.staffCompliantWithStandards);
      setObjectivesClear(data.objectivesClear);
      setClassParticipation(data.classParticipation);
      setCentreManagerName(data.centreManagerName);
      setCentreManagerQualification(data.centreManagerQualification);
      setAssessor1Name(data.assessor1Name);
      setAssessor1Qualification(data.assessor1Qualification);
      setAssessor2Name(data.assessor2Name);
      setAssessor2Qualification(data.assessor2Qualification);
      setInternalVerifierName(data.internalVerifierName);
      setInternalVerifierQualification(data.internalVerifierQualification);
      setLiaisonOfficerName(data.liaisonOfficerName);
      setLiaisonOfficerQualification(data.liaisonOfficerQualification);
      setResourceOfficerName(data.resourceOfficerName);
      setResourceOfficerQualification(data.resourceOfficerQualification);
      setOtherStaffName(data.otherStaffName);
      setOtherStaffQualification(data.otherStaffQualification);
      setOtherStaffDesignation(data.otherStaffDesignation);
      setTrainingRoomQuantity(data.trainingRoomQuantity);
      setTrainingRoomFloorArea(data.trainingRoomFloorArea);
      setWorkshopQuantity(data.workshopQuantity);
      setWorkshopFloorArea(data.workshopFloorArea);
      setPracticalTrainingSpaceQuantity(data.practicalTrainingSpaceQuantity);
      setPracticalTrainingSpaceFloorArea(data.practicalTrainingSpaceFloorArea);
      setTechnicalLabQuantity(data.technicalLabQuantity);
      setTechnicalLabFloorArea(data.technicalLabFloorArea);
      setRecordsRoomQuantity(data.recordsRoomQuantity);
      setRecordsRoomFloorArea(data.recordsRoomFloorArea);
      setAuditoriumQuantity(data.auditoriumQuantity);
      setAuditoriumFloorArea(data.auditoriumFloorArea);
      setLibraryQuantity(data.libraryQuantity);
      setLibraryFloorArea(data.libraryFloorArea);
      setUtilityQuantity(data.utilityQuantity);
      setUtilityFloorArea(data.utilityFloorArea);
      setMgtOfficesQuantity(data.mgtOfficesQuantity);
      setMgtOfficesFloorArea(data.mgtOfficesFloorArea);
      setStaffRoomQuantity(data.staffRoomQuantity);
      setStaffRoomFloorArea(data.staffRoomFloorArea);
      setToiletQuantity(data.toiletQuantity);
      setToiletFloorArea(data.toiletFloorArea);
      setFirstAidFireFacilityQuantity(data.firstAidFireFacilityQuantity);
      setFirstAidFireFacilityFloorArea(data.firstAidFireFacilityFloorArea);
      setHostelQuantity(data.hostelQuantity);
      setHostelFloorArea(data.hostelFloorArea);
      setTradeToolsQuantity(data.tradeToolsQuantity);
      setTradeToolsFloorArea(data.tradeToolsFloorArea);
      setOtherFacilityName(data.otherFacilityName);
      setOtherFacilityQuantity(data.otherFacilityQuantity);
      setOtherFacilityFloorArea(data.otherFacilityFloorArea);
      setTrainingStaff1Name(data.trainingStaff1Name);
      setTrainingStaff1Gender(data.trainingStaff1Gender);
      setTrainingStaff1Qualification(data.trainingStaff1Qualification);
      setTrainingStaff1Experience(data.trainingStaff1Experience);
      setTrainingStaff1Specialization(data.trainingStaff1Specialization);
      setTrainingStaff2Name(data.trainingStaff2Name);
      setTrainingStaff2Gender(data.trainingStaff2Gender);
      setTrainingStaff2Qualification(data.trainingStaff2Qualification);
      setTrainingStaff2Experience(data.trainingStaff2Experience);
      setTrainingStaff2Specialization(data.trainingStaff2Specialization);
      setTrainingStaff3Name(data.trainingStaff3Name);
      setTrainingStaff3Gender(data.trainingStaff3Gender);
      setTrainingStaff3Qualification(data.trainingStaff3Qualification);
      setTrainingStaff3Experience(data.trainingStaff3Experience);
      setTrainingStaff3Specialization(data.trainingStaff3Specialization);
      setApplicantsName(data.applicantsName);
      setApplicantsPosition(data.applicantsPosition);
    };
    fetchCentreApplicationData();
  }, [id]);

  // Fetch qualifications data
  useEffect(() => {
    const fetchData = async () => {
      const qualificationsResponse = await axios.get(
        `${apiUrl}/api/qualifications/getQualifications`
      );

      setQualificationsData(qualificationsResponse.data);
    };

    fetchData();
  }, []);

  // Input validation
  useEffect(() => {
    setValidCentreName(CENTRE_REGEX.test(centreName));
    setValidEmail(EMAIL_REGEX.test(email));
  }, [centreName, email]);

  //CENTRE DETAILS HANDLE CHANGE
  const onCentreNameChanged = (e) => setCentreName(e.target.value);
  const onCACChanged = (e) => setCACNumber(e.target.value);
  const onPermanentCentreAddressChanged = (e) => setPermanentCentreAddress(e.target.value);
  const onTrainingCentreAddressChanged = (e) => setTrainingCentreAddress(e.target.value);
  const onPhoneChanged = (e) => setPhone(e.target.value);
  const onEmailChanged = (e) => setEmail(e.target.value);
  const onWebsiteChanged = (e) => setWebsite(e.target.value);
  const onCentreHeadChanged = (e) => setCentreHead(e.target.value);
  const onCentreHeadPhoneChanged = (e) => setCentreHeadPhone(e.target.value);
  const onCentreHeadEmailChanged = (e) => setCentreHeadEmail(e.target.value);

  //CENTRE ORGANIZATION HANDLE CHANGE
  const onCentreTypeChanged = (e) => setCentreType(e.target.value);
  const onSatelliteCentreName1Changed = (e) => setSatelliteCentreName1(e.target.value);
  const onSatelliteCentreAddress1Changed = (e) => setSatelliteCentreAddress1(e.target.value);
  const onSatelliteCentreName2Changed = (e) => setSatelliteCentreName2(e.target.value);
  const onSatelliteCentreAddress2Changed = (e) => setSatelliteCentreAddress2(e.target.value);
  const onOtherApprovalsChange = (event) => {
    setOtherApprovals({ ...otherApprovals, [event.target.name]: event.target.checked });
  };
  const { itp, naddc, nabteb, cityGuild } = otherApprovals;
  const onAwardingBody1Changed = (e) => setAwardingBody1(e.target.value);
  const onAwardingBody1LevelChanged = (e) => setAwardingBody1Level(e.target.value);
  const onAwardingBody2Changed = (e) => setAwardingBody2(e.target.value);
  const onAwardingBody2LevelChanged = (e) => setAwardingBody2Level(e.target.value);

  //HUMAN & FINANCIAL RESOURCES HANDLE CHANGE
  const onAssessorsCountChanged = (e) => setAssessorsCount(e.target.value);
  const onVerifiersCountChanged = (e) => setVerifiersCount(e.target.value);

  //ORGANIZATIONAL STAFFING HANDLE CHANGE
  const onCentreManagerNameChanged = (e) => setCentreManagerName(e.target.value);
  const onCentreManagerQualificationChanged = (e) => setCentreManagerQualification(e.target.value);
  const onAssessor1NameChanged = (e) => setAssessor1Name(e.target.value);
  const onAssessor1QualificationChanged = (e) => setAssessor1Qualification(e.target.value);
  const onAssessor2NameChanged = (e) => setAssessor2Name(e.target.value);
  const onAssessor2QualificationChanged = (e) => setAssessor2Qualification(e.target.value);
  const onInternalVerifierNameChanged = (e) => setInternalVerifierName(e.target.value);
  const onInternalVerifierQualificationChanged = (e) =>
    setInternalVerifierQualification(e.target.value);
  const onLiaisonOfficerNameChanged = (e) => setLiaisonOfficerName(e.target.value);
  const onLiaisonOfficerQualificationChanged = (e) =>
    setLiaisonOfficerQualification(e.target.value);
  const onResourceOfficerNameChanged = (e) => setResourceOfficerName(e.target.value);
  const onResourceOfficerQualificationChanged = (e) =>
    setResourceOfficerQualification(e.target.value);
  const onOtherStaffNameChanged = (e) => setOtherStaffName(e.target.value);
  const onOtherStaffQualificationChanged = (e) => setOtherStaffQualification(e.target.value);
  const onOtherStaffDesignationChanged = (e) => setOtherStaffDesignation(e.target.value);

  //PHYSICAL FACILITIES HANDLE CHANGE
  const onTrainingRoomQuantityChanged = (e) => setTrainingRoomQuantity(e.target.value);
  const onTrainingRoomFloorAreaChanged = (e) => setTrainingRoomFloorArea(e.target.value);
  const onWorkshopQuantityChanged = (e) => setWorkshopQuantity(e.target.value);
  const onWorkshopFloorAreaChanged = (e) => setWorkshopFloorArea(e.target.value);
  const onPracticalTrainingSpaceQuantityChanged = (e) =>
    setPracticalTrainingSpaceQuantity(e.target.value);
  const onPracticalTrainingSpaceFloorAreaChanged = (e) =>
    setPracticalTrainingSpaceFloorArea(e.target.value);
  const onTechnicalLabQuantityChanged = (e) => setTechnicalLabQuantity(e.target.value);
  const onTechnicalLabFloorAreaChanged = (e) => setTechnicalLabFloorArea(e.target.value);
  const onRecordsRoomQuantityChanged = (e) => setRecordsRoomQuantity(e.target.value);
  const onRecordsRoomFloorAreaChanged = (e) => setRecordsRoomFloorArea(e.target.value);
  const onAuditoriumQuantityChanged = (e) => setAuditoriumQuantity(e.target.value);
  const onAuditoriumFloorAreaChanged = (e) => setAuditoriumFloorArea(e.target.value);
  const onLibraryQuantityChanged = (e) => setLibraryQuantity(e.target.value);
  const onLibraryFloorAreaChanged = (e) => setLibraryFloorArea(e.target.value);
  const onUtilityQuantityChanged = (e) => setUtilityQuantity(e.target.value);
  const onUtilityFloorAreaChanged = (e) => setUtilityFloorArea(e.target.value);
  const onMgtOfficesQuantityChanged = (e) => setMgtOfficesQuantity(e.target.value);
  const onMgtOfficesFloorAreaChanged = (e) => setMgtOfficesFloorArea(e.target.value);
  const onStaffRoomQuantityChanged = (e) => setStaffRoomQuantity(e.target.value);
  const onStaffRoomFloorAreaChanged = (e) => setStaffRoomFloorArea(e.target.value);
  const onToiletQuantityChanged = (e) => setToiletQuantity(e.target.value);
  const onToiletFloorAreaChanged = (e) => setToiletFloorArea(e.target.value);
  const onFirstAidFireFacilityQuantityChanged = (e) =>
    setFirstAidFireFacilityQuantity(e.target.value);
  const onFirstAidFireFacilityFloorAreaChanged = (e) =>
    setFirstAidFireFacilityFloorArea(e.target.value);
  const onHostelQuantityChanged = (e) => setHostelQuantity(e.target.value);
  const onHostelFloorAreaChanged = (e) => setHostelFloorArea(e.target.value);
  const onTradeToolsQuantityChanged = (e) => setTradeToolsQuantity(e.target.value);
  const onTradeToolsFloorAreaChanged = (e) => setTradeToolsFloorArea(e.target.value);
  const onOtherFacilityNameChanged = (e) => setOtherFacilityName(e.target.value);
  const onOtherFacilityQuantityChanged = (e) => setOtherFacilityQuantity(e.target.value);
  const onOtherFacilityFloorAreaChanged = (e) => setOtherFacilityFloorArea(e.target.value);

  //TRAINING STAFF HANDLE CHANGE
  const onTrainingStaff1NameChanged = (e) => setTrainingStaff1Name(e.target.value);
  const onTrainingStaff1QualificationChanged = (e) =>
    setTrainingStaff1Qualification(e.target.value);
  const onTrainingStaff1ExperienceChanged = (e) => setTrainingStaff1Experience(e.target.value);
  const onTrainingStaff2NameChanged = (e) => setTrainingStaff2Name(e.target.value);
  const onTrainingStaff2QualificationChanged = (e) =>
    setTrainingStaff2Qualification(e.target.value);
  const onTrainingStaff2ExperienceChanged = (e) => setTrainingStaff2Experience(e.target.value);
  const onTrainingStaff3NameChanged = (e) => setTrainingStaff3Name(e.target.value);
  const onTrainingStaff3QualificationChanged = (e) =>
    setTrainingStaff3Qualification(e.target.value);
  const onTrainingStaff3ExperienceChanged = (e) => setTrainingStaff3Experience(e.target.value);

  //SUPPORTING DOCS & DECLARATION HANDLE CHANGE
  const onApplicantsNameChanged = (e) => setApplicantsName(e.target.value);
  const onApplicantsPositionChanged = (e) => setApplicantsPosition(e.target.value);

  //HANDLE CHANGE
  const onEqaFeedbackOnCentreHumanResourceChanged = (e) =>
    setEqaFeedbackOnCentreHumanResource(e.target.value);
  const onEqaFeedbackOnCentreFacilitiesChanged = (e) =>
    setEqaFeedbackOnCentreFacilities(e.target.value);
  const onEqaFeedbackOnCentreNOSNSQChanged = (e) => setEqaFeedbackOnCentreNOSNSQ(e.target.value);
  const onEqaFinalFeedbackOnCentreChanged = (e) => setEqaFinalFeedbackOnCentre(e.target.value);

  //SUPPORTING DOCS HANDLE CHANGE
  const onSelectEqaReports = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setEqaReports(e.target.files[0]);
    }
  };

  const canSave = [
    nsqCertifiedAssessorsEQAView !== "",
    assessorsCountEQAView !== "",
    nsqCertifiedVerifiersEQAView !== "",
    verifiersCountEQAView !== "",
    nsqCertifiedCentreManagerEQAView !== "",
    liaisonOfficerPresentEQAView !== "",
    resourceOfficerPresentEQAView !== "",
    siteBankPresentEQAView !== "",
    eqaFeedbackOnCentreHumanResource !== "",
    trainingRoomAdequateEQAView !== "",
    dataRoomAdequateEQAView !== "",
    workshopAdequateEQAView !== "",
    practicalSpaceAdequateEQAView !== "",
    techLabAdequateEQAView !== "",
    staffRoomAdequateEQAView !== "",
    toiletAdequateEQAView !== "",
    firstAidFireAdequateEQAView !== "",
    hostelsAdequateEQAView !== "",
    tradeToolsAdequateEQAView !== "",
    auditoriumAvailableEQAView !== "",
    libraryAdequateEQAView !== "",
    waterAndPowerAdequateEQAView !== "",
    mgtOfficesAdequateEQAView !== "",
    eqaFeedbackOnCentreFacilities !== "",
    nosAvailableEQAView !== "",
    trainingManualAvailableEQAView !== "",
    nosAndManualAlignedEQAView !== "",
    otherTrainingMaterialsAvailableEQAView !== "",
    nsqGuidelineAvailableEQAView !== "",
    staffCompliantWithStandardsEQAView !== "",
    objectivesClearEQAView !== "",
    classParticipationEQAView !== "",
    eqaFeedbackOnCentreNOSNSQ !== "",
    conduciveLearningEnvironment !== "",
    policyAndProcedure !== "",
    practicalWorkPlacementArrangement !== "",
    sufficientQualityAssuranceTeam !== "",
    learnersSupportSystem !== "",
    eqaFinalFeedbackOnCentre !== "",
    eqaReports,
  ].every(Boolean);

  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    type: "success", // "success" or "error"
    message: "",
  });

  // Show notification
  const showNotification = (type, message) => {
    setNotification({ open: true, type, message });
  };

  // Close notification
  const closeNotification = () => {
    setNotification({ ...notification, open: false });
  };

  // Form submission handler
  const onSaveEqaReportClicked = async (e) => {
    e.preventDefault();

    if (!eqaReports) {
      console.error("No file selected.");
      return;
    }

    try {
      // Step 1: Upload the zip file to the server
      const zipFormData = new FormData();
      zipFormData.append("eqareports", eqaReports);

      const uploadResponse = await axios.post(
        `${apiUrl}/api/centreapplications/upload-eqareports`,
        zipFormData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      const eqaReportsUrl = uploadResponse.data.filePath;

      // Step 3: Append url and store centre application
      const formData = {
        nsqCertifiedAssessorsEQAView,
        assessorsCountEQAView,
        nsqCertifiedVerifiersEQAView,
        verifiersCountEQAView,
        nsqCertifiedCentreManagerEQAView,
        liaisonOfficerPresentEQAView,
        resourceOfficerPresentEQAView,
        siteBankPresentEQAView,
        eqaFeedbackOnCentreHumanResource,
        trainingRoomAdequateEQAView,
        dataRoomAdequateEQAView,
        workshopAdequateEQAView,
        practicalSpaceAdequateEQAView,
        techLabAdequateEQAView,
        staffRoomAdequateEQAView,
        toiletAdequateEQAView,
        firstAidFireAdequateEQAView,
        hostelsAdequateEQAView,
        tradeToolsAdequateEQAView,
        auditoriumAvailableEQAView,
        libraryAdequateEQAView,
        waterAndPowerAdequateEQAView,
        mgtOfficesAdequateEQAView,
        eqaFeedbackOnCentreFacilities,
        nosAvailableEQAView,
        trainingManualAvailableEQAView,
        nosAndManualAlignedEQAView,
        otherTrainingMaterialsAvailableEQAView,
        nsqGuidelineAvailableEQAView,
        staffCompliantWithStandardsEQAView,
        objectivesClearEQAView,
        classParticipationEQAView,
        eqaFeedbackOnCentreNOSNSQ,
        conduciveLearningEnvironment,
        policyAndProcedure,
        practicalWorkPlacementArrangement,
        sufficientQualityAssuranceTeam,
        learnersSupportSystem,
        eqaFinalFeedbackOnCentre,
        eqaReportsUrl: eqaReportsUrl,
      };

      const response = await axios.put(`${apiUrl}/api/centreapplications/${id}`, formData);

      if (response) {
        console.log("Response: ", response.data.message);
        showNotification("success", "Centre Visit report submitted successfully!");

        // Redirect after a delay
        setTimeout(() => navigate("/"), 7000);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      showNotification("error", `${error.response.data.message}, Try again.`);
    }
  };

  // Options for autocomplete components
  const options3 = qualificationsData.map((qualification) => ({
    id: qualification.id,
    qualificationType: qualification.qualificationType,
  }));

  const options1 = Object.entries(STATES).map(([key, value]) => ({
    key,
    state: value,
  }));

  const options4 = Object.entries(ZONES).map(([key, value]) => ({
    key,
    zone: value,
  }));

  const options0 = Object.entries(CENTRETYPES).map(([key, value]) => ({
    key,
    centreType: value,
  }));

  // Ensure values are always valid objects or null
  const mapValuesToOptions = (values, options, key) => {
    if (!Array.isArray(values) || !Array.isArray(options)) return [];
    return values
      .map((value) => options.find((option) => option[key] === value))
      .filter((option) => option); // Ensure no undefined values
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <MDBox sx={{ pl: 5, pr: 5 }}>
            <MDTypography sx={{ color: "#295C21", fontSize: 17, mb: 3 }}>
              EQA FORM INSTRUCTIONS
            </MDTypography>

            <MDBox sx={{ maxWidth: "80%" }}>
              <MDTypography sx={{ color: "#0b1c08", fontSize: 16, wordWrap: "break-word", mb: 3 }}>
                Please use this form to conduct your quality assurance for
                <span style={{ color: "#49a139" }}> &quot;{centreName}&quot; </span>
                Your input begins from the <u>EQA FEEDBACK 1</u> section. Complete all sections in
                BLOCK LETTERS .
              </MDTypography>
              <MDTypography sx={{ color: "#49a139", fontSize: 16, wordWrap: "break-word" }}>
                Read the following conditions which apply to this quality assurance check, before
                you complete this form:
              </MDTypography>
              <List sx={{ fontSize: 14, wordWrap: "break-word" }}>
                <ListItem>
                  1. Download this&nbsp;
                  <a
                    href="/Centre Visit Status Report.pdf" // Adjusted path
                    style={{ color: "#49a139" }}
                    target="_blank"
                    download="Centre_Visit_Status_Report.pdf" // Added download attribute
                  >
                    &quot;
                    <u>Centre Visit Status Report</u>
                    &quot;
                  </a>
                </ListItem>
                <ListItem>
                  2. Complete the downloaded report and upload it in the&nbsp;
                  <u>EQA SUPPORTING DOCUMENTs</u>
                  &nbsp;section.
                </ListItem>
              </List>
            </MDBox>
          </MDBox>
        );
      /*
      case 1:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography sx={{ color: "#295C21", fontSize: 12, gridColumn: "span 6" }}>
              CENTRE DETAILS
            </MDTypography>
            <MDInput
              variant="standard"
              type="text"
              disabled
              label={
                <span>
                  Centre Name <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCentreNameChanged}
              value={centreName}
              name="centreName"
              sx={{ gridColumn: "span 2" }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                variant="standard"
                disabled
                label={
                  <span>
                    Year of Incorporation <span style={{ color: "red" }}> *</span>
                  </span>
                }
                openTo="year"
                onChange={(newValue) => setYearOfInc(newValue)}
                value={yearOfInc ? dayjs(yearOfInc) : dayjs("")}
                views={["year"]}
                maxDate={dayjs(maxYear)}
                minDate={dayjs("1960")}
                name="yearOfInc"
                sx={{ gridColumn: "span 2" }}
              />
            </LocalizationProvider>

            <MDInput
              variant="standard"
              type="text"
              disabled
              label={
                <span>
                  CAC Number <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCACChanged}
              value={cacNumber}
              name="cacNumber"
              sx={{ gridColumn: "span 2" }}
              InputProps={{
                startAdornment: <InputAdornment position="start">RC</InputAdornment>,
              }}
            />

            <MDInput
              variant="standard"
              multiline
              rows={3}
              type="text"
              disabled
              label={
                <span>
                  Permanent Centre Address <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onPermanentCentreAddressChanged}
              value={permanentCentreAddress}
              name="permanentCentreAddress"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              multiline
              rows={3}
              type="text"
              disabled
              label="Training Centre Address"
              onChange={onTrainingCentreAddressChanged}
              value={trainingCentreAddress}
              name="trainingCentreAddress"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label={
                <span>
                  Phone Number <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onPhoneChanged}
              value={phone}
              name="phone"
              sx={{ gridColumn: "span 2" }}
              InputProps={{
                startAdornment: <InputAdornment position="start">+234</InputAdornment>,
              }}
            />

            <MDInput
              variant="standard"
              type="email"
              disabled
              label={
                <span>
                  Email <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onEmailChanged}
              value={email}
              name="email"
              sx={{ gridColumn: "span 2" }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label="Website"
              onChange={onWebsiteChanged}
              value={website}
              name="website"
              sx={{ gridColumn: "span 2" }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label={
                <span>
                  Centre Head Fullname <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCentreHeadChanged}
              value={centreHead}
              name="centreHead"
              sx={{ gridColumn: "span 2" }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label={
                <span>
                  Centre Head Phone Number <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCentreHeadPhoneChanged}
              value={centreHeadPhone}
              name="centreHeadPhone"
              sx={{ gridColumn: "span 2" }}
              InputProps={{
                startAdornment: <InputAdornment position="start">+234</InputAdornment>,
              }}
            />

            <MDInput
              variant="standard"
              type="email"
              disabled
              label="Centre Head Email"
              onChange={onCentreHeadEmailChanged}
              value={centreHeadEmail}
              name="centreHeadEmail"
              sx={{ gridColumn: "span 2" }}
            />

            <MDAutocomplete
              id="zone-selector"
              disabled
              sx={{ gridColumn: "span 3" }}
              options={options4}
              value={options4.find((option) => option.zone === zone) || null} // Set a single value or null if no match
              onChange={(event, value) => setZone(value?.zone || "")} // Set only one zone value
              getOptionLabel={(option) => option.zone} // Access the zone property for display
              isOptionEqualToValue={(option, value) => option.zone === value?.zone}
              renderInput={(params) => (
                <MDInput {...params} label="Select Zone" variant="standard" />
              )}
            />

            <MDAutocomplete
              id="state-selector"
              disabled
              sx={{ gridColumn: "span 3" }}
              options={options1}
              value={options1.find((option) => option.state === stateOfRez) || null} // Set null if no selection
              onChange={(event, value) => setStateOfRez(value?.state || "")} // Update the stateOfRez based on selection
              getOptionLabel={(option) => option.state} // Display the state property for options
              isOptionEqualToValue={(option, value) => option.state === value?.state} // Match state
              renderInput={(params) => (
                <MDInput {...params} label="Select State" variant="standard" />
              )}
            />

            <MDAutocomplete
              id="qualification-selector"
              disabled
              sx={{ gridColumn: "span 6", mb: "30px" }}
              options={options3}
              multiple
              value={mapValuesToOptions(qualifications, options3, "qualificationType")}
              onChange={(event, value) =>
                setQualifications(value.map((item) => item.qualificationType))
              }
              getOptionLabel={(option) => option.qualificationType}
              isOptionEqualToValue={(option, value) =>
                option.qualificationType === value?.qualificationType
              }
              renderInput={(params) => (
                <MDInput {...params} label="Select Qualifications" variant="standard" />
              )}
            />
          </MDBox>
        );

      case 2:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography sx={{ color: "#295C21", fontSize: 12, gridColumn: "span 6" }}>
              YOUR ORGANISATION
            </MDTypography>
            <MDAutocomplete
              id="centretype-selector"
              disabled
              sx={{ gridColumn: "span 6" }}
              options={options0}
              value={options0.find((option) => option.centreType === centreType) || null} // Set a single value or null if no match
              onChange={(event, value) => setCentreType(value?.centreType || "")} // Set only one centreType value
              getOptionLabel={(option) => option.centreType} // Access the centreType property for display
              isOptionEqualToValue={(option, value) => option.centreType === value?.centreType}
              renderInput={(params) => (
                <MDInput {...params} label="Select Centre Type" variant="standard" />
              )}
            />

            <MDTypography sx={{ gridColumn: "span 6", fontSize: "15px", mb: "-10px" }}>
              - Satellite Centres where Training is provided other than your Main Campus/Centre
            </MDTypography>
            <MDInput
              variant="standard"
              type="text"
              disabled
              label="Satellite Centre Name 1"
              onChange={onSatelliteCentreName1Changed}
              value={satelliteCentreName1}
              name="satelliteCentreName1"
              sx={{ gridColumn: "span 2" }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label="Satellite Centre Address 1"
              onChange={onSatelliteCentreAddress1Changed}
              value={satelliteCentreAddress1}
              name="satelliteCentreAddress1"
              sx={{ gridColumn: "span 4" }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label="Satellite Centre Name 2"
              onChange={onSatelliteCentreName2Changed}
              value={satelliteCentreName2}
              name="satelliteCentreName2"
              sx={{ gridColumn: "span 2" }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label="Satellite Centre Address 2"
              onChange={onSatelliteCentreAddress2Changed}
              value={satelliteCentreAddress2}
              name="satelliteCentreAddress2"
              sx={{ gridColumn: "span 4", mb: "30px" }}
            />

            <FormControl fullWidth sx={{ gridColumn: "span 6" }}>
              <FormLabel component="legend" sx={{ color: "#49a139", fontSize: "15px" }}>
                Indicate if you are currently an approved Centre of another Awarding Body
              </FormLabel>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={otherApprovals?.itp}
                      onChange={onOtherApprovalsChange}
                      name="itp"
                      sx={{ color: "#49a139" }}
                    />
                  }
                  label="ITP"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={otherApprovals?.naddc}
                      onChange={onOtherApprovalsChange}
                      name="naddc"
                      sx={{ color: "#49a139" }}
                    />
                  }
                  label="NADDC"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={otherApprovals?.nabteb}
                      onChange={onOtherApprovalsChange}
                      name="nabteb"
                      sx={{ color: "#49a139" }}
                    />
                  }
                  label="NABTEB"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={otherApprovals?.cityGuild}
                      onChange={onOtherApprovalsChange}
                      name="cityGuild"
                      sx={{ color: "#49a139" }}
                    />
                  }
                  label="City & Guilds"
                />
              </FormGroup>
            </FormControl>

            <MDTypography sx={{ gridColumn: "span 6", fontSize: "15px" }}>
              If your Centre is currently approved as a Centre for NSQ by another Awarding Body,
              please give details below:
            </MDTypography>

            <MDInput
              variant="standard"
              type="text"
              disabled
              label="Awarding Body 1"
              onChange={onAwardingBody1Changed}
              value={awardingBody1}
              name="awardingBody1"
              sx={{ gridColumn: "span 1" }}
            />

            <MDAutocomplete
              id="awardingBody1-qualification-selector"
              disabled
              sx={{ gridColumn: "span 2" }}
              options={options3}
              value={
                options3.find(
                  (option) => option.qualificationType === awardingBody1Qualification
                ) || null
              } // Set null if no selection
              onChange={(event, value) =>
                setAwardingBody1Qualification(value?.qualificationType || "")
              } // Update the stateOfRez based on selection
              getOptionLabel={(option) => option.qualificationType} // Display the state property for options
              isOptionEqualToValue={(option, value) =>
                option.qualificationType === value?.qualificationType
              } // Match state
              renderInput={(params) => (
                <MDInput {...params} label="Qualification 1" variant="standard" />
              )}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Level"
              onChange={onAwardingBody1LevelChanged}
              value={awardingBody1Level}
              name="awardingBody1Level"
              sx={{ gridColumn: "span 1" }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                variant="standard"
                label={<span>Date Approved</span>}
                value={
                  awardingBody1DateApproved ? dayjs(awardingBody1DateApproved, "DD-MM-YYYY") : null
                }
                onChange={(newValue) =>
                  setAwardingBody1DateApproved(newValue ? newValue.format("DD-MM-YYYY") : "")
                }
                views={["year", "month", "day"]}
                maxDate={dayjs()} // Today's date
                minDate={dayjs("1960-01-01")} // Min date set to 1960
                name="awardingBody1DateApproved"
                sx={{ gridColumn: "span 1" }}
                renderInput={(params) => (
                  <MDInput {...params} value={awardingBody1DateApproved || ""} /> // Format displayed value
                )}
              />
            </LocalizationProvider>

            <MDAutocomplete
              id="awardingBody1ApprovalStatus-selector"
              sx={{ gridColumn: "span 1" }}
              options={[
                { label: "Done", value: "Done" },
                { label: "Pending", value: "Pending" },
              ]} // Array of options for the autocomplete
              getOptionLabel={(option) => option?.label || ""} // Display label of the option
              renderInput={(params) => (
                <MDInput
                  {...params}
                  fullWidth
                  label="Approval Status"
                  variant="standard"
                  aria-autocomplete="list"
                  spellCheck={false}
                  autoComplete="off"
                  autoCapitalize="none"
                />
              )}
              onChange={(event, value) => {
                setAwardingBody1ApprovalStatus(value?.value || ""); // Update state based on selection
              }}
              isOptionEqualToValue={
                (option, value) => option?.value === value?.value // Safely compare option and value
              }
            />

            <MDInput
              variant="standard"
              type="text"
              label="Awarding Body 2"
              onChange={onAwardingBody2Changed}
              value={awardingBody2}
              name="awardingBody2"
              sx={{ gridColumn: "span 1" }}
            />

            <MDAutocomplete
              id="awardingBody2-qualification-selector"
              sx={{ gridColumn: "span 2" }}
              options={options3}
              value={
                options3.find(
                  (option) => option.qualificationType === awardingBody2Qualification
                ) || null
              } // Set null if no selection
              onChange={(event, value) =>
                setAwardingBody2Qualification(value?.qualificationType || "")
              } // Update the stateOfRez based on selection
              getOptionLabel={(option) => option.qualificationType} // Display the state property for options
              isOptionEqualToValue={(option, value) =>
                option.qualificationType === value?.qualificationType
              } // Match state
              renderInput={(params) => (
                <MDInput {...params} label="Qualification 2" variant="standard" />
              )}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Level"
              onChange={onAwardingBody2LevelChanged}
              value={awardingBody2Level}
              name="awardingBody2Level"
              sx={{ gridColumn: "span 1" }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                variant="standard"
                label={<span>Date Approved</span>}
                value={
                  awardingBody2DateApproved ? dayjs(awardingBody2DateApproved, "DD-MM-YYYY") : null
                }
                onChange={(newValue) =>
                  setAwardingBody2DateApproved(newValue ? newValue.format("DD-MM-YYYY") : "")
                }
                views={["year", "month", "day"]}
                maxDate={dayjs()} // Today's date
                minDate={dayjs("1960-01-01")} // Min date set to 1960
                name="awardingBody2DateApproved"
                sx={{ gridColumn: "span 1" }}
                renderInput={(params) => (
                  <MDInput {...params} value={awardingBody2DateApproved || ""} /> // Format displayed value
                )}
              />
            </LocalizationProvider>

            <MDAutocomplete
              id="awardingBody2ApprovalStatus-selector"
              sx={{ gridColumn: "span 1", mb: "30px" }}
              options={[
                { label: "Done", value: "Done" },
                { label: "Pending", value: "Pending" },
              ]} // Array of options for the autocomplete
              getOptionLabel={(option) => option.label || ""} // Display label of the option
              renderInput={(params) => (
                <MDInput
                  {...params}
                  fullWidth
                  label="Approval Status"
                  variant="standard"
                  aria-autocomplete="list"
                  spellCheck={false}
                  autoComplete="off"
                  autoCapitalize="none"
                />
              )}
              onChange={(event, value) => {
                console.log("Value 2: ", value);
                setAwardingBody2ApprovalStatus(value?.value || "");
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />
          </MDBox>
        );

      case 3:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography sx={{ color: "#295C21", fontSize: 12, gridColumn: "span 6" }}>
              ORGANISATIONAL STAFFING
            </MDTypography>
            <MDInput
              variant="standard"
              type="text"
              disabled
              label={
                <span>
                  Centre Manager&apos;s FullName <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCentreManagerNameChanged}
              value={centreManagerName}
              name="centreManagerName"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label={
                <span>
                  Centre Manager&apos;s Qualification <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onCentreManagerQualificationChanged}
              value={centreManagerQualification}
              name="centreManagerQualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label={
                <span>
                  Assessor 1&apos;s FullName <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onAssessor1NameChanged}
              value={assessor1Name}
              name="assessor1Name"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label={
                <span>
                  Assessor 1&apos;s Qualification <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onAssessor1QualificationChanged}
              value={assessor1Qualification}
              name="assessor1Qualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Assessor 2&apos;s FullName</span>}
              onChange={onAssessor2NameChanged}
              value={assessor2Name}
              name="assessor2Name"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Assessor 2&apos;s Qualification</span>}
              onChange={onAssessor2QualificationChanged}
              value={assessor2Qualification}
              name="assessor2Qualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label={
                <span>
                  Internal Verifier&apos;s FullName <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onInternalVerifierNameChanged}
              value={internalVerifierName}
              name="internalVerifierName"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label={
                <span>
                  Internal Verifier&apos;s Qualification <span style={{ color: "red" }}> *</span>
                </span>
              }
              onChange={onInternalVerifierQualificationChanged}
              value={internalVerifierQualification}
              name="internalVerifierQualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Industry/Workplace Liaison Officer&apos;s FullName</span>}
              onChange={onLiaisonOfficerNameChanged}
              value={liaisonOfficerName}
              name="liaisonOfficerName"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Industry/Workplace Liaison Officer&apos;s Qualification</span>}
              onChange={onInternalVerifierQualificationChanged}
              value={liaisonOfficerQualification}
              name="liaisonOfficerQualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Resource/Data Officer&apos;s FullName</span>}
              onChange={onResourceOfficerNameChanged}
              value={resourceOfficerName}
              name="resourceOfficerName"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Resource/Data Officer&apos;s Qualification</span>}
              onChange={onResourceOfficerQualificationChanged}
              value={resourceOfficerQualification}
              name="resourceOfficerQualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Other Staff&apos;s FullName</span>}
              onChange={onOtherStaffNameChanged}
              value={otherStaffName}
              name="otherStaffName"
              sx={{ gridColumn: "span 2" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Other Staff&apos;s Qualification</span>}
              onChange={onOtherStaffQualificationChanged}
              value={otherStaffQualification}
              name="otherStaffQualification"
              helperText="e.g: BSc. Building, HND Marketing, etc."
              sx={{ gridColumn: "span 2" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label={<span>Other Staff&apos;s Designation</span>}
              onChange={onOtherStaffDesignationChanged}
              value={otherStaffDesignation}
              name="otherStaffDesignation"
              helperText="e.g: Program Officer, HOD Admin, etc."
              sx={{ gridColumn: "span 2" }}
            />
          </MDBox>
        );

      case 4:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography sx={{ color: "#295C21", fontSize: 12, gridColumn: "span 6" }}>
              PHYSICAL FACILITIES
            </MDTypography>
            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Classrooms/Training Rooms - Quantity"
              onChange={onTrainingRoomQuantityChanged}
              value={trainingRoomQuantity}
              name="trainingRoomQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Classrooms/Training Rooms - Total Floor Area"
              onChange={onTrainingRoomFloorAreaChanged}
              value={trainingRoomFloorArea}
              name="trainingRoomFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Workshops - Quantity"
              onChange={onWorkshopQuantityChanged}
              value={workshopQuantity}
              name="workshopQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Workshops - Total Floor Area"
              onChange={onWorkshopFloorAreaChanged}
              value={workshopFloorArea}
              name="workshopFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Practical Training Space for each Trade - Quantity"
              onChange={onPracticalTrainingSpaceQuantityChanged}
              value={practicalTrainingSpaceQuantity}
              name="practicalTrainingSpaceQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Practical Training Space for each Trade - Total Floor Area"
              onChange={onPracticalTrainingSpaceFloorAreaChanged}
              value={practicalTrainingSpaceFloorArea}
              name="practicalTrainingSpaceFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Technical Laboratories - Quantity"
              onChange={onTechnicalLabQuantityChanged}
              value={technicalLabQuantity}
              name="technicalLabQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Technical Laboratories - Total Floor Area"
              onChange={onTechnicalLabFloorAreaChanged}
              value={technicalLabFloorArea}
              name="technicalLabFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Data/Records Room - Quantity"
              onChange={onRecordsRoomQuantityChanged}
              value={recordsRoomQuantity}
              name="recordsRoomQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Data/Records Room - Total Floor Area"
              onChange={onRecordsRoomFloorAreaChanged}
              value={recordsRoomFloorArea}
              name="recordsRoomFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Auditorium - Quantity"
              onChange={onAuditoriumQuantityChanged}
              value={auditoriumQuantity}
              name="auditoriumQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Auditorium - Total Floor Area"
              onChange={onAuditoriumFloorAreaChanged}
              value={auditoriumFloorArea}
              name="auditoriumFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Library & Study Rooms - Quantity"
              onChange={onLibraryQuantityChanged}
              value={libraryQuantity}
              name="libraryQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Library & Study Rooms - Total Floor Area"
              onChange={onLibraryFloorAreaChanged}
              value={libraryFloorArea}
              name="libraryFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Water & Power Supply (Public/Private) - Quantity"
              onChange={onUtilityQuantityChanged}
              value={utilityQuantity}
              name="utilityQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Water & Power Supply (Public/Private) - Total Floor Area"
              onChange={onUtilityFloorAreaChanged}
              value={utilityFloorArea}
              name="utilityFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Management Offices - Quantity"
              onChange={onMgtOfficesQuantityChanged}
              value={mgtOfficesQuantity}
              name="mgtOfficesQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Management Offices - Total Floor Area"
              onChange={onMgtOfficesFloorAreaChanged}
              value={mgtOfficesFloorArea}
              name="mgtOfficesFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Staff Rooms - Quantity"
              onChange={onStaffRoomQuantityChanged}
              value={staffRoomQuantity}
              name="staffRoomQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Staff Rooms - Total Floor Area"
              onChange={onStaffRoomFloorAreaChanged}
              value={staffRoomFloorArea}
              name="staffRoomFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Toilets - Quantity"
              onChange={onToiletQuantityChanged}
              value={toiletQuantity}
              name="toiletQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Toilets - Total Floor Area"
              onChange={onToiletFloorAreaChanged}
              value={toiletFloorArea}
              name="toiletFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="First Aid/Firefighting Facility - Quantity"
              onChange={onFirstAidFireFacilityQuantityChanged}
              value={firstAidFireFacilityQuantity}
              name="firstAidFireFacilityQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="First Aid/Firefighting Facility - Total Floor Area"
              onChange={onFirstAidFireFacilityFloorAreaChanged}
              value={firstAidFireFacilityFloorArea}
              name="firstAidFireFacilityFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Hostel - Quantity"
              onChange={onHostelQuantityChanged}
              value={hostelQuantity}
              name="hostelQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Hostel - Total Floor Area"
              onChange={onHostelFloorAreaChanged}
              value={hostelFloorArea}
              name="hostelFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              label="Tools & Equipment for relevant Trades - Quantity"
              onChange={onTradeToolsQuantityChanged}
              value={tradeToolsQuantity}
              name="tradeToolsQuantity"
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Tools & Equipment for relevant Trades - Total Floor Area"
              onChange={onTradeToolsFloorAreaChanged}
              value={tradeToolsFloorArea}
              name="tradeToolsFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 3" }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label="Other Facility Name"
              onChange={onOtherFacilityNameChanged}
              value={otherFacilityName}
              name="otherFacilityName"
              sx={{ gridColumn: "span 2" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Others - Quantity"
              onChange={onOtherFacilityQuantityChanged}
              value={otherFacilityQuantity}
              name="otherFacilityQuantity"
              InputProps={{ inputProps: { min: 0 } }}
              sx={{ gridColumn: "span 2" }}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Others - Total Floor Area"
              onChange={onOtherFacilityFloorAreaChanged}
              value={otherFacilityFloorArea}
              name="otherFacilityFloorArea"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">Sqm.</InputAdornment>,
              }}
              sx={{ gridColumn: "span 2" }}
            />
          </MDBox>
        );

      case 5:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography sx={{ color: "#295C21", fontSize: 12, gridColumn: "span 6" }}>
              TRAINING STAFF
            </MDTypography>
            <MDInput
              variant="standard"
              type="text"
              disabled
              label="Full Name of Staff 1"
              onChange={onTrainingStaff1NameChanged}
              value={trainingStaff1Name}
              name="trainingStaff1Name"
              sx={{ gridColumn: "span 2" }}
            />

            <MDInput
              variant="standard"
              type="text"
              disabled
              label="Staff's Qualification"
              onChange={onTrainingStaff1QualificationChanged}
              value={trainingStaff1Qualification}
              name="trainingStaff1Qualification"
              helperText="e.g: BSc. Building, etc."
              sx={{ gridColumn: "span 1" }}
            />

            <MDAutocomplete
              id="trainingStaff1Gender-selector"
              disabled
              sx={{ gridColumn: "span 1" }}
              options={[
                { label: "Male", value: "Male" },
                { label: "Female", value: "Female" },
              ]} // Array of options for the autocomplete
              getOptionLabel={(option) => option.label || ""} // Display label of the option
              renderInput={(params) => (
                <MDInput
                  {...params}
                  fullWidth
                  label="Gender"
                  variant="standard"
                  aria-autocomplete="list"
                  spellCheck={false}
                  autoComplete="off"
                  autoCapitalize="none"
                />
              )}
              onChange={(event, value) => {
                setTrainingStaff1Gender(value?.value || ""); // Call setGender with the value (e.g., "male" or "female")
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />

            <MDInput
              variant="standard"
              type="number"
              disabled
              label="Practical Experience"
              onChange={onTrainingStaff1ExperienceChanged}
              value={trainingStaff1Experience}
              name="trainingStaff1Experience"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">yrs</InputAdornment>,
              }}
              sx={{ gridColumn: "span 1" }}
            />

            <MDAutocomplete
              id="trainingStaff1Specialization-selector"
              disabled
              options={options3}
              value={
                options3.find(
                  (option) => option.qualificationType === trainingStaff1Specialization
                ) || null
              }
              onChange={(event, value) =>
                setTrainingStaff1Specialization(value?.qualificationType || "")
              }
              getOptionLabel={(option) => option.qualificationType || ""}
              isOptionEqualToValue={(option, value) =>
                option.qualificationType === value?.qualificationType
              }
              renderInput={(params) => (
                <MDInput {...params} label="Trade Specialization" variant="standard" />
              )}
              sx={{ gridColumn: "span 1" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="Full Name of Staff 2"
              onChange={onTrainingStaff2NameChanged}
              value={trainingStaff2Name}
              name="trainingStaff2Name"
              sx={{ gridColumn: "span 2" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="Staff's Qualification"
              onChange={onTrainingStaff2QualificationChanged}
              value={trainingStaff2Qualification}
              name="trainingStaff2Qualification"
              helperText="e.g: BSc. Building, etc."
              sx={{ gridColumn: "span 1" }}
            />

            <MDAutocomplete
              id="trainingStaff2Gender-selector"
              sx={{ gridColumn: "span 1" }}
              options={[
                { label: "Male", value: "Male" },
                { label: "Female", value: "Female" },
              ]} // Array of options for the autocomplete
              getOptionLabel={(option) => option.label || ""} // Display label of the option
              renderInput={(params) => (
                <MDInput
                  {...params}
                  fullWidth
                  label="Gender"
                  variant="standard"
                  aria-autocomplete="list"
                  spellCheck={false}
                  autoComplete="off"
                  autoCapitalize="none"
                />
              )}
              onChange={(event, value) => {
                setTrainingStaff2Gender(value?.value || ""); // Call setGender with the value (e.g., "male" or "female")
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Practical Experience"
              onChange={onTrainingStaff2ExperienceChanged}
              value={trainingStaff2Experience}
              name="trainingStaff2Experience"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">yrs</InputAdornment>,
              }}
              sx={{ gridColumn: "span 1" }}
            />

            <MDAutocomplete
              id="trainingStaff2Specialization-selector"
              options={options3}
              value={
                options3.find(
                  (option) => option.qualificationType === trainingStaff2Specialization
                ) || null
              }
              onChange={(event, value) =>
                setTrainingStaff2Specialization(value?.qualificationType || "")
              }
              getOptionLabel={(option) => option.qualificationType || ""}
              isOptionEqualToValue={(option, value) =>
                option.qualificationType === value?.qualificationType
              }
              renderInput={(params) => (
                <MDInput {...params} label="Trade Specialization" variant="standard" />
              )}
              sx={{ gridColumn: "span 1" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="Full Name of Staff 3"
              onChange={onTrainingStaff3NameChanged}
              value={trainingStaff3Name}
              name="trainingStaff3Name"
              sx={{ gridColumn: "span 2" }}
            />

            <MDInput
              variant="standard"
              type="text"
              label="Staff's Qualification"
              onChange={onTrainingStaff3QualificationChanged}
              value={trainingStaff3Qualification}
              name="trainingStaff3Qualification"
              helperText="e.g: BSc. Building, etc."
              sx={{ gridColumn: "span 1" }}
            />

            <MDAutocomplete
              id="trainingStaff3Gender-selector"
              sx={{ gridColumn: "span 1" }}
              options={[
                { label: "Male", value: "Male" },
                { label: "Female", value: "Female" },
              ]} // Array of options for the autocomplete
              getOptionLabel={(option) => option.label || ""} // Display label of the option
              renderInput={(params) => (
                <MDInput
                  {...params}
                  fullWidth
                  label="Gender"
                  variant="standard"
                  aria-autocomplete="list"
                  spellCheck={false}
                  autoComplete="off"
                  autoCapitalize="none"
                />
              )}
              onChange={(event, value) => {
                setTrainingStaff3Gender(value?.value || ""); // Call setGender with the value (e.g., "male" or "female")
              }}
              isOptionEqualToValue={(option, value) => option.value === value.value}
            />

            <MDInput
              variant="standard"
              type="number"
              label="Practical Experience"
              onChange={onTrainingStaff3ExperienceChanged}
              value={trainingStaff3Experience}
              name="trainingStaff3Experience"
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: <InputAdornment position="end">yrs</InputAdornment>,
              }}
              sx={{ gridColumn: "span 1" }}
            />

            <MDAutocomplete
              id="trainingStaff3Specialization-selector"
              options={options3}
              value={
                options3.find(
                  (option) => option.qualificationType === trainingStaff3Specialization
                ) || null
              }
              onChange={(event, value) =>
                setTrainingStaff3Specialization(value?.qualificationType || "")
              }
              getOptionLabel={(option) => option.qualificationType || ""}
              isOptionEqualToValue={(option, value) =>
                option.qualificationType === value?.qualificationType
              }
              renderInput={(params) => (
                <MDInput {...params} label="Trade Specialization" variant="standard" />
              )}
              sx={{ gridColumn: "span 1" }}
            />
          </MDBox>
        );
      */
      case 1:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography sx={{ color: "#295C21", fontSize: 12, gridColumn: "span 6" }}>
              EQA FEEDBACK 1
            </MDTypography>
            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography
                sx={{ width: "60%", fontSize: "15px", gridColumn: { xs: "span 6", sm: "span 4" } }}
              >
                i.{"\u00A0"} Are there NSQ certified Assessors at your Centre?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="nsqCertifiedAssessor-selector"
                sx={{ width: "20%", gridColumn: { xs: "span 6", sm: "span 2" } }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  nsqCertifiedAssessors
                    ? { label: nsqCertifiedAssessors, value: nsqCertifiedAssessors }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setNsqCertifiedAssessors(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="nsqCertifiedAssessorsEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  nsqCertifiedAssessorsEQAView
                    ? { label: nsqCertifiedAssessorsEQAView, value: nsqCertifiedAssessorsEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setNsqCertifiedAssessorsEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                ii.{"\u00A0"} How many Assessors are available at Centre?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDInput
                variant="standard"
                type="number"
                disabled
                label="Centre's Response"
                onChange={onAssessorsCountChanged}
                value={assessorsCount}
                InputProps={{ inputProps: { min: 0 } }}
                name="assessorsCount"
                sx={{ width: "20%" }}
              />

              <MDAutocomplete
                id="assessorsCountEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  assessorsCountEQAView
                    ? { label: assessorsCountEQAView, value: assessorsCountEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setAssessorsCountEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                iii.{"\u00A0"} Are there NSQ certified Verifiers at your Centre?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="nsqCertifiedVerifier-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  nsqCertifiedVerifiers
                    ? { label: nsqCertifiedVerifiers, value: nsqCertifiedVerifiers }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setNsqCertifiedVerifiers(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="nsqCertifiedVerifiersEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  nsqCertifiedVerifiersEQAView
                    ? { label: nsqCertifiedVerifiersEQAView, value: nsqCertifiedVerifiersEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setNsqCertifiedVerifiersEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                iv.{"\u00A0"} How many Verifiers are available at Centre?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDInput
                variant="standard"
                type="number"
                disabled
                label="Centre's Response"
                onChange={onVerifiersCountChanged}
                value={verifiersCount}
                InputProps={{ inputProps: { min: 0 } }}
                name="verifiersCount"
                sx={{ width: "20%" }}
              />

              <MDAutocomplete
                id="verifiersCountEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  verifiersCountEQAView
                    ? { label: verifiersCountEQAView, value: verifiersCountEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setVerifiersCountEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                v.{"\u00A0"} Is the Centre Manager NSQ certified?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="nsqCertifiedCentreManager-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  nsqCertifiedCentreManager
                    ? { label: nsqCertifiedCentreManager, value: nsqCertifiedCentreManager }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setNsqCertifiedCentreManager(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="nsqCertifiedCentreManagerEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  nsqCertifiedCentreManagerEQAView
                    ? {
                        label: nsqCertifiedCentreManagerEQAView,
                        value: nsqCertifiedCentreManagerEQAView,
                      }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setNsqCertifiedCentreManagerEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                vi.{"\u00A0"} Is there a Liaison Officer with the Industry at your Centre?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="liaisonOfficerPresent-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  liaisonOfficerPresent
                    ? { label: liaisonOfficerPresent, value: liaisonOfficerPresent }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setLiaisonOfficerPresent(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="liaisonOfficerPresentEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  liaisonOfficerPresentEQAView
                    ? { label: liaisonOfficerPresentEQAView, value: liaisonOfficerPresentEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setLiaisonOfficerPresentEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                vii.{"\u00A0"} Is there a Resource/Data Officer at your Centre?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="resourceOfficerPresent-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  resourceOfficerPresent
                    ? { label: resourceOfficerPresent, value: resourceOfficerPresent }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setResourceOfficerPresent(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="resourceOfficerPresentEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  resourceOfficerPresentEQAView
                    ? { label: resourceOfficerPresentEQAView, value: resourceOfficerPresentEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setResourceOfficerPresentEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                viii.{"\u00A0"} Are there evidences/records of site-banks for apprenticeship
                training at your Centre?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="siteBankPresent-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={siteBankPresent ? { label: siteBankPresent, value: siteBankPresent } : null}
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setSiteBankPresent(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="siteBankPresentEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  siteBankPresentEQAView
                    ? { label: siteBankPresentEQAView, value: siteBankPresentEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setSiteBankPresentEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDInput
                variant="standard"
                type="text"
                multiline
                rows={3}
                label="EQA General Feedback on Centre Human Resource"
                onChange={onEqaFeedbackOnCentreHumanResourceChanged}
                value={eqaFeedbackOnCentreHumanResource}
                name="eqaFeedbackOnCentreHumanResource"
                sx={{ width: "100%", mb: 3 }}
              />
            </MDBox>
          </MDBox>
        );

      case 2:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography sx={{ color: "#295C21", fontSize: 12, gridColumn: "span 6" }}>
              EQA FEEDBACK 2
            </MDTypography>
            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                ix.{"\u00A0"} Are the Classrooms/Training Rooms adequate in terms of space,
                lighting, ventilation & traffic?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="trainingRoomAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  trainingRoomAdequate
                    ? { label: trainingRoomAdequate, value: trainingRoomAdequate }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setTrainingRoomAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="trainingRoomAdequateEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  trainingRoomAdequateEQAView
                    ? { label: trainingRoomAdequateEQAView, value: trainingRoomAdequateEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setTrainingRoomAdequateEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                x.{"\u00A0"} Is there a Data/Records room available and adequate?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="dataRoomAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  dataRoomAdequate ? { label: dataRoomAdequate, value: dataRoomAdequate } : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setDataRoomAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="dataRoomAdequateEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  dataRoomAdequateEQAView
                    ? { label: dataRoomAdequateEQAView, value: dataRoomAdequateEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setDataRoomAdequateEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xi.{"\u00A0"} Are Workshop spaces adequate and fit for purpose?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="workshopAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  workshopAdequate ? { label: workshopAdequate, value: workshopAdequate } : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setWorkshopAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="workshopAdequateEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  workshopAdequateEQAView
                    ? { label: workshopAdequateEQAView, value: workshopAdequateEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setWorkshopAdequateEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xii.{"\u00A0"} Are Practical training spaces available and suitable for each trade?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="practicalSpaceAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  practicalSpaceAdequate
                    ? { label: practicalSpaceAdequate, value: practicalSpaceAdequate }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setPracticalSpaceAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="practicalSpaceAdequateEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  practicalSpaceAdequateEQAView
                    ? { label: practicalSpaceAdequateEQAView, value: practicalSpaceAdequateEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setPracticalSpaceAdequateEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xiii.{"\u00A0"} Are Technical laboratories AVAILABLE or HIRED?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="techLabAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "AVAILABLE", value: "AVAILABLE" },
                  { label: "HIRED", value: "HIRED" },
                ]} // Array of options for the autocomplete
                value={techLabAdequate ? { label: techLabAdequate, value: techLabAdequate } : null}
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setTechLabAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="techLabAdequateEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "AVAILABLE", value: "AVAILABLE" },
                  { label: "HIRED", value: "HIRED" },
                ]} // Array of options for the autocomplete
                value={
                  techLabAdequateEQAView
                    ? { label: techLabAdequateEQAView, value: techLabAdequateEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setTechLabAdequateEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xiv.{"\u00A0"} Are Staff rooms adequate and fit for purpose?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="staffRoomAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  staffRoomAdequate ? { label: staffRoomAdequate, value: staffRoomAdequate } : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setStaffRoomAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="staffRoomAdequateEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  staffRoomAdequateEQAView
                    ? { label: staffRoomAdequateEQAView, value: staffRoomAdequateEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setStaffRoomAdequateEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xv.{"\u00A0"} Are Toilets available and functional?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="toiletAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={toiletAdequate ? { label: toiletAdequate, value: toiletAdequate } : null}
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setToiletAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="toiletAdequateEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  toiletAdequateEQAView
                    ? { label: toiletAdequateEQAView, value: toiletAdequateEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setToiletAdequateEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xvi.{"\u00A0"} Are First Aid/Firefighting facilities available and functional?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="firstAidFireAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  firstAidFireAdequate
                    ? { label: firstAidFireAdequate, value: firstAidFireAdequate }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setFirstAidFireAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="firstAidFireAdequateEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  firstAidFireAdequateEQAView
                    ? { label: firstAidFireAdequateEQAView, value: firstAidFireAdequateEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setFirstAidFireAdequateEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xvii.{"\u00A0"} Are Hostels available and fit for purpose?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="hostelsAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={hostelsAdequate ? { label: hostelsAdequate, value: hostelsAdequate } : null}
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setHostelsAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="hostelsAdequateEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  hostelsAdequateEQAView
                    ? { label: hostelsAdequateEQAView, value: hostelsAdequateEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setHostelsAdequateEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xviii.{"\u00A0"} Are there Tools and Equipment for relevant trades at your Centre?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="tradeToolsAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  tradeToolsAdequate
                    ? { label: tradeToolsAdequate, value: tradeToolsAdequate }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setTradeToolsAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="tradeToolsAdequateEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  tradeToolsAdequateEQAView
                    ? { label: tradeToolsAdequateEQAView, value: tradeToolsAdequateEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setTradeToolsAdequateEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xix.{"\u00A0"} Is Auditorium AVAILABLE or HIRED?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="auditoriumAvailable-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "AVAILABLE", value: "AVAILABLE" },
                  { label: "HIRED", value: "HIRED" },
                ]} // Array of options for the autocomplete
                value={
                  auditoriumAvailable
                    ? { label: auditoriumAvailable, value: auditoriumAvailable }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setAuditoriumAvailable(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="auditoriumAvailableEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "AVAILABLE", value: "AVAILABLE" },
                  { label: "HIRED", value: "HIRED" },
                ]} // Array of options for the autocomplete
                value={
                  auditoriumAvailableEQAView
                    ? { label: auditoriumAvailableEQAView, value: auditoriumAvailableEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setAuditoriumAvailableEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xx.{"\u00A0"} Are Library and Study rooms adequate?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="libraryAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={libraryAdequate ? { label: libraryAdequate, value: libraryAdequate } : null}
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setLibraryAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="libraryAdequateEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  libraryAdequateEQAView
                    ? { label: libraryAdequateEQAView, value: libraryAdequateEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setLibraryAdequateEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xxi.{"\u00A0"} Is Water and Power supply adequate?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="waterAndPowerAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  waterAndPowerAdequate
                    ? { label: waterAndPowerAdequate, value: waterAndPowerAdequate }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setWaterAndPowerAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="waterAndPowerAdequateEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  waterAndPowerAdequateEQAView
                    ? { label: waterAndPowerAdequateEQAView, value: waterAndPowerAdequateEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setWaterAndPowerAdequateEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xxii.{"\u00A0"} Are Management Offices adequate and fit for purpose?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="mgtOfficesAdequate-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  mgtOfficesAdequate
                    ? { label: mgtOfficesAdequate, value: mgtOfficesAdequate }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setMgtOfficesAdequate(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="mgtOfficesAdequateEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  mgtOfficesAdequateEQAView
                    ? { label: mgtOfficesAdequateEQAView, value: mgtOfficesAdequateEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setMgtOfficesAdequateEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDInput
                variant="standard"
                type="text"
                multiline
                rows={3}
                label="EQA General Feedback on Centre Facilities"
                onChange={onEqaFeedbackOnCentreFacilitiesChanged}
                value={eqaFeedbackOnCentreFacilities}
                name="eqaFeedbackOnCentreFacilities"
                sx={{ width: "100%", mb: 3 }}
              />
            </MDBox>
          </MDBox>
        );

      case 3:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography sx={{ color: "#295C21", fontSize: 12, gridColumn: "span 6" }}>
              EQA FEEDBACK 3
            </MDTypography>
            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xxiii.{"\u00A0"} Is NOS available at your Centre?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="nosAvailable-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={nosAvailable ? { label: nosAvailable, value: nosAvailable } : null}
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setNosAvailable(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="nosAvailableEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  nosAvailableEQAView
                    ? { label: nosAvailableEQAView, value: nosAvailableEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setNosAvailableEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xxiv.{"\u00A0"} Are there Training Manuals at your Centre?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="trainingManualAvailable-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  trainingManualAvailable
                    ? { label: trainingManualAvailable, value: trainingManualAvailable }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setTrainingManualAvailable(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="trainingManualAvailableEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  trainingManualAvailableEQAView
                    ? {
                        label: trainingManualAvailableEQAView,
                        value: trainingManualAvailableEQAView,
                      }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setTrainingManualAvailableEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xxv.{"\u00A0"} Are the Manuals aligned to the NOS?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="nosAndManualAlignment-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  nosAndManualAlignment
                    ? { label: nosAndManualAlignment, value: nosAndManualAlignment }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setNosAndManualAlignment(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="nosAndManualAlignedEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  nosAndManualAlignedEQAView
                    ? { label: nosAndManualAlignedEQAView, value: nosAndManualAlignedEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setNosAndManualAlignedEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xxvi.{"\u00A0"} Are there other Training materials that are relevant to the training
                needs?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="otherTrainingMaterialsAvailable-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  otherTrainingMaterialsAvailable
                    ? {
                        label: otherTrainingMaterialsAvailable,
                        value: otherTrainingMaterialsAvailable,
                      }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setOtherTrainingMaterialsAvailable(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="otherTrainingMaterialsAvailableEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  otherTrainingMaterialsAvailableEQAView
                    ? {
                        label: otherTrainingMaterialsAvailableEQAView,
                        value: otherTrainingMaterialsAvailableEQAView,
                      }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setOtherTrainingMaterialsAvailableEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xxvii.{"\u00A0"} Is there an NSQ Code of Practice and Implementation Guideline at
                your Centre?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="nsqGuidelineAvailable-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  nsqGuidelineAvailable
                    ? { label: nsqGuidelineAvailable, value: nsqGuidelineAvailable }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setNsqGuidelineAvailable(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="nsqGuidelineAvailableEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  nsqGuidelineAvailableEQAView
                    ? { label: nsqGuidelineAvailableEQAView, value: nsqGuidelineAvailableEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setNsqGuidelineAvailableEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xxviii.{"\u00A0"} Are the Trainers and Staff conversant/comply with these documents?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="staffCompliantWithStandards-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  staffCompliantWithStandards
                    ? { label: staffCompliantWithStandards, value: staffCompliantWithStandards }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setStaffCompliantWithStandards(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="staffCompliantWithStandardsEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  staffCompliantWithStandardsEQAView
                    ? {
                        label: staffCompliantWithStandardsEQAView,
                        value: staffCompliantWithStandardsEQAView,
                      }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setStaffCompliantWithStandardsEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xxix.{"\u00A0"} Are the Objectives of the training clearly defined?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="objectivesClear-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={objectivesClear ? { label: objectivesClear, value: objectivesClear } : null}
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setObjectivesClear(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="objectivesClearEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  objectivesClearEQAView
                    ? { label: objectivesClearEQAView, value: objectivesClearEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setObjectivesClearEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                xxx.{"\u00A0"} Is Class participation through questioning encouraged?
                <span style={{ color: "red" }}> *</span>
              </MDTypography>

              <MDAutocomplete
                id="classParticipation-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  classParticipation
                    ? { label: classParticipation, value: classParticipation }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    disabled
                    fullWidth
                    label="Centre's Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setClassParticipation(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />

              <MDAutocomplete
                id="classParticipationEQAView-selector"
                sx={{ width: "20%" }}
                options={[
                  { label: "Yes", value: "Yes" },
                  { label: "No", value: "No" },
                ]} // Array of options for the autocomplete
                value={
                  classParticipationEQAView
                    ? { label: classParticipationEQAView, value: classParticipationEQAView }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="EQA Response"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setClassParticipationEQAView(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDInput
                variant="standard"
                type="text"
                multiline
                rows={3}
                label="EQA General Feedback on Centre NOS & NSQ"
                onChange={onEqaFeedbackOnCentreNOSNSQChanged}
                value={eqaFeedbackOnCentreNOSNSQ}
                name="eqaFeedbackOnCentreNOSNSQ"
                sx={{ width: "100%", mb: 3 }}
              />
            </MDBox>
          </MDBox>
        );

      case 4:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography sx={{ color: "#295C21", fontSize: 12, gridColumn: "span 6" }}>
              EQA FINAL REPORT
            </MDTypography>
            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDInput
                variant="standard"
                type="text"
                multiline
                rows={6}
                label="EQA End of Visit Report"
                onChange={onEqaFinalFeedbackOnCentreChanged}
                value={eqaFinalFeedbackOnCentre}
                name="eqaFinalFeedbackOnCentre"
                sx={{ width: "100%", mb: 3 }}
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                I.{"\u00A0"} Conducive Learning Environment:
              </MDTypography>

              <MDAutocomplete
                id="conduciveLearningEnvironment-selector"
                sx={{ width: "40%" }}
                options={[
                  { label: "APPROVED", value: "APPROVED" },
                  { label: "TEMPORAL APPROVAL", value: "TEMPORAL APPROVAL" },
                  { label: "NEED MORE INFORMATION", value: "NEED MORE INFORMATION" },
                  { label: "NOT APPROVED", value: "NOT APPROVED" },
                ]} // Array of options for the autocomplete
                value={
                  conduciveLearningEnvironment
                    ? { label: conduciveLearningEnvironment, value: conduciveLearningEnvironment }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select as appropriate"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setConduciveLearningEnvironment(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                II.{"\u00A0"} Policy and Procedure:
              </MDTypography>

              <MDAutocomplete
                id="policyAndProcedure-selector"
                sx={{ width: "40%" }}
                options={[
                  { label: "APPROVED", value: "APPROVED" },
                  { label: "TEMPORAL APPROVAL", value: "TEMPORAL APPROVAL" },
                  { label: "NEED MORE INFORMATION", value: "NEED MORE INFORMATION" },
                  { label: "NOT APPROVED", value: "NOT APPROVED" },
                ]} // Array of options for the autocomplete
                value={
                  policyAndProcedure
                    ? { label: policyAndProcedure, value: policyAndProcedure }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select as appropriate"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setPolicyAndProcedure(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                III.{"\u00A0"} Practical Work Placement Arrangement:
              </MDTypography>

              <MDAutocomplete
                id="practicalWorkPlacementArrangement-selector"
                sx={{ width: "40%" }}
                options={[
                  { label: "APPROVED", value: "APPROVED" },
                  { label: "TEMPORAL APPROVAL", value: "TEMPORAL APPROVAL" },
                  { label: "NEED MORE INFORMATION", value: "NEED MORE INFORMATION" },
                  { label: "NOT APPROVED", value: "NOT APPROVED" },
                ]} // Array of options for the autocomplete
                value={
                  practicalWorkPlacementArrangement
                    ? {
                        label: practicalWorkPlacementArrangement,
                        value: practicalWorkPlacementArrangement,
                      }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select as appropriate"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setPracticalWorkPlacementArrangement(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6" }}>
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                IV.{"\u00A0"} Sufficient Quality Assurance Team:
              </MDTypography>

              <MDAutocomplete
                id="sufficientQualityAssuranceTeam-selector"
                sx={{ width: "40%" }}
                options={[
                  { label: "APPROVED", value: "APPROVED" },
                  { label: "TEMPORAL APPROVAL", value: "TEMPORAL APPROVAL" },
                  { label: "NEED MORE INFORMATION", value: "NEED MORE INFORMATION" },
                  { label: "NOT APPROVED", value: "NOT APPROVED" },
                ]} // Array of options for the autocomplete
                value={
                  sufficientQualityAssuranceTeam
                    ? {
                        label: sufficientQualityAssuranceTeam,
                        value: sufficientQualityAssuranceTeam,
                      }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select as appropriate"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setSufficientQualityAssuranceTeam(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>

            <MDBox
              sx={{ display: "flex", alignItems: "center", gap: 4, gridColumn: "span 6", mb: 3 }}
            >
              <MDTypography sx={{ width: "60%", fontSize: "15px" }}>
                V.{"\u00A0"} Course Structure / Learners&apos; Support System:
              </MDTypography>

              <MDAutocomplete
                id="learnersSupportSystem-selector"
                sx={{ width: "40%" }}
                options={[
                  { label: "APPROVED", value: "APPROVED" },
                  { label: "TEMPORAL APPROVAL", value: "TEMPORAL APPROVAL" },
                  { label: "NEED MORE INFORMATION", value: "NEED MORE INFORMATION" },
                  { label: "NOT APPROVED", value: "NOT APPROVED" },
                ]} // Array of options for the autocomplete
                value={
                  learnersSupportSystem
                    ? { label: learnersSupportSystem, value: learnersSupportSystem }
                    : null
                }
                getOptionLabel={(option) => option?.label || ""} // Display label of the option
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    fullWidth
                    label="Select as appropriate"
                    variant="standard"
                    aria-autocomplete="list"
                    spellCheck={false}
                    autoComplete="off"
                    autoCapitalize="none"
                  />
                )}
                onChange={(event, value) => {
                  setLearnersSupportSystem(value?.value || ""); // Update state based on selection
                }}
                isOptionEqualToValue={
                  (option, value) => option?.value === value?.value // Safely compare option and value
                }
              />
            </MDBox>
          </MDBox>
        );

      case 5:
        return (
          <MDBox
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: undefined },
              pl: 5,
              pr: 5,
            }}
          >
            <MDTypography sx={{ color: "#295C21", fontSize: 12, gridColumn: "span 6" }}>
              EQA SUPPORTING DOCUMENT(s)
            </MDTypography>
            <MDButton
              component="label"
              role={undefined}
              variant="gradient"
              color="dark"
              tabIndex={-1}
              sx={{ gridColumn: "span 3", mb: 3 }}
              startIcon={<CloudUploadIcon />}
            >
              Click to Upload EQA Report(s)
              <VisuallyHiddenInput type="file" onChange={onSelectEqaReports} />
            </MDButton>
          </MDBox>
        );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDSnackbar
        color={notification.type}
        icon={notification.type === "success" ? "check" : "warning"}
        title="NIOB Skill CaMP"
        content={notification.message}
        open={notification.open}
        onClose={closeNotification}
        close={closeNotification}
        bgWhite
      />
      <MDBox sx={{ mb: 5, mt: 5 }}>
        <Card>
          <form>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              sx={{
                p: 3,
                ml: 5,
                mr: 5,
                mt: -3,
                background: "#4CAF50 !important",
                boxShadow: "#4CAF50 !important",
                "& .MuiStepLabel-label": {
                  color: "#ffffff !important", // Ensures text remains visible
                },
                "& .MuiStepIcon-root": {
                  color: "#ffffff !important", // Changes icon color
                  stroke: "#ffffff !important",
                  fill: "#ffffff !important",
                },
                "& .MuiStepConnector-root": {
                  color: "#ffffff !important", // Changes connector color
                  stroke: "#ffffff !important",
                  fill: "#ffffff !important",
                },
              }}
            >
              {eqasteps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <MDBox sx={{ mt: 3 }}>{renderStepContent(activeStep)}</MDBox>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 1,
                mb: 2,
                mr: 3,
                ml: 3,
              }}
            >
              <MDButton disabled={activeStep === 0} onClick={handleBack}>
                Back
              </MDButton>
              {/* Next/Finish Button */}
              {activeStep === eqasteps.length - 1 ? (
                <MDButton onClick={onSaveEqaReportClicked}>Send</MDButton>
              ) : (
                <MDButton onClick={handleNext}>Next</MDButton>
              )}
            </MDBox>
          </form>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
