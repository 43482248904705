import React, { useState, useEffect } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import axios from "axios";
import { jwtDecode } from "jwt-decode";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import { useAuth } from "../../../AuthProvider"; // Corrected Import

export default function data() {
  const [centreapplications, setCentreApplications] = useState([]);
  const apiUrl = "https://api.skillcamp.niobnat.org";

  const { auth } = useAuth();
  const userId = jwtDecode(auth.token).id;

  useEffect(() => {
    const getCentreApplicationsData = async () => {
      try {
        const { data } = await axios.get(`${apiUrl}/api/centreapplications/getCentreApplications`);

        // Filter applications assigned to the logged-in user
        const filteredApplications = data.filter((app) => app.eqaAssignedTo === userId);

        setCentreApplications(filteredApplications);
      } catch (error) {
        console.error("Error fetching centre applications:", error);
      }
    };

    getCentreApplicationsData();
  }, [auth]);

  const CentreApplication = ({ cacNumber, centreName }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {centreName}
        </MDTypography>
        <MDTypography variant="caption">{cacNumber}</MDTypography>
      </MDBox>
    </MDBox>
  );

  CentreApplication.propTypes = {
    cacNumber: PropTypes.string.isRequired,
    centreName: PropTypes.string.isRequired,
  };

  const Assignment = ({ isAssigned, eqaAssigned }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDBox ml={-1}>
        <MDBadge
          badgeContent={isAssigned ? "assigned" : "unassigned"}
          color={isAssigned ? "success" : "dark"}
          variant="gradient"
          size="sm"
        />
      </MDBox>
      <MDTypography variant="caption">{eqaAssigned}</MDTypography>
    </MDBox>
  );

  Assignment.propTypes = {
    isAssigned: PropTypes.bool.isRequired,
    eqaAssigned: PropTypes.string.isRequired,
  };

  const Status = ({ isApproved }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDBox ml={-1}>
        <MDBadge
          badgeContent={isApproved ? "approved" : "pending"}
          color={isApproved ? "success" : "dark"}
          variant="gradient"
          size="sm"
        />
      </MDBox>
    </MDBox>
  );

  Status.propTypes = {
    isApproved: PropTypes.bool.isRequired,
  };

  const Report = ({ eqaReportCompleted }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDBox ml={-1}>
        <MDBadge
          badgeContent={eqaReportCompleted ? "completed" : "pending"}
          color={eqaReportCompleted ? "success" : "dark"}
          variant="gradient"
          size="sm"
        />
      </MDBox>
    </MDBox>
  );

  Report.propTypes = {
    eqaReportCompleted: PropTypes.bool.isRequired,
  };

  return {
    columns: [
      { Header: "sno.", accessor: "number", width: "10%", align: "center" },
      { Header: "centre", accessor: "centre", width: "20%", align: "left" },
      { Header: "is-assigned", accessor: "assignment", width: "20%", align: "left" },
      { Header: "application status", accessor: "status", width: "10%", align: "center" },
      { Header: "is-report-completed", accessor: "report", width: "150px", align: "left" },
      { Header: "state", accessor: "state", width: "20%", align: "left" },
      { Header: "action", accessor: "action", width: "20%", align: "center" },
    ],

    rows: centreapplications.map((centreapplication, index) => ({
      number: <MDTypography>{index + 1 + "."}</MDTypography>,
      centre: (
        <CentreApplication
          cacNumber={`RC ${centreapplication.cacNumber}`}
          centreName={centreapplication.centreName}
        />
      ),
      assignment: (
        <Assignment
          isAssigned={centreapplication.eqaAssigned}
          eqaAssigned={`${centreapplication?.eqaInCharge?.firstName || ""} ${
            centreapplication?.eqaInCharge?.lastName || ""
          }`}
        />
      ),
      status: <Status isApproved={centreapplication.isApproved} />,
      report: <Report eqaReportCompleted={centreapplication.eqaCentreReportComplete} />,
      state: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {centreapplication.stateOfRez}
        </MDTypography>
      ),
      action: !centreapplication.eqaCentreReportComplete && (
        <MDTypography
          component="a"
          href={`centreapplications/${centreapplication.id}`}
          variant="caption"
          color="success"
          fontWeight="medium"
        >
          Complete Centre Report
        </MDTypography>
      ),
    })),
  };
}
