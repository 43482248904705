import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
// MUI Components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 Components
import MDBox from "components/MDBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Themes
import theme from "assets/theme";
import themeDark from "assets/theme-dark";

// Components & Context
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import { useAuth } from "./AuthProvider"; // Corrected Import

// Layouts & Authentication
import SignIn from "layouts/authentication/sign-in";
import Error404 from "layouts/error-404";
import Profile from "layouts/profile";
import Settings from "layouts/settings";
import CentreApplications, {
  NewCentreApplicationForm,
  ViewCentreApplication,
  CentreQualityCheck,
} from "layouts/centreapplications";
import Users, { NewUserForm, EditUserForm, ViewUser } from "layouts/users";
import Roles, { NewRoleForm, EditRoleForm } from "layouts/roles";
import Qualifications, {
  NewQualificationForm,
  EditQualificationForm,
} from "layouts/qualifications";
import routes from "routes";

// Images
import brandWhite from "assets/images/logo_white.png";
import brandDark from "assets/images/logo_dark.png";

// Component for Admin Dashboard
import Dashboard, { EQAMDashboard } from "layouts/dashboard";

// Ensure AuthProvider is wrapping App.js
export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const { auth } = useAuth();
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const authData = localStorage.getItem("auth");

    if (!authData) {
      console.error("No auth data found in localStorage.");
      return;
    }

    try {
      const parsedAuth = JSON.parse(authData);
      const token = parsedAuth?.token;

      if (!token) {
        console.error("Token not found in auth object.");
        return;
      }

      const user = jwtDecode(token);
      console.log("Decoded User:", user);

      if (user.roles) {
        setUserRole(user.roles);
      } else {
        console.error("Roles not found in token payload.");
      }
    } catch (error) {
      console.error("Error parsing auth data from localStorage:", error);
    }
  }, [auth]); // Depend on `auth` to ensure it updates properly

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Filter routes based on user role
  const filteredRoutes = routes.filter((route) =>
    route.allowedRoles.includes(userRole.toLowerCase())
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && auth && (
        <Sidenav
          color={sidenavColor}
          brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
          brandName="NIOB Skill CaMP"
          routes={filteredRoutes}
        />
      )}

      <Routes>
        {/* Public Routes */}
        <Route path="/auth/login" element={<SignIn />} />
        <Route path="/error404" element={<Error404 />} />
        <Route path="/centre-application" element={<NewCentreApplicationForm />} />

        {/* Protected Routes */}
        {auth ? (
          <>
            {/* Role-based Redirecting */}
            <Route
              path="/"
              element={
                userRole ? (
                  userRole.toLowerCase() === "admin" ? (
                    <Navigate to="/dashboard" />
                  ) : userRole.toLowerCase() === "eqam" ? (
                    <Navigate to="/eqam-dashboard" />
                  ) : (
                    <Navigate to="/error404" />
                  )
                ) : (
                  <p>Loading...</p>
                ) // Prevents redirecting before role is set
              }
            />
            {/* Render only allowed routes */}
            {filteredRoutes.map(({ route, component }, index) =>
              route ? <Route key={index} path={route} element={component} /> : null
            )}
          </>
        ) : (
          <Route path="*" element={<Navigate to="/auth/login" replace />} />
        )}

        {/* Default Redirects */}
        <Route path="*" element={<Navigate to={auth ? "/" : "/auth/login"} />} />
        <Route path="/users/new" element={<NewUserForm />} />
        <Route path="/users/:id/edit" element={<EditUserForm />} />
        <Route path="/users/:id/view" element={<ViewUser />} />
        <Route path="/users" element={<Users />} />
        <Route path="/roles/new" element={<NewRoleForm />} />
        <Route path="/roles/:id/edit" element={<EditRoleForm />} />
        <Route path="/roles" element={<Roles />} />
        <Route path="/qualifications/new" element={<NewQualificationForm />} />
        <Route path="/qualifications/:id/edit" element={<EditQualificationForm />} />
        <Route path="/qualifications" element={<Qualifications />} />
        <Route path="/centreapplications/:id/view" element={<ViewCentreApplication />} />
        <Route path="/centreapplications/:id" element={<CentreQualityCheck />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/settings" element={<Settings />} />
      </Routes>
    </ThemeProvider>
  );
}
