/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

import Grid from "@mui/material/Grid";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

export default function data() {
  const [users, setUsers] = useState([]);
  //const apiUrl = 'https://api.niobplateau.org.ng'
  const apiUrl = "https://api.skillcamp.niobnat.org";

  // get all Users' record
  useEffect(() => {
    const getUsersData = async () => {
      const { data } = await axios.get(`${apiUrl}/api/users/getUsers`); /*, {
       withCredentials: true, // Include cookies or session data
      });*/
      setUsers(data);
    };
    getUsersData();
  }, []);

  const User = ({ avatar, uln, fullName, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={avatar} name={fullName} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {fullName}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {uln}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Role = ({ role, qualification }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {role}
      </MDTypography>
      <MDTypography variant="caption">{qualification}</MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "sno.", accessor: "number", width: "10%", align: "center" },
      { Header: "user", accessor: "user", width: "20%", align: "left" },
      { Header: "uln", accessor: "uln", width: "10%", align: "left" },
      {
        Header: "role/qualification",
        accessor: "qualification",
        width: "20%",
        align: "left",
        Cell: ({ value }) => (
          <div
            style={{
              maxWidth: "250px",
              whiteSpace: "normal",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {value}
          </div>
        ),
      },
      { Header: "centre", accessor: "centre", width: "10%", align: "left" },
      { Header: "action", accessor: "action", width: "20%", align: "center" },
    ],

    rows: users.map((user, index) => ({
      number: <MDTypography>{index + 1 + "."}</MDTypography>,
      user: (
        <User
          avatar={user.avatar ? apiUrl + "" + user.avatar : team2}
          fullName={user.firstName + " " + user.middleName + " " + user.lastName}
          email={user.email}
        />
      ),
      uln: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {user.uln}
        </MDTypography>
      ),
      qualification: (
        <Role
          role={user.roles.map((role) => role.roleType).join(", ")}
          qualification={user.qualifications.map((q) => q.qualificationType).join(", ")}
        />
      ),
      centre: (
        <MDBox>
          {user.centres.map((centre, index) => (
            <React.Fragment key={centre.id}>
              <MDTypography
                component="a"
                href={`/centres/${centre.id}`}
                variant="caption"
                color="text"
                fontWeight="medium"
              >
                {centre.centreCode}
              </MDTypography>
              {/* Add a comma except for the last element */}
              {index < user.centres.length - 1 && ", "}
            </React.Fragment>
          ))}
        </MDBox>
      ),
      action: (
        <Grid Container space={1}>
          <Grid item>
            <MDTypography
              component="a"
              href={`users/${user.id}/view`}
              variant="caption"
              color="success"
              fontWeight="medium"
            >
              View
            </MDTypography>
          </Grid>
          <Grid item>
            <MDTypography
              component="a"
              href={`users/${user.id}/edit`}
              variant="caption"
              color="success"
              fontWeight="medium"
            >
              Edit
            </MDTypography>
          </Grid>
        </Grid>
      ),
    })),
  };
}
