import React, { useState } from "react";
import { List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import SettingsApplicationsOutlinedIcon from "@mui/icons-material/SettingsApplicationsOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const menuItems = [
  { text: "Change Avatar", icon: <PersonOutlineIcon />, href: "#avatar" },
  { text: "Basic Info", icon: <ReceiptLongIcon />, href: "#basic-info" },
  { text: "Change Password", icon: <LockOutlinedIcon />, href: "#password" },
  /*{ text: "2FA", icon: <SecurityOutlinedIcon />, href: "#2fa" },
  { text: "Accounts", icon: <BadgeOutlinedIcon />, href: "#accounts" },
  { text: "Notifications", icon: <CampaignOutlinedIcon />, href: "#notifications" },
  { text: "Sessions", icon: <SettingsApplicationsOutlinedIcon />, href: "#sessions" },*/
  { text: "Delete Account", icon: <DeleteOutlineIcon />, href: "#delete-account" },
];

const menuItems2 = [
  { text: "Centre Info", icon: <ReceiptLongIcon />, href: "#centre-info" },
  { text: "Assign EQA", icon: <PersonOutlineIcon />, href: "#assign-eqa" },
  { text: "Centre Credentials", icon: <LockOutlinedIcon />, href: "#credentials" },
  { text: "Generate Report", icon: <SecurityOutlinedIcon />, href: "#report" },
  { text: "Messaging", icon: <BadgeOutlinedIcon />, href: "#messaging" },
];

const menuItems3 = [{ text: "Bio Info", icon: <ReceiptLongIcon />, href: "#my-bio" }];

export default function Sidebar() {
  const [active, setActive] = useState("#avatar"); // Default active link

  return (
    <List
      sx={{
        width: 250,
        position: "sticky",
        top: 100,
        overflowY: "auto",
        bgcolor: "background.paper", // Remove card background
        p: 2,
        borderRadius: 3,
      }}
    >
      {menuItems.map((item) => (
        <ListItemButton
          key={item.href}
          component={Link}
          to={item.href}
          onClick={() => setActive(item.href)}
          sx={{
            borderRadius: "8px",
            mb: 0.5,
            color: active === item.href ? "black" : "gray",
            backgroundColor: active === item.href ? "#f5f6f7" : "transparent",
            "&:hover": {
              backgroundColor: "#f5f6f7",
            },
          }}
        >
          <ListItemIcon
            sx={{
              color: "inherit",
              minWidth: 40,
            }}
          >
            {item.icon}
          </ListItemIcon>
          <ListItemText
            primary={item.text}
            primaryTypographyProps={{ sx: { fontSize: "0.9rem" } }}
          />
        </ListItemButton>
      ))}
    </List>
  );
}

export function CASidebar() {
  const [active, setActive] = useState("#centre-info"); // Default active link

  return (
    <List
      sx={{
        width: 250,
        position: "sticky",
        top: 100,
        overflowY: "auto",
        bgcolor: "background.paper", // Remove card background
        p: 2,
        borderRadius: 3,
      }}
    >
      {menuItems2.map((item) => (
        <ListItemButton
          key={item.href}
          component={Link}
          to={item.href}
          onClick={() => setActive(item.href)}
          sx={{
            borderRadius: "8px",
            mb: 0.5,
            color: active === item.href ? "black" : "gray",
            backgroundColor: active === item.href ? "#f5f6f7" : "transparent",
            "&:hover": {
              backgroundColor: "#f5f6f7",
            },
          }}
        >
          <ListItemIcon
            sx={{
              color: "inherit",
              minWidth: 40,
            }}
          >
            {item.icon}
          </ListItemIcon>
          <ListItemText
            primary={item.text}
            primaryTypographyProps={{ sx: { fontSize: "0.9rem" } }}
          />
        </ListItemButton>
      ))}
    </List>
  );
}

export function ProfileSidebar() {
  const [active, setActive] = useState("#my-bio"); // Default active link

  return (
    <List
      sx={{
        width: 250,
        position: "sticky",
        top: 100,
        overflowY: "auto",
        bgcolor: "background.paper", // Remove card background
        p: 2,
        borderRadius: 3,
      }}
    >
      {menuItems3.map((item) => (
        <ListItemButton
          key={item.href}
          component={Link}
          to={item.href}
          onClick={() => setActive(item.href)}
          sx={{
            borderRadius: "8px",
            mb: 0.5,
            color: active === item.href ? "black" : "gray",
            backgroundColor: active === item.href ? "#f5f6f7" : "transparent",
            "&:hover": {
              backgroundColor: "#f5f6f7",
            },
          }}
        >
          <ListItemIcon
            sx={{
              color: "inherit",
              minWidth: 40,
            }}
          >
            {item.icon}
          </ListItemIcon>
          <ListItemText
            primary={item.text}
            primaryTypographyProps={{ sx: { fontSize: "0.9rem" } }}
          />
        </ListItemButton>
      ))}
    </List>
  );
}
