/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";
import PropTypes from "prop-types"; // For typechecking
import MDAutocompleteRoot from "components/MDAutocomplete/MDAutocompleteRoot"; // Styled component

// MDAutocomplete Component
const MDAutocomplete = forwardRef(({ error, success, disabled, ...rest }, ref) => (
  <MDAutocompleteRoot {...rest} ref={ref} ownerState={{ error, success, disabled }} />
));

// Default prop values
MDAutocomplete.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

// Prop type validation
MDAutocomplete.propTypes = {
  error: PropTypes.bool, // Indicates if the autocomplete input is in an error state
  success: PropTypes.bool, // Indicates if the autocomplete input is in a success state
  disabled: PropTypes.bool, // Indicates if the autocomplete input is disabled
};

export default MDAutocomplete;
