import React, { useState, useEffect } from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import { Link, useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";

const apiUrl = "https://api.skillcamp.niobnat.org";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDAutocomplete from "components/MDAutocomplete";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { Grid, Paper, Typography, TextField, Autocomplete, Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SendIcon from "@mui/icons-material/Send";
import ApprovalIcon from "@mui/icons-material/Approval";

const AssignEQACard = ({ eqas }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    type: "success", // "success" or "error"
    message: "",
  });

  // Show notification
  const showNotification = (type, message) => {
    setNotification({ open: true, type, message });
  };

  // Close notification
  const closeNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const id = useParams();
  const [selectedEqa, setSelectedEqa] = useState("");

  const onSubmitAssignmentClicked = async (e) => {
    e.preventDefault();

    const formData = { eqaAssignedTo: selectedEqa };

    try {
      const eqaAssigned = await axios.put(
        `${apiUrl}/api/centreapplications/assigneqa/${id.id}`,
        formData
      );

      if (eqaAssigned) {
        showNotification("success", eqaAssigned.data.message);

        // Redirect after a delay
        setTimeout(() => navigate("/"), 7000);
      }
    } catch (e) {
      showNotification("error", `Error in assigning EQA to Centre: ${e}`);
    }
  };

  const options = Array.isArray(eqas)
    ? eqas.map((eqa) => ({
        id: eqa.id,
        fullName: eqa.firstName + " " + eqa.middleName + " " + eqa.lastName,
        zone: eqa.zone,
      }))
    : [];

  if (!eqas || eqas.length === 0) {
    return (
      <MDBox pt={3} px={2}>
        <MDTypography variant="subtitle2" fontWeight="medium">
          Loading centre & eqa information...
        </MDTypography>
      </MDBox>
    );
  }

  return (
    <Paper elevation={1} sx={{ p: 3, borderRadius: 2, mt: 3 }} id="assign-eqa">
      <MDSnackbar
        color={notification.type}
        icon={notification.type === "success" ? "check" : "warning"}
        title="NIOB Skill CaMP"
        content={notification.message}
        open={notification.open}
        onClose={closeNotification}
        close={closeNotification}
        bgWhite
      />
      <Typography variant="h6" sx={{ mb: 3 }}>
        Assign EQA to Centre
      </Typography>
      <Box component="form" noValidate autoComplete="off" onSubmit={onSubmitAssignmentClicked}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <MDBox mb={2} lineHeight={0} spacing={3} display="flex" alignItems="center" md={12}>
              <MDAutocomplete
                id="eqa-selector"
                sx={{ minWidth: "350px", mr: 3 }}
                options={options}
                value={options.find((option) => option.id === selectedEqa) || null} // Set null if no selection
                onChange={(event, value) => setSelectedEqa(value?.id || "")}
                getOptionLabel={(option) => option.fullName}
                isOptionEqualToValue={(option, value) => option.fullName === value?.fullName}
                renderInput={(params) => (
                  <MDInput {...params} label="Select EQA" variant="standard" />
                )}
              />
              <MDButton type="submit" variant="gradient" color="success" sx={{ minWidth: "250px" }}>
                Submit Assignment
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

// Define PropTypes for CAInformation
AssignEQACard.propTypes = {
  eqas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      middleName: PropTypes.string,
      lastName: PropTypes.string.isRequired,
      zone: PropTypes.string.isRequired,
    })
  ),
};

// Set default props to avoid undefined errors
AssignEQACard.defaultProps = {
  eqas: [],
};

const DownloadCADocsCard = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const apiUrl = "https://api.skillcamp.niobnat.org";
  const [ca, setCa] = useState([]);
  const navigate = useNavigate();

  const id = useParams();

  // Fetch centreapplication data
  useEffect(() => {
    const fetchCentreApplicationData = async () => {
      try {
        const { data } = await axios.get(`${apiUrl}/api/centreapplications/${id.id}`);
        setCa({
          ...data,
          qualificationsList: data.qualificationsList || [], // Ensure it’s always an array
        });
      } catch (error) {
        console.error("Error fetching centre application data:", error);
        showNotification("error", error);
      }
    };
    fetchCentreApplicationData();
  }, [id]);

  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    type: "success", // "success" or "error"
    message: "",
  });

  // Show notification
  const showNotification = (type, message) => {
    setNotification({ open: true, type, message });
  };

  // Close notification
  const closeNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const onDownloadCredentialsClicked = async (e) => {
    e.preventDefault();

    try {
      // Fetch Centre's Credentials URL
      const response = await axios.get(`${apiUrl}/api/centreapplications/${id.id}`);
      const ca = response.data; // Extract actual data

      if (ca && ca.centreCredentialsUrl) {
        // Construct the full file URL
        const fileUrl = `${apiUrl}/${ca.centreCredentialsUrl.replace(/\\/g, "/")}`;

        // Fetch the file as a Blob to ensure it downloads instead of opening
        const fileResponse = await fetch(fileUrl);
        const blob = await fileResponse.blob();

        // Determine the file extension
        const fileExtension = fileUrl.split(".").pop().toLowerCase();
        const suggestedFilename =
          fileExtension === "zip" ? "Centre_Credentials.zip" : "Centre_Credentials.pdf";

        // Create a Blob URL and trigger download
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = suggestedFilename; // Force correct filename
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      } else {
        showNotification("error", "Centre Credentials URL not found in response");
      }
    } catch (error) {
      showNotification("error", `Error fetching Centre Credentials: ${error}`);
    }
  };

  const onDownloadEQAReportClicked = async (e) => {
    e.preventDefault();

    try {
      // Fetch Centre's Credentials URL
      const response = await axios.get(`${apiUrl}/api/centreapplications/${id.id}`);
      const ca = response.data; // Extract actual data

      if (ca && ca.eqaReportsUrl) {
        // Construct the full file URL
        const fileUrl = `${apiUrl}/${ca.eqaReportsUrl.replace(/\\/g, "/")}`;

        // Determine the file extension
        const fileExtension = fileUrl.split(".").pop().toLowerCase();
        const suggestedFilename =
          fileExtension === "zip" ? "EQA_Centre_Visit_Report.zip" : "EQA_Centre_Visit_Report.pdf";

        // Fetch the file as a Blob
        const fileResponse = await fetch(fileUrl);
        const blob = await fileResponse.blob();

        // Create a Blob URL and trigger download
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", suggestedFilename);
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
      } else {
        console.warn("EQA Report URL not found in response");
        showNotification("error", "EQA Report URL not found in response");
      }
    } catch (error) {
      console.error("Error fetching EQA Report:", error);
      showNotification("error", `Error fetching EQA Report: ${error}`);
    }
  };

  const onGenerateCAReportClicked = async (e) => {
    e.preventDefault();

    // Assuming centreapplication is an object with data that you want to send for PDF creation
    const response = await axios.get(`${apiUrl}/api/centreapplications/${id.id}`);
    const centreapplication = response.data;

    // POST request to generate the PDF
    axios
      .post(`${apiUrl}/create-pdf`, centreapplication)
      .then(() => {
        // GET request to fetch the generated PDF as a blob
        return axios.get(`${apiUrl}/fetch-pdf`, { responseType: "blob" });
      })
      .then((res) => {
        // Create a Blob from the response data (PDF)
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });

        // Use the saveAs function from file-saver to download the PDF
        saveAs(pdfBlob, `${centreapplication.centreName}_Center_Application.pdf`);
      })
      .catch((error) => {
        console.error("Error generating or downloading PDF:", error);
        showNotification("error", `Error generating or downloading PDF: ${error}`);
      });
  };

  return (
    <Paper elevation={1} sx={{ p: 3, borderRadius: 2, mt: 3 }} id="credentials">
      <MDSnackbar
        color={notification.type}
        icon={notification.type === "success" ? "check" : "warning"}
        title="NIOB Skill CaMP"
        content={notification.message}
        open={notification.open}
        onClose={closeNotification}
        close={closeNotification}
        bgWhite
      />
      <Grid item xs={12} lg={12}>
        <MDBox lineHeight={0} sx={{ mb: 3 }}>
          <MDTypography variant="h6">Centre Application Documents</MDTypography>
          <MDTypography variant="caption" color="text" fontWeight="regular">
            All documents related to the Centre Application are available for you to download
          </MDTypography>
        </MDBox>
        <MDBox>
          <Grid container spacing={1}>
            <Grid item lg={4}>
              <MDButton
                variant="gradient"
                color="dark"
                onClick={onDownloadCredentialsClicked}
                fullWidth
                startIcon={<FileDownloadIcon />}
              >
                Download Centre Credentials
              </MDButton>
            </Grid>
            <Grid item lg={4}>
              <MDButton
                variant="gradient"
                color="primary"
                disabled={ca.eqaCentreReportComplete ? false : true}
                onClick={onDownloadEQAReportClicked}
                fullWidth
                startIcon={<FileDownloadIcon />}
              >
                Download EQA Centre Visit Report
              </MDButton>
            </Grid>
            <Grid item lg={4}>
              <MDButton
                variant="gradient"
                color="info"
                disabled={ca.eqaCentreReportComplete ? false : true}
                onClick={onGenerateCAReportClicked}
                fullWidth
                startIcon={<PictureAsPdfIcon />}
              >
                Generate Centre Application Report
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
    </Paper>
  );
};

const ApproveCACard = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const apiUrl = "https://api.skillcamp.niobnat.org";
  const [ca, setCa] = useState([]);
  const navigate = useNavigate();

  const id = useParams();

  // Fetch centreapplication data
  useEffect(() => {
    const fetchCentreApplicationData = async () => {
      try {
        const { data } = await axios.get(`${apiUrl}/api/centreapplications/${id.id}`);
        setCa({
          ...data,
          qualificationsList: data.qualificationsList || [], // Ensure it’s always an array
        });
      } catch (error) {
        console.error("Error fetching centre application data:", error);
        showNotification("error", error);
      }
    };
    fetchCentreApplicationData();
  }, [id]);

  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    type: "success", // "success" or "error"
    message: "",
  });

  // Show notification
  const showNotification = (type, message) => {
    setNotification({ open: true, type, message });
  };

  // Close notification
  const closeNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const onApproveCAClicked = async (e) => {
    e.preventDefault();

    try {
      if (!ca || !ca.eqaCentreReportComplete) {
        return showNotification("error", "EQA Centre Report is not completed");
      }

      const response = await axios.put(`${apiUrl}/api/approve/${id.id}`);

      if (response.status === 201) {
        showNotification("success", `Centre ${ca.centreName} has been Approved`);

        // Redirect after a delay
        setTimeout(() => navigate("/centreapplications"), 7000);
      } else {
        showNotification("error", "Failed to approve Centre. Please try again.");
      }
    } catch (error) {
      console.error("Approval Error: ", error);
      showNotification("error", error.response?.data || "Error approving Centre");
    }
  };

  return (
    <Paper elevation={1} sx={{ p: 3, borderRadius: 2, mt: 3 }} id="approval">
      <MDSnackbar
        color={notification.type}
        icon={notification.type === "success" ? "check" : "warning"}
        title="NIOB Skill CaMP"
        content={notification.message}
        open={notification.open}
        onClose={closeNotification}
        close={closeNotification}
        bgWhite
      />
      <Grid item xs={12} lg={12}>
        <MDBox lineHeight={0} sx={{ mb: 3 }}>
          <MDTypography variant="h6">Centre Application Feedback</MDTypography>
          <MDTypography variant="caption" color="text" fontWeight="regular">
            Approve Centre Application or send a feedback to the Centre
          </MDTypography>
        </MDBox>
        <MDBox>
          <Grid container spacing={1}>
            <Grid item lg={4}>
              <MDButton
                variant="gradient"
                color="success"
                disabled={
                  ca.eqaCentreReportComplete && !ca.centreApplicationApproved ? false : true
                }
                onClick={onApproveCAClicked}
                fullWidth
                startIcon={<ApprovalIcon />}
              >
                Approve Centre Application
              </MDButton>
            </Grid>
            <Grid item lg={4}>
              <MDButton
                variant="gradient"
                color="light"
                onClick={onApproveCAClicked}
                fullWidth
                startIcon={<SendIcon />}
              >
                Send Feedback to Centre
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
    </Paper>
  );
};

export { AssignEQACard, DownloadCADocsCard, ApproveCACard };
