/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

export default function data() {
  const [centreapplications, setCentreApplications] = useState([]);
  //const apiUrl = 'https://api.niobplateau.org.ng'
  const apiUrl = "https://api.skillcamp.niobnat.org";

  // get all Centres' Applications record
  useEffect(() => {
    const getCentreApplicationsData = async () => {
      const { data } = await axios.get(
        `${apiUrl}/api/centreapplications/getCentreApplications`
      ); /*, {
       withCredentials: true, // Include cookies or session data
      });*/
      setCentreApplications(data);
    };
    getCentreApplicationsData();
  }, []);

  const CentreApplication = ({ cacNumber, centreName }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {centreName}
        </MDTypography>
        <MDTypography variant="caption">{cacNumber}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Assignment = ({ isAssigned, eqaAssigned }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDBox ml={-1}>
        <MDBadge
          badgeContent={isAssigned ? "assigned" : "unassigned"}
          color={isAssigned ? "success" : "dark"}
          variant="gradient"
          size="sm"
        />
      </MDBox>
      <MDTypography variant="caption">{eqaAssigned}</MDTypography>
    </MDBox>
  );

  const Status = ({ isApproved }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDBox ml={-1}>
        <MDBadge
          badgeContent={isApproved ? "approved" : "pending"}
          color={isApproved ? "success" : "dark"}
          variant="gradient"
          size="sm"
        />
      </MDBox>
    </MDBox>
  );

  const Report = ({ eqaReportCompleted }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDBox ml={-1}>
        <MDBadge
          badgeContent={eqaReportCompleted ? "completed" : "pending"}
          color={eqaReportCompleted ? "success" : "dark"}
          variant="gradient"
          size="sm"
        />
      </MDBox>
    </MDBox>
  );

  return {
    columns: [
      { Header: "sno.", accessor: "number", width: "10%", align: "center" },
      { Header: "centre", accessor: "centre", width: "20%", align: "left" },
      { Header: "is-assigned", accessor: "assignment", width: "20%", align: "left" },
      { Header: "application status", accessor: "status", width: "10%", align: "center" },
      { Header: "is-report-completed", accessor: "report", width: "150px", align: "left" },
      { Header: "state", accessor: "state", width: "20%", align: "left" },
      { Header: "action", accessor: "action", width: "20%", align: "center" },
    ],

    rows: centreapplications.map((centreapplication, index) => ({
      number: <MDTypography>{index + 1 + "."}</MDTypography>,
      centre: (
        <CentreApplication
          cacNumber={`RC ${centreapplication.cacNumber}`}
          centreName={centreapplication.centreName}
        />
      ),
      assignment: (
        <Assignment
          isAssigned={centreapplication.eqaAssigned}
          eqaAssigned={`${centreapplication?.eqaInCharge?.firstName || ""} ${
            centreapplication?.eqaInCharge?.lastName || ""
          }`}
        />
      ),
      status: <Status isApproved={centreapplication.centreApplicationApproved} />,
      report: <Report eqaReportCompleted={centreapplication.eqaCentreReportComplete} />,
      state: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {centreapplication.stateOfRez}
        </MDTypography>
      ),
      action: (
        <MDTypography
          component="a"
          href={`centreapplications/${centreapplication.id}/view`}
          variant="caption"
          color="success"
          fontWeight="medium"
        >
          View Application
        </MDTypography>
      ),
    })),
  };
}
