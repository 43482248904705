// stefan.dataanalyst - wfthxGz4zd
// deji4me - FDa5NUfB8x

// @mui material components
import { Grid, Card, Divider, Icon, Tooltip } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAutocomplete from "components/MDAutocomplete";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar, { DashboardNavbar2 } from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { STATES } from "../../config/states";
import { ZONES } from "../../config/zones";

//import MDLoadingButton from "@mui/lab/LoadingButton";
import { useTheme, styled } from "@mui/material";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDAlert from "@mui/material/Alert";
import MDHomeIcon from "@mui/icons-material/Home";
import MDVisibilityIcon from "@mui/icons-material/Visibility";
import MDCloudUploadIcon from "@mui/icons-material/CloudUpload";
import MDTextField from "@mui/material/TextField";
import MDStack from "@mui/material/Stack";
import MDButton from "components/MDButton";
import MDFormControl from "@mui/material/FormControl";
import MDInputLabel from "@mui/material/InputLabel";
import MDSelect from "@mui/material/Select";
import MDMenuItem from "@mui/material/MenuItem";
import MDGrid from "@mui/material/Grid";
import MDCard from "@mui/material/Card";

const USER_REGEX = /^[A-z]{3,20}$/;
const EMAIL_REGEX = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/;
const PHONE_REGEX = /^[0-9]{11}$/;

// Data
import usersTableData from "layouts/users/data/usersTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import { useAuth } from "../../AuthProvider"; // Corrected Import
import { Navigate, useLocation } from "react-router-dom";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

import burceMars from "assets/images/bruce-mars.jpg";

// Display All Users
export default function Users() {
  const { columns, rows } = usersTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();

  const { auth } = useAuth();
  const userRole = auth?.roles?.toLowerCase();

  if (userRole !== "admin") {
    return <Navigate to="/error404" />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar2 />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
              >
                <MDTypography variant="h6" color="white">
                  All Users
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  canSearch={true}
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  pagination={{ variant: "gradient", color: "success" }}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

// New User Form
export function NewUserForm() {
  const navigate = useNavigate();
  const [uln, setUln] = useState("0000");
  const [firstName, setFirstName] = useState("");
  const [validFirstName, setValidFirstName] = useState(false);
  const [middleName, setMiddleName] = useState("");
  const [validMiddleName, setValidMiddleName] = useState(false);
  const [lastName, setLastName] = useState("");
  const [validLastName, setValidLastName] = useState(false);
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [gender, setGender] = useState("");
  const [phone, setPhone] = useState("");
  const [validPhone, setValidPhone] = useState(false);
  const [roles, setRoles] = useState([]);
  const [stateOfRez, setStateOfRez] = useState("");
  const [zone, setZone] = useState("");
  const [centres, setCentres] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const apiUrl = "https://api.skillcamp.niobnat.org";

  const [rolesData, setRolesData] = useState([]);
  const [qualificationsData, setQualificationsData] = useState([]);
  const [centresData, setCentresData] = useState([]);

  const { auth } = useAuth();
  const userRole = auth?.roles?.toLowerCase();

  if (userRole !== "admin") {
    return <Navigate to="/error404" />;
  }

  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    type: "success", // "success" or "error"
    message: "",
  });

  // Show notification
  const showNotification = (type, message) => {
    setNotification({ open: true, type, message });
  };

  // Close notification
  const closeNotification = () => {
    setNotification({ ...notification, open: false });
  };

  // Fetch roles, qualifications, and centres data
  useEffect(() => {
    const fetchData = async () => {
      const rolesResponse = await axios.get(`${apiUrl}/api/roles/getRoles`);
      const qualificationsResponse = await axios.get(
        `${apiUrl}/api/qualifications/getQualifications`
      );
      const centresResponse = await axios.get(`${apiUrl}/api/centres/getCentres`);

      setRolesData(rolesResponse.data);
      setQualificationsData(qualificationsResponse.data);
      setCentresData(centresResponse.data);
    };

    fetchData();
  }, []);

  // Input validation
  useEffect(() => {
    setValidFirstName(USER_REGEX.test(firstName));
    setValidMiddleName(USER_REGEX.test(middleName));
    setValidLastName(USER_REGEX.test(lastName));
    setValidEmail(EMAIL_REGEX.test(email));
    setValidPhone(PHONE_REGEX.test(phone));
  }, [firstName, middleName, lastName, email, phone]);

  const onFirstNameChanged = (e) => setFirstName(e.target.value);
  const onMiddleNameChanged = (e) => setMiddleName(e.target.value);
  const onLastNameChanged = (e) => setLastName(e.target.value);
  const onEmailChanged = (e) => setEmail(e.target.value);
  const onPhoneChanged = (e) => setPhone(e.target.value);

  const onSaveUserClicked = async (e) => {
    e.preventDefault();

    // Map roles, centres, and qualifications to their corresponding IDs
    const selectedRoleIds = roles
      .map((role) => rolesData.find((data) => data.roleType === role)?.id)
      .filter((id) => id); // Filter out undefined values

    const selectedCentreIds = centres
      .map((centre) => centresData.find((data) => data.centreName === centre)?.id)
      .filter((id) => id);

    const selectedQualificationIds = qualifications
      .map(
        (qualification) =>
          qualificationsData.find((data) => data.qualificationType === qualification)?.id
      )
      .filter((id) => id);

    try {
      if (uln === "0000") {
        setRoles([]);
        //setRoles(["f04e319b-53e3-4cc5-b3e5-6fb4d71b8c39"]);
        const formData = {
          uln,
          firstName,
          middleName,
          lastName,
          gender,
          email,
          phone,
          roles: ["f04e319b-53e3-4cc5-b3e5-6fb4d71b8c39"],
          stateOfRez,
          zone,
          centres,
          qualifications,
        };

        const userAdded = await axios.post(`${apiUrl}/api/users/addUser`, formData);

        if (userAdded) {
          console.log("Response: ", userAdded);
          setUln("");
          setFirstName("");
          setMiddleName("");
          setLastName("");
          setEmail("");
          setPhone("");
          setRoles([]);
          showNotification("success", "User added successfully!");

          // Redirect after a delay
          setTimeout(() => navigate("/users"), 7000);
        }
      } else {
        const formData = {
          uln,
          firstName,
          middleName,
          lastName,
          gender,
          email,
          phone,
          roles: selectedRoleIds,
          stateOfRez,
          zone,
          centres: selectedCentreIds,
          qualifications: selectedQualificationIds,
        };

        const userAdded = await axios.post(`${apiUrl}/api/users/addUser`, formData);

        if (userAdded) {
          console.log("Response: ", userAdded);
          setUln("");
          setFirstName("");
          setMiddleName("");
          setLastName("");
          setEmail("");
          setPhone("");
          setRoles([]);
          showNotification("success", "User added successfully!");

          // Redirect after a delay
          setTimeout(() => navigate("/users"), 7000);
        }
      }
    } catch (e) {
      console.error("Error while adding user: ", error.response.data.message);
      showNotification("error", `${error.response.data.message}, Try again.`);
    }
  };

  // Options for autocomplete components
  const options0 = rolesData.map((role) => ({ id: role.id, roleType: role.roleType }));
  const options2 = centresData.map((centre) => ({ id: centre.id, centreName: centre.centreName }));
  const options3 = qualificationsData.map((qualification) => ({
    id: qualification.id,
    qualificationType: qualification.qualificationType,
  }));
  const options1 = Object.entries(STATES).map(([key, value]) => ({
    key,
    state: value,
  }));

  const options4 = Object.entries(ZONES).map(([key, value]) => ({
    key,
    zone: value,
  }));

  const canSave = [
    roles.length,
    centres.length,
    qualifications.length,
    validFirstName,
    validLastName,
    validEmail,
    validPhone,
  ].every(Boolean);

  // Ensure values are always valid objects or null
  const mapValuesToOptions = (values, options, labelKey) =>
    values.map(
      (value) => options.find((option) => option[labelKey] === value) || { [labelKey]: value }
    );

  return (
    <DashboardLayout>
      <DashboardNavbar2 />
      <MDSnackbar
        color={notification.type}
        icon={notification.type === "success" ? "check" : "warning"}
        title="NIOB Skill CaMP"
        content={notification.message}
        open={notification.open}
        onClose={closeNotification}
        close={closeNotification}
        bgWhite
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
              >
                <MDTypography variant="h6" color="white">
                  Add New User
                </MDTypography>
              </MDBox>
              <MDBox p={3}>
                <form onSubmit={onSaveUserClicked}>
                  <MDBox
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": { gridColumn: undefined },
                    }}
                  >
                    <MDInput
                      fullWidth
                      variant="standard"
                      type="text"
                      label="Unique Leaner's Number"
                      onChange={(e) => setUln(e.target.value)}
                      value={uln}
                      name="uln"
                      sx={{ gridColumn: "span 4" }}
                    />

                    <MDInput
                      fullWidth
                      variant="standard"
                      type="text"
                      label="First Name"
                      onChange={onFirstNameChanged}
                      value={firstName}
                      name="firstName"
                      sx={{ gridColumn: { xs: "span 4", sm: "span 2" } }}
                    />

                    <MDInput
                      fullWidth
                      variant="standard"
                      type="text"
                      label="Middle Name"
                      onChange={onMiddleNameChanged}
                      value={middleName}
                      name="middleName"
                      sx={{ gridColumn: { xs: "span 4", sm: "span 1" } }}
                    />

                    <MDInput
                      fullWidth
                      variant="standard"
                      type="text"
                      label="Last Name"
                      onChange={onLastNameChanged}
                      value={lastName}
                      name="lastName"
                      sx={{ gridColumn: { xs: "span 4", sm: "span 1" } }}
                    />

                    <MDAutocomplete
                      id="gender-selector"
                      sx={{ gridColumn: { xs: "span 4", sm: "span 1" } }}
                      options={[
                        { label: "Male", value: "Male" },
                        { label: "Female", value: "Female" },
                      ]} // Array of options for the autocomplete
                      getOptionLabel={(option) => option.label || ""} // Display label of the option
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          fullWidth
                          label="Select Gender"
                          variant="standard"
                          aria-autocomplete="list"
                          spellCheck={false}
                          autoComplete="off"
                          autoCapitalize="none"
                        />
                      )}
                      onChange={(event, value) => {
                        setGender(value?.value || ""); // Call setGender with the value (e.g., "male" or "female")
                      }}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                    />

                    <MDInput
                      fullWidth
                      variant="standard"
                      type="email"
                      label="Email"
                      onChange={onEmailChanged}
                      value={email}
                      name="email"
                      sx={{ gridColumn: { xs: "span 4", sm: "span 2" } }}
                    />

                    <MDInput
                      fullWidth
                      variant="standard"
                      type="tel"
                      label="Phone Number"
                      onChange={onPhoneChanged}
                      value={phone}
                      name="phone"
                      sx={{ gridColumn: { xs: "span 4", sm: "span 1" } }}
                    />

                    <MDAutocomplete
                      id="zone-selector"
                      sx={{ gridColumn: { xs: "span 4", sm: "span 2" } }}
                      options={options4}
                      value={options4.find((option) => option.zone === zone) || null} // Set a single value or null if no match
                      onChange={(event, value) => setZone(value?.zone || "")} // Set only one zone value
                      getOptionLabel={(option) => option.zone} // Access the zone property for display
                      isOptionEqualToValue={(option, value) => option.zone === value?.zone}
                      renderInput={(params) => (
                        <MDInput {...params} label="Select Zone" variant="standard" />
                      )}
                    />

                    <MDAutocomplete
                      id="state-selector"
                      sx={{ gridColumn: { xs: "span 4", sm: "span 2" } }}
                      options={options1}
                      value={options1.find((option) => option.state === stateOfRez) || null} // Set null if no selection
                      onChange={(event, value) => setStateOfRez(value?.state || "")} // Update the stateOfRez based on selection
                      getOptionLabel={(option) => option.state} // Display the state property for options
                      isOptionEqualToValue={(option, value) => option.state === value?.state} // Match state
                      renderInput={(params) => (
                        <MDInput {...params} label="Select State" variant="standard" />
                      )}
                    />

                    <MDAutocomplete
                      id="centre-selector"
                      options={options2}
                      multiple
                      value={mapValuesToOptions(centres, options2, "centreName")}
                      onChange={(event, value) => setCentres(value.map((item) => item.centreName))}
                      getOptionLabel={(option) => option.centreName || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.centreName === value?.centreName
                      }
                      renderInput={(params) => (
                        <MDInput {...params} label="Select Centres" variant="standard" />
                      )}
                      sx={{ gridColumn: { xs: "span 4", sm: "span 2" } }}
                    />

                    <MDAutocomplete
                      id="role-selector"
                      options={options0}
                      multiple
                      value={mapValuesToOptions(roles, options0, "roleType")}
                      onChange={(event, value) => setRoles(value.map((item) => item.roleType))}
                      getOptionLabel={(option) => option.roleType || ""}
                      isOptionEqualToValue={(option, value) => option.roleType === value?.roleType}
                      renderInput={(params) => (
                        <MDInput {...params} label="Select Roles" variant="standard" />
                      )}
                      sx={{ gridColumn: { xs: "span 4", sm: "span 2" } }}
                    />

                    <MDAutocomplete
                      id="qualification-selector"
                      options={options3}
                      multiple
                      value={mapValuesToOptions(qualifications, options3, "qualificationType")}
                      onChange={(event, value) =>
                        setQualifications(value.map((item) => item.qualificationType))
                      }
                      getOptionLabel={(option) => option.qualificationType || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.qualificationType === value?.qualificationType
                      }
                      renderInput={(params) => (
                        <MDInput {...params} label="Select Qualifications" variant="standard" />
                      )}
                      sx={{ gridColumn: "span 4" }}
                    />
                  </MDBox>
                  <MDBox>
                    <MDButton
                      disabled={!canSave}
                      loading={false}
                      type="submit"
                      variant="contained"
                      color="success"
                      sx={{ mt: 3 }}
                    >
                      Add New User
                    </MDButton>
                  </MDBox>
                </form>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

// Edit User Form
export function EditUserForm() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [uln, setUln] = useState("");
  const [firstName, setFirstName] = useState("");
  const [validFirstName, setValidFirstName] = useState(false);
  const [middleName, setMiddleName] = useState("");
  const [validMiddleName, setValidMiddleName] = useState(false);
  const [lastName, setLastName] = useState("");
  const [validLastName, setValidLastName] = useState(false);
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [gender, setGender] = useState("");
  const [phone, setPhone] = useState("");
  const [validPhone, setValidPhone] = useState(false);
  const [roles, setRoles] = useState([]);
  const [stateOfRez, setStateOfRez] = useState("");
  const [zone, setZone] = useState("");
  const [centres, setCentres] = useState([]);
  const [qualifications, setQualifications] = useState([]);

  const [rolesData, setRolesData] = useState([]);
  const [qualificationsData, setQualificationsData] = useState([]);
  const [centresData, setCentresData] = useState([]);

  const apiUrl = "https://api.skillcamp.niobnat.org";

  const { auth } = useAuth();
  const userRole = auth?.roles?.toLowerCase();

  if (userRole !== "admin") {
    return <Navigate to="/error404" />;
  }

  // Notification state
  const [notification, setNotification] = useState({
    open: false,
    type: "success", // "success" or "error"
    message: "",
  });

  // Show notification
  const showNotification = (type, message) => {
    setNotification({ open: true, type, message });
  };

  // Close notification
  const closeNotification = () => {
    setNotification({ ...notification, open: false });
  };

  // Fetch user data
  useEffect(() => {
    const fetchUserData = async () => {
      const { data } = await axios.get(`${apiUrl}/api/users/${id}`);
      setUln(data.uln);
      setFirstName(data.firstName);
      setMiddleName(data.middleName);
      setLastName(data.lastName);
      setGender(data.gender);
      setPhone(data.phone);
      setEmail(data.email);
      setStateOfRez(data.stateOfRez);
      setZone(data.zone);
      setQualifications(data.qualifications.map((q) => q.qualificationType));
      setRoles(data.roles.map((r) => r.roleType));
      setCentres(data.centres.map((c) => c.centreName));
    };
    fetchUserData();
  }, [id]);

  // Fetch roles, qualifications, and centres data
  useEffect(() => {
    const fetchData = async () => {
      const rolesResponse = await axios.get(`${apiUrl}/api/roles/getRoles`);
      const qualificationsResponse = await axios.get(
        `${apiUrl}/api/qualifications/getQualifications`
      );
      const centresResponse = await axios.get(`${apiUrl}/api/centres/getCentres`);

      setRolesData(rolesResponse.data);
      setQualificationsData(qualificationsResponse.data);
      setCentresData(centresResponse.data);
    };

    fetchData();
  }, []);

  // Input validation
  useEffect(() => {
    setValidFirstName(USER_REGEX.test(firstName));
    setValidMiddleName(USER_REGEX.test(middleName));
    setValidLastName(USER_REGEX.test(lastName));
    setValidEmail(EMAIL_REGEX.test(email));
    setValidPhone(PHONE_REGEX.test(phone));
  }, [firstName, middleName, lastName, email, phone]);

  // Form submission handler
  const onSaveUserClicked = async (e) => {
    e.preventDefault();

    // Map roles, centres, and qualifications to their corresponding IDs
    const selectedRoleIds = roles
      .map((role) => rolesData.find((data) => data.roleType === role)?.id)
      .filter((id) => id); // Filter out undefined values

    const selectedCentreIds = centres
      .map((centre) => centresData.find((data) => data.centreName === centre)?.id)
      .filter((id) => id);

    const selectedQualificationIds = qualifications
      .map(
        (qualification) =>
          qualificationsData.find((data) => data.qualificationType === qualification)?.id
      )
      .filter((id) => id);

    const formData = {
      uln,
      firstName,
      middleName,
      lastName,
      gender,
      email,
      phone,
      roles: selectedRoleIds,
      stateOfRez,
      zone,
      centres: selectedCentreIds,
      qualifications: selectedQualificationIds,
    };

    //const endpoint = uln === "0000" ? "/api/users/addUser" : `/api/users/edit/${id}`;
    const response = await axios.put(`${apiUrl}/api/users/${id}`, formData);

    if (response) {
      showNotification("success", "User record updated successfully!");

      // Redirect after a delay
      setTimeout(() => navigate("/users"), 7000);
    }
  };

  // Options for autocomplete components
  const options0 = rolesData.map((role) => ({ id: role.id, roleType: role.roleType }));
  const options2 = centresData.map((centre) => ({ id: centre.id, centreName: centre.centreName }));
  const options3 = qualificationsData.map((qualification) => ({
    id: qualification.id,
    qualificationType: qualification.qualificationType,
  }));
  const options1 = Object.entries(STATES).map(([key, value]) => ({
    key,
    state: value,
  }));

  const options4 = Object.entries(ZONES).map(([key, value]) => ({
    key,
    zone: value,
  }));

  // Form validation state
  const canSave = [
    validFirstName,
    validLastName,
    validEmail,
    validPhone,
    roles.length,
    centres.length,
    qualifications.length,
  ].every(Boolean);

  // Ensure values are always valid objects or null
  const mapValuesToOptions = (values, options, labelKey) =>
    values.map(
      (value) => options.find((option) => option[labelKey] === value) || { [labelKey]: value }
    );

  return (
    <DashboardLayout>
      <DashboardNavbar2 />
      <MDSnackbar
        color={notification.type}
        icon={notification.type === "success" ? "check" : "warning"}
        title="NIOB Skill CaMP"
        content={notification.message}
        open={notification.open}
        onClose={closeNotification}
        close={closeNotification}
        bgWhite
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="success"
              >
                <MDTypography variant="h6" color="white">
                  Update User Record
                </MDTypography>
              </MDBox>
              <MDBox p={3}>
                <form onSubmit={onSaveUserClicked}>
                  <MDBox display="grid" gap="30px" gridTemplateColumns="repeat(4, minmax(0, 1fr))">
                    <MDInput
                      fullWidth
                      variant="standard"
                      type="text"
                      label="Unique Learner's Number"
                      value={uln}
                      disabled
                      sx={{ gridColumn: "span 4" }}
                    />

                    <MDInput
                      fullWidth
                      variant="standard"
                      type="text"
                      label="First Name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      sx={{ gridColumn: { xs: "span 4", sm: "span 2" } }}
                    />

                    <MDInput
                      fullWidth
                      variant="standard"
                      type="text"
                      label="Middle Name"
                      value={middleName}
                      onChange={(e) => setMiddleName(e.target.value)}
                      sx={{ gridColumn: { xs: "span 4", sm: "span 1" } }}
                    />

                    <MDInput
                      fullWidth
                      variant="standard"
                      type="text"
                      label="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      sx={{ gridColumn: { xs: "span 4", sm: "span 1" } }}
                    />

                    <MDAutocomplete
                      id="gender-selector"
                      sx={{ gridColumn: { xs: "span 4", sm: "span 1" } }}
                      options={[
                        { label: "Male", value: "Male" },
                        { label: "Female", value: "Female" },
                      ]}
                      getOptionLabel={(option) => option.label || ""}
                      renderInput={(params) => (
                        <MDInput
                          {...params}
                          fullWidth
                          label="Select Gender"
                          variant="standard"
                          value={gender} // Populate the current value
                          aria-autocomplete="list"
                          spellCheck={false}
                          autoComplete="off"
                          autoCapitalize="none"
                        />
                      )}
                      value={gender ? { label: gender, value: gender } : null} // Set current value as default
                      onChange={(event, value) => {
                        setGender(value?.value || ""); // Update gender state
                      }}
                      isOptionEqualToValue={(option, value) => option.value === value?.value}
                    />

                    <MDInput
                      fullWidth
                      variant="standard"
                      type="email"
                      label="Email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      name="email"
                      sx={{ gridColumn: { xs: "span 4", sm: "span 2" } }}
                    />

                    <MDInput
                      fullWidth
                      variant="standard"
                      type="tel"
                      label="Phone Number"
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                      name="phone"
                      sx={{ gridColumn: { xs: "span 4", sm: "span 1" } }}
                    />

                    <MDAutocomplete
                      id="state-selector"
                      sx={{ gridColumn: { xs: "span 4", sm: "span 2" } }}
                      options={options1}
                      value={options1.find((option) => option.state === stateOfRez) || null} // Set a single value or null if no match
                      onChange={(event, value) => setStateOfRez(value?.state || "")} // Set only one state value
                      getOptionLabel={(option) => option.state} // Access the state property for display
                      isOptionEqualToValue={(option, value) => option.state === value?.state}
                      renderInput={(params) => (
                        <MDInput {...params} label="Select State" variant="standard" />
                      )}
                    />

                    <MDAutocomplete
                      id="zone-selector"
                      sx={{ gridColumn: { xs: "span 4", sm: "span 2" } }}
                      options={options4}
                      value={options4.find((option) => option.zone === zone) || null} // Set a single value or null if no match
                      onChange={(event, value) => setZone(value?.zone || "")} // Set only one zone value
                      getOptionLabel={(option) => option.zone} // Access the zone property for display
                      isOptionEqualToValue={(option, value) => option.zone === value?.zone}
                      renderInput={(params) => (
                        <MDInput {...params} label="Select Zone" variant="standard" />
                      )}
                    />

                    <MDAutocomplete
                      id="role-selector"
                      options={options0}
                      multiple
                      value={mapValuesToOptions(roles, options0, "roleType")}
                      onChange={(event, value) => setRoles(value.map((item) => item.roleType))}
                      getOptionLabel={(option) => option.roleType || ""}
                      isOptionEqualToValue={(option, value) => option.roleType === value?.roleType}
                      renderInput={(params) => (
                        <MDInput {...params} label="Select Roles" variant="standard" />
                      )}
                      sx={{ gridColumn: { xs: "span 4", sm: "span 2" } }}
                    />

                    <MDAutocomplete
                      id="centre-selector"
                      options={options2}
                      multiple
                      value={mapValuesToOptions(centres, options2, "centreName")}
                      onChange={(event, value) => setCentres(value.map((item) => item.centreName))}
                      getOptionLabel={(option) => option.centreName || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.centreName === value?.centreName
                      }
                      renderInput={(params) => (
                        <MDInput {...params} label="Select Centres" variant="standard" />
                      )}
                      sx={{ gridColumn: { xs: "span 4", sm: "span 2" } }}
                    />

                    <MDAutocomplete
                      id="qualification-selector"
                      options={options3}
                      multiple
                      value={mapValuesToOptions(qualifications, options3, "qualificationType")}
                      onChange={(event, value) =>
                        setQualifications(value.map((item) => item.qualificationType))
                      }
                      getOptionLabel={(option) => option.qualificationType || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.qualificationType === value?.qualificationType
                      }
                      renderInput={(params) => (
                        <MDInput {...params} label="Select Qualifications" variant="standard" />
                      )}
                      sx={{ gridColumn: "span 4" }}
                    />
                  </MDBox>
                  <MDBox mt={3}>
                    <MDButton type="submit" disabled={!canSave} variant="gradient" color="success">
                      Update User Record
                    </MDButton>
                  </MDBox>
                </form>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

// View Single User
export function ViewUser() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const apiUrl = "https://api.skillcamp.niobnat.org";
  const [_user, setUser] = useState(null);
  const { id } = useParams();

  const { auth } = useAuth();
  const userRole = auth?.roles?.toLowerCase();

  if (userRole !== "admin") {
    return <Navigate to="/error404" />;
  }

  // Fetch user data only if `id` is available
  useEffect(() => {
    if (!id) return;

    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/users/${id}`);

        if (response.data) {
          setUser({
            ...response.data,
            qualifications: response.data.qualifications || [],
            roles: response.data.roles || [],
          });
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardNavbar2 />
      <MDBox mt={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Grid container spacing={3} alignItems="center" pb={3} pl={2}>
                <Grid item>
                  <MDAvatar
                    src={apiUrl + "" + _user?.avatar || burceMars}
                    alt="profile-image"
                    size="xl"
                    shadow="sm"
                  />
                </Grid>
                <Grid item>
                  <MDBox height="100%" mt={0.5} lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="medium">
                      {_user?.firstName.toUpperCase() +
                        " " +
                        _user?.middleName.toUpperCase() +
                        " " +
                        _user?.lastName.toUpperCase()}
                    </MDTypography>
                    <MDTypography variant="button" color="success" fontWeight="regular">
                      {_user?.roles.map((role) => {
                        if (role.roleType === "EQAM") {
                          return "External Quality Assurance Manager";
                        } else if (role.roleType === "IQAM") {
                          return "Internal Quality Assurance Manager";
                        }
                      })}
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
              <Card>
                <MDBox pt={1} pb={2} px={2}>
                  <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    <MDBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      bgColor={darkMode ? "transparent" : "grey-100"}
                      borderRadius="lg"
                      p={3}
                      mb={1}
                      mt={2}
                    >
                      <MDBox width="100%" display="flex" flexDirection="column">
                        <MDBox mb={2} lineHeight={0} display="flex" alignItems="center">
                          <MDTypography
                            variant="subtitle2"
                            color="success"
                            sx={{ minWidth: "150px" }}
                          >
                            ULN:
                          </MDTypography>
                          <MDTypography variant="h6" fontWeight="regular">
                            {_user?.uln}
                          </MDTypography>
                        </MDBox>
                        <MDBox mb={2} lineHeight={0} display="flex" alignItems="center">
                          <MDTypography
                            variant="subtitle2"
                            color="success"
                            sx={{ minWidth: "150px" }}
                          >
                            EMAIL:
                          </MDTypography>
                          <MDTypography variant="h6" fontWeight="regular">
                            {_user?.email}
                          </MDTypography>
                        </MDBox>
                        <MDBox mb={2} lineHeight={0} display="flex" alignItems="center">
                          <MDTypography
                            variant="subtitle2"
                            color="success"
                            sx={{ minWidth: "150px" }}
                          >
                            PHONE:
                          </MDTypography>
                          <MDTypography variant="h6" fontWeight="regular">
                            {_user?.phone}
                          </MDTypography>
                        </MDBox>
                        <MDBox mb={2} lineHeight={0} display="flex" alignItems="center">
                          <MDTypography
                            variant="subtitle2"
                            color="success"
                            sx={{ minWidth: "150px" }}
                          >
                            ZONE:
                          </MDTypography>
                          <MDTypography variant="h6" fontWeight="regular">
                            {_user?.zone}
                          </MDTypography>
                        </MDBox>
                        <MDBox mb={2} lineHeight={0} display="flex" alignItems="center">
                          <MDTypography
                            variant="subtitle2"
                            color="success"
                            sx={{ minWidth: "150px" }}
                          >
                            STATE:
                          </MDTypography>
                          <MDTypography variant="h6" fontWeight="regular">
                            {_user?.stateOfRez}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox
          pt={2}
          px={2}
          lineHeight={1.25}
          sx={{ backgroundColor: "#fff", borderRadius: 4, mb: 3, boxShadow: 3 }}
        >
          <MDTypography variant="h6" fontWeight="medium" mt={3} ml={2}>
            Qualifications
          </MDTypography>
          <MDBox mb={3} ml={2}>
            <MDTypography variant="button" color="text">
              You have been trained in the following fields
            </MDTypography>
          </MDBox>

          <MDBox p={2}>
            <Grid container spacing={3}>
              {_user?.qualifications.map((qualification, index) => (
                <Grid item xs={12} md={4} key={index} mb={2}>
                  <Card>
                    <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
                      <MDBox
                        variant="gradient"
                        bgColor={
                          qualification.qualificationType === "PLUMBING"
                            ? "dark"
                            : qualification.qualificationType === "CARPENTRY"
                            ? "success"
                            : qualification.qualificationType === "ELECTRICAL"
                            ? "error"
                            : qualification.qualificationType === "PAINTING & DECORATION"
                            ? "info"
                            : qualification.qualificationType === "WELDING & FABRICATION"
                            ? "warning"
                            : "dark"
                        }
                        color={"white"}
                        coloredShadow={"dark"}
                        borderRadius="xl"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="4rem"
                        height="4rem"
                        mt={-3}
                      >
                        <Icon fontSize="medium" color="inherit">
                          {qualification.qualificationType === "PLUMBING"
                            ? "plumbing"
                            : qualification.qualificationType === "CARPENTRY"
                            ? "handyman"
                            : qualification.qualificationType === "ELECTRICAL"
                            ? "electrical_services"
                            : qualification.qualificationType === "PAINTING & DECORATION"
                            ? "format_paint"
                            : qualification.qualificationType === "TILING & DECORATIVE STONE WORK"
                            ? "view_comfy"
                            : "engineering"}
                        </Icon>
                      </MDBox>
                      <MDBox textAlign="right" lineHeight={1.25}>
                        <MDTypography
                          variant={
                            qualification.qualificationType === "TILING & DECORATIVE STONE WORK"
                              ? "caption"
                              : "subtitle2"
                          }
                          fontWeight="light"
                          color="text"
                        >
                          {qualification.qualificationType}
                        </MDTypography>
                        <MDTypography variant="subtitle2">
                          Level &nbsp;
                          <MDTypography
                            component="span"
                            variant="h5"
                            fontWeight="regular"
                            color={
                              qualification.UserQualification.level > 5
                                ? "success"
                                : qualification.UserQualification.level === 5
                                ? "warning"
                                : qualification.UserQualification.level < 5
                                ? "error"
                                : "text"
                            }
                          >
                            {qualification.UserQualification.level}
                          </MDTypography>
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <Divider />
                    <MDBox pb={2} px={2}>
                      <MDTypography component={Link} to="#" variant="body2" color="secondary">
                        <Tooltip title="add certificate" placement="top">
                          <Icon>picture_as_pdf</Icon>
                        </Tooltip>
                      </MDTypography>
                    </MDBox>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </MDBox>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
