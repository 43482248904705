/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

export default function data() {
  const [qualifications, setQualifications] = useState([]);
  const apiUrl = "https://api.skillcamp.niobnat.org";

  // get all Qualifications' record
  useEffect(() => {
    const getQualificationsData = async () => {
      const { data } = await axios.get(`${apiUrl}/api/qualifications/getQualifications`); /*, {
       withCredentials: true, // Include cookies or session data
      });*/
      setQualifications(data);
    };
    getQualificationsData();
  }, []);

  const Qualification = ({ qualificationType, description }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {qualificationType}
        </MDTypography>
        <MDTypography variant="caption">{description}</MDTypography>
      </MDBox>
    </MDBox>
  );

  return {
    columns: [
      { Header: "sno.", accessor: "number", width: "10%", align: "center" },
      { Header: "qualification-type", accessor: "qualification", width: "45%", align: "left" },
      { Header: "action", accessor: "action", width: "20%", align: "center" },
    ],

    rows: qualifications.map((qualification, index) => ({
      number: <MDTypography>{index + 1 + "."}</MDTypography>,
      qualification: (
        <Qualification
          qualificationType={qualification.qualificationType}
          description={qualification.description}
        />
      ),
      action: (
        <MDTypography
          component="a"
          href={`qualifications/${qualification.id}/edit`}
          variant="caption"
          color="success"
          fontWeight="medium"
        >
          Edit
        </MDTypography>
      ),
    })),
  };
}
